import OTPInput from "src/lib/otp-input";
import React from "react";
import "./custom-otp-input.css";

const CustomInputOTP = ({ setOtp }: { setOtp?: any }) => {
  const [{ otp, numInputs, placeholder, inputType }, setConfig] =
    React.useState({
      otp: "",
      numInputs: 6,
      separator: "-",
      minLength: 0,
      maxLength: 40,
      placeholder: "------",
      inputType: "number" as const,
    });

  const handleOTPChange = (otp: string) => {
    setOtp(otp);
    setConfig((prevConfig) => ({ ...prevConfig, otp }));
  };

  return (
    <OTPInput
      inputStyle="inputStyle"
      numInputs={numInputs}
      onChange={handleOTPChange}
      // renderSeparator={<span>{separator}</span>}
      value={otp}
      placeholder={placeholder}
      inputType={inputType}
      renderInput={(props: any) => (
        <input
          {...props}
          className="otp-input !focus-visible:border-primary-300 otp-input appearance-none rounded-md border border-solid border-gray-10 !bg-white px-16 py-24 text-14 font-normal leading-20 tracking-0.014 placeholder:text-14 placeholder:text-gray-10 focus-visible:shadow-input-field-focus-shadow focus-visible:outline-none"
        />
      )}
      shouldAutoFocus
    />
  );
};

export default CustomInputOTP;
