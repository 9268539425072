import React from "react";
import { IQuotationCard } from "./types";
import { CARD_TYPES } from "./data";

const QuotationCardComponent = (props: IQuotationCard) => {
  const {
    id,
    title,
    image,
    description,
    isActive,
    handleCardClick,
    cardType = CARD_TYPES.QUOTATION_TYPE,
  } = props;
  if (cardType == CARD_TYPES.QUOTATION_TYPE) {
    const className = `px-24 py-12 rounded-[7px] my-2 border-[0.6px] hover:shadow-lg  cursor-pointer ${
      isActive
        ? "border-primary-300 bg-primary-100 shadow-button-active"
        : "border-primary-200 bg-primary-50"
    }`;

    return (
      <div
        className={className}
        onClick={() => {
          handleCardClick && handleCardClick(id);
        }}
      >
        <h3 className="pb-8 text-16 font-semibold leading-24 text-blue-200">
          {title}
        </h3>
        <p className="text-12 font-normal leading-18 text-blue-200">
          {description}
        </p>
      </div>
    );
  } else {
    return (
      <div
        className={`max-w-[12.5rem] cursor-pointer rounded-[7px] border-[0.6px] px-24 py-12  hover:shadow-lg ${
          isActive
            ? "border-primary-300 bg-primary-100 shadow-button-active"
            : "border-primary-200 bg-primary-50"
        }`}
        onClick={() => {
          handleCardClick && handleCardClick(id);
        }}
      >
        <div className="mb-12 h-[96px]">
          <img
            src={image}
            alt={title}
            className="h-full w-full object-contain"
          />
        </div>
        <h4 className="text-center text-16 font-semibold leading-24">
          {title}
        </h4>
      </div>
    );
  }
};

export default QuotationCardComponent;
