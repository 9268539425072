import React from "react";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import AuthLayout from "src/components/layouts/auth-layout";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess = () => {
  const redirect = useNavigate();
  return (
    <>
      <AuthLayout>
        <div className="flex flex-col text-center">
          <div className="mb-32 flex items-center justify-center">
            <div className="flex h-[120px] w-[120px] items-center justify-center rounded-[28px] bg-gray-800">
              <Icon.IcPrs />
            </div>
          </div>
          <div className="mb-32">
            <h4 className="mb-8 text-36 font-bold leading-44 tracking-0.72 text-gray-700">
              Success!
            </h4>
            <p className="mb-8 text-14 font-normal leading-20 text-gray-700">
              Your password has been successfully changed
            </p>
          </div>
          <CustomButton
            onClick={() => {
              redirect("/login");
            }}
          >
            Back to login page
          </CustomButton>
        </div>
      </AuthLayout>
    </>
  );
};

export default ResetPasswordSuccess;
