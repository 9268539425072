
import { CONSTANTS } from "src/constants";
import * as yup from "yup";

export const integrationSchema = (isEdit: boolean) => {
  return yup.object().shape({
    secret_id: yup.string().trim().required("Secret Id is required"),
    secret_key: yup.string().trim().required("Secret Key is required"),
  });
};
