import { Button, Divider, Modal } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "src/components/atoms/icons";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";

interface UploadModalProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  allowMultipleFiles: boolean;
  acceptedFileTypes?: string; // Accepted file types, e.g., ".pdf,.xls,.docs"
}

const UploadModal: React.FC<UploadModalProps> = ({
  open,
  onOk,
  onCancel,
  fileList,
  setFileList,
  acceptedFileTypes,
  allowMultipleFiles,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const validateFileType = (file: RcFile) => {
    if (!acceptedFileTypes) return true;
    const acceptedTypes = acceptedFileTypes.split(",");
    const fileType = `.${file.name.split(".").pop()}`;
    return acceptedTypes.includes(fileType);
  };

  const handleUpload = (file: RcFile): Promise<false> => {
    setIsUploading(true);
    if (!allowMultipleFiles) {
      setFileList([file as UploadFile]);
    } else if (validateFileType(file)) {
      setFileList((prevFileList) => [...prevFileList, file as UploadFile]);
    } else {
      toast.error(`File type not supported: ${file.name}`);
    }
    setIsUploading(false);
    return Promise.resolve(false); // Prevent upload
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const invalidFiles = Array.from(e.dataTransfer.files)
      .filter((file: any) => !validateFileType(file))
      .map((file: File) => file.name);

    if (invalidFiles.length > 0) {
      toast.error(`File type not supported ${invalidFiles.join(", ")}`);
    }
  };

  const draggerProps: UploadProps = {
    name: "file",
    multiple: allowMultipleFiles,
    accept: acceptedFileTypes,
    fileList: fileList as UploadFile[],
    beforeUpload: handleUpload,
    onDrop: handleDrop,
    showUploadList: false,
    className: "border-md basis-3/5",
  };

  return (
    <Modal
      title="Upload File"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      width={820}
      okText="Save"
      cancelButtonProps={{
        className: "h-10",
      }}
      okButtonProps={{
        className:
          "bg-primary-500-base hover:!bg-primary-500-base hover:opacity-75 h-10",
      }}
    >
      <Divider />
      <div className="flex items-center gap-x-8 px-24 py-20">
        {/* ===== Drag & Drop ===== */}
        <Dragger {...draggerProps}>
          <div className="p-45">
            <div className="mb-28 flex items-end justify-center gap-5">
              <div className="ant-upload-drag-icon">
                <Icon.McsIcUploadCloud />
              </div>

              <div className="text-14">
                <p>Drag files to upload</p>
                <span>or</span>
                <div className="rounded-md border border-border px-20 py-10 text-14 font-semibold text-blue-200">
                  Browse files
                </div>
              </div>
            </div>

            <p className="text-12">
              Maximum size: <strong>12MB</strong>
            </p>
            <p className="text-12">
              Supported file types:{" "}
              <strong>
                {acceptedFileTypes ? acceptedFileTypes : ".pdf,.xls,.docs"}
              </strong>
            </p>
          </div>
        </Dragger>

        <div className="basis-2/5">
          <p>
            Status:{" "}
            <strong>
              {isUploading
                ? "Uploading..."
                : fileList.length > 0
                  ? "Uploaded"
                  : "-"}
            </strong>
          </p>
          <div className="my-12 flex gap-2 rounded-md border border-border px-16 py-10">
            <Icon.McsIcPolygon />
            <div className="w-full">
              <div className="flex w-full flex-col gap-1 ">
                <span className="text-nowrap">File name: </span>
                <div className="h-40 overflow-y-auto">
                  {fileList.length > 0 &&
                    fileList.map((f) => (
                      <div key={f.uid} className="flex w-full justify-between">
                        <strong
                          className="line-clamp-1 h-6 text-ellipsis"
                          style={{ maxWidth: "150px" }}
                        >
                          {f.name}
                        </strong>
                        <p className="font-bold">
                          {formatFileSize(f.size ?? 0)}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Button
            size="large"
            onClick={() => {
              setFileList([]);
            }}
            className="text-14 hover:!text-slate-500"
          >
            Cancel Upload
          </Button>
        </div>
      </div>

      <Divider />
    </Modal>
  );
};

UploadModal.defaultProps = {
  acceptedFileTypes: ".pdf,.xls,.docs",
  allowMultipleFiles: false,
};

export default UploadModal;

/**
 * Convert a number representing file size into a human-readable format (KB, MB, GB, etc.).
 * @param {number} bytes - The size in bytes to convert.
 * @returns {string} A string representing the converted file size with appropriate unit (KB, MB, GB, etc.).
 */
export function formatFileSize(bytes: number): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}
