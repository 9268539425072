import axios from "axios";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { formatDateQuotation } from "src/components/molecules/crop-modal-easy/utils";
import { CONSTANTS } from "src/constants";
import { ENDPOINTS } from "src/store/endpoints";
import { showToast } from "src/utils";

export const loginUserApi = () => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        name: "Test",
        email: "test@gmail.com",
      });
    }, 1000);
  });
};

export const checkForEmptyBioUser = (user: any) => {
  if (
    !user?.first_name ||
    !user?.last_name ||
    !user?.dob ||
    !user?.profession ||
    !user?.country
  ) {
    showToast(
      TOASTR_TYPES.ERROR,
      "Please fill the required fields in personal info",
    );
    return true;
  } else if (user?.biography?.length == 0) {
    showToast(
      TOASTR_TYPES.ERROR,
      "You cannot publish the biography as it is currently empty",
    );
    return true;
  } else {
    return false;
  }
};

let timerId: NodeJS.Timeout;

let cancelTokenSource = axios.CancelToken.source();

export const checkEmailExists = (data: any) => {
  clearTimeout(timerId);
  // Cancel the previous request
  cancelTokenSource.cancel();

  // Create a new CancelToken source for the current request
  cancelTokenSource = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    timerId = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + ENDPOINTS.ADMIN.CHECK_EMAIL_EXISTS,
          data,
          {
            cancelToken: cancelTokenSource.token,
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    }, 500);
  });
};

export const formatDataForUpdatePricings = (obj: any) => {

  const data: any = [];

  const mapping: any = {
    arr: "pre-carriage",
    arr1: "origin",
    arr2: "destination",
    arr3: "ocean-freight",
  };

  for (const key in obj) {
    obj[key]?.forEach((item: any) => {
      data.push({
        charge_type: mapping[key],
        sub_charge_type: item.type,
        charges:
          (item.per_rate_basis == "0.00"
            ? item?.charges
            : item.per_rate_basis) * item?.qty,
        rate_basis: item.rate_basis,
        qty: item.qty,
        per_rate_basis:
          item.per_rate_basis == "0.00" ? item?.charges : item.per_rate_basis,
        direction: mapping[key] == "origin" ? "export" : mapping[key] == "destination" ? "import":""
      });
    });
  }

  return data;
};

export const getFilterCountQuotation = (data: any, filterType: any) => {
  let count = 0;
  Array.isArray(data) &&
    data?.forEach((quotation: any) => {
      if (quotation.mode_of_transport === filterType) {
        count++;
      }
    });
  return count;
};

export const convertKgCM = (value: number, unit: any) => {
  let ratio = 2.54;

  if (!unit) {
    ratio = 1 / 2.54;
  }

  // const convertedVal = Math.round(value * ratio * 10) / 10;
  const convertedVal = (value * ratio * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertVolumeUnit = (value: number, unit: any) => {
  let ratio = 35.314667;

  if (!unit) {
    ratio = 1 / 35.314667;
  }

  // const convertedVal = Math.round(value * ratio * 10) / 10;
  const convertedVal = (value * (1 / ratio) * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertPoundKg = (value: number, unit: any) => {
  if (!unit) {
    //kg to lb
    // return naiveRound(Math.round(value * 2.205), 2);
    return naiveRound(value * 2.205);
  }
  // return naiveRound(Math.round((value * 1) / 2.205), 2);
  return naiveRound((value * 1) / 2.205);
};

export function naiveRound(num: number, decimalPlaces = 2) {
  const p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

export const convertVolume = (value: any) => {
  const calResult = Number.parseFloat(value) / 1000000;

  return calResult.toFixed(3) || "-";
};

export const generateErrorMessage = (
  field: string,
  isValueLimit = false,
  value?: string,
) => {
  if (isValueLimit) {
    return `Max ${field} ${value}`;
  }
  return "Please enter " + field;
};

export const convertDataForPricings = (input: any) => {
  const mapping: { [key: string]: string } = {
    preCarriage: "pre-carriage",
    origin: "origin",
    oceanFreight: "ocean-freight",
    destination: 'destination'
  };

  const newObj: { [key: string]: any } = {};
  for (const x in input) {
    newObj[mapping[x]] = input[x].map((e: any) => ({ [e.carriage]: e.amount }));
  }
  return newObj;
};

export const isCustomQuotation = (formikQuotation: any) => {
  return formikQuotation?.values?.quotation_type === "internal" ? false : true;
};

export const checkErrorCargoItem = (
  errors: any,
  total_cargo_dimensions: boolean,
) => {
  return total_cargo_dimensions
    ? errors?.volume || errors?.weight
    : errors?.height || errors?.length || errors?.width || errors?.weight;
};

export const formatQuotationReference = (data: any) => {
  if (data?.reference_no && data?.uid) {
    return data?.reference_no + "-" + data?.uid;
  } else if (data?.uid) {
    return data?.uid || "-";
  } else {
    return data?.reference_no || "-";
  }
};

export const formatDataForCustomerQuotationExport = (data: any) => {
  return data.map((item: any) => {
    return {
      "Reference No.": formatQuotationReference(item) || "",
      "Creation Date": formatDateQuotation(item?.created_at),
      Origin: ` ${item?.source_port} ,${item?.source_country}`,
      Destination: `${item?.destination_port} ,${item?.destination_country} `,
      MOT: item?.mode_of_transport?.toUpperCase(),
      "Service Type": item?.quotation_type?.toUpperCase(),
      Status: item?.status?.toUpperCase(),
    };
  });
};

export const checkDefaultCustomer = (user: any) => {
  return user?.email === CONSTANTS.DEFAULT_CUSTOMER_EMAIL;
};
