import React, { useEffect } from "react";
import IntegrationComponent from "./integration.component";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { IResponse } from "src/services/types";
import useLoadingError from "src/hooks/useLoadingError";
import { useFormik } from "formik";
import { useIntegrationGetTokenMutation, useIntegrationStoreCredsMutation } from "src/services/api-service/settings";
import { integrationSchema } from "src/validations/integration-schema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";

const IntegrationContainer = () => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [integrationStoreCredsMutation] = useIntegrationStoreCredsMutation<any>();
  const [integrationGetTokenMutation] = useIntegrationGetTokenMutation<any>();

  const navigate = useNavigate();
  const [url] = useSearchParams();
  const code = url?.get("code");
  const user = useAppSelector(userSelector);

  const formik = useFormik({
    initialValues: {
      secret_id: "",
      secret_key: "",
    },
    validationSchema: integrationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    console.log(user)
    if(code&&user) {
      startLoading();
      const body = {
        user_id: user?.id,
        code: code
      }
      integrationGetTokenMutation(body)
        .unwrap()
        .then((data: any) => {
          formik.resetForm();
          showToast(TOASTR_TYPES.SUCCESS, data?.message);
        })
        .catch((err: IResponse) => {
          // showToast(TOASTR_TYPES.ERROR, err?.message);
        })
        .finally(() => {
          formik.resetForm();
          stopLoading();
          navigate("/admin/settings?tab=Integration")
        });
    }
  },[code])

  async function handleSubmit(data: any) {
    formik.setTouched({
      secret_id: true,
      secret_key: true,
    });
    if (formik?.isValid) {
      startLoading();
      integrationStoreCredsMutation(formik?.values)
        .unwrap()
        .then((data: any) => {
          formik.resetForm();
          showToast(TOASTR_TYPES.SUCCESS, data?.message);
        })
        .catch((err: IResponse) => {
          // showToast(TOASTR_TYPES.ERROR, err?.message);
        })
        .finally(() => {
          formik.resetForm();
          stopLoading();
        });
    }
  }

  return <IntegrationComponent formik={formik} resetError={reset} />;
};

export default IntegrationContainer;
