import { useLocation } from "react-router-dom";

interface IQueryParams {
  paramName: string;
}

const useQueryParams = (): {
  getQueryParam: (paramName: string) => string;
} => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const getQueryParam = (paramName: string) => {
    const queryParam = params.get(paramName);
    return queryParam || "";
  };

  return { getQueryParam };
};

export default useQueryParams;
