import React from "react";
import Label from "../label";
import { DatePicker } from "antd";
import ErrorLabel from "../error-label";
import { ICustomDatePicker } from "./types";
import dayjs from "dayjs";
import moment from "moment";

const dateFormat = "MMM DD, YYYY";

const CustomDatePickerComponent = ({
  id,
  name,
  label,
  className,
  placeholder,
  disabled,
  value,
  required = false,
  errorMessage = "",
  showError,
  onChange,
  containerClassName = "",
}: ICustomDatePicker) => {
  const disabledDate = (current: any) => {
    // Always return true to disable all dates
    return current && current < moment().startOf("day");
  };

  return (
    <div
      className={`${containerClassName} ${
        errorMessage && showError ? "date-error" : ""
      }`}
    >
      {label && <Label id={id || name} name={label} required={required} />}
      <div className="relative">
        <DatePicker
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          value={value ? dayjs(value) : null}
          disabledDate={disabledDate}
          allowClear={false}
          format={dateFormat}
          size="large"
          className={className}
        />

        {showError && <ErrorLabel message={errorMessage} />}
      </div>
    </div>
  );
};

export default CustomDatePickerComponent;
