import React from "react";

const McsIcBrandColorChevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33325 6L7.88207 10.5488C7.94716 10.6139 8.05268 10.6139 8.11777 10.5488L12.6666 6"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default McsIcBrandColorChevronDown;
