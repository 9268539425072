import React from "react";
import { Select } from "antd";
import { ISelectProps } from "./types";
import "./custom-select.css";
import Label from "../label";
import { Icon } from "../icons";
import ErrorLabel from "../error-label";

const { Option } = Select;

const CustomSelectComponent = ({
  mode,
  placeholder,
  value,
  onChange,
  popupClassName = "",
  allowClear,
  tagRender,
  dropdownRender,
  label,
  disabled,
  options = [],
  className,
  defaultValue,
  id,
  required,
  name,
  classes,
  errorMessage,
  showError,
  showSearch
}: ISelectProps) => {
  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <>
      {label && <Label id={id || name} name={label} required={required} />}

      <Select
        mode={mode === "multiple" || mode === "tags" ? mode : undefined} //
        showSearch={showSearch}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
        tagRender={tagRender}
        dropdownRender={dropdownRender}
        style={{ width: "100%" }}
        suffixIcon={disabled?'':<Icon.McsIcDownArrow />}
        disabled={disabled}
        popupClassName={popupClassName}
        className={`${className} ${errorMessage && showError ? " ant-select-error cursor-pointer" : " cursor-pointer"} ${disabled ? " !text-gray-700" : ""}`}
        // filterOption={(input, option:any) => (option?.label ?? '').includes(input)}
        // filterSort={(optionA, optionB) =>
        //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        // }
      >
        {/* Dynamic Options */}
        {options.map((option) => (
          <Option
            // style={
            //   option.disabled && { background: "gainsboro", color: "gray" }
            // }
            key={option?.value || option?.id}
            value={option?.value || option?.id}
            disabled={option?.disabled}
          >
            <span className="text-14 font-medium leading-20 text-gray-500-base text-gray-800">
              {toTitleCase(option?.label || option?.name || "")}
            </span>
          </Option>
        ))}
      </Select>
      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </>
  );
};

export default CustomSelectComponent;
