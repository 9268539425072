import React, { useEffect, useRef, useState } from "react";
import usePagination from "src/hooks/usePagination";
import { columns } from "./columns";
import { Button, Checkbox, Input, Select, Tooltip } from "antd";
import { Dropdown, MenuProps, SelectProps } from "antd";
import { Space } from "antd";
import DataTableSearch from "src/components/organisms/datatable/elements/datatable-search";
import { Icon } from "src/components/atoms/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

interface Props {
  columnOption: string[];
  sendFilteredColumns: (data: any) => void;
  values: any;
  setFiltersData: any;
  isShow: boolean;
}
export default function FilterQuotationHistory(
  this: any,
  { columnOption, sendFilteredColumns, values, setFiltersData, isShow }: Props,
) {
  const serviceFilterArray = [
    {
      value: "lcl",
      label: <div className="  text-12 font-medium leading-18 ">LCL</div>,
    },
    {
      value: "fcl",
      label: <div className="text-12 font-medium leading-18 ">FCL</div>,
    },
    {
      value: "air",
      label: <div className="  text-12 font-medium leading-18 ">Air</div>,
    },
    {
      value: "road",
      label: <div className="  text-12 font-medium leading-18 ">Road</div>,
    },
    {
      value: "rail",
      label: <div className="  text-12 font-medium leading-18 ">Rail</div>,
    },
  ];
  const defaultServiceSelectedValues = serviceFilterArray;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const { pagination, handleSearch, clearSearch } = usePagination({
    key: "quotations-datatable",
    mutation: undefined,
  });
  const [selectAllService, setSelectAllService] = useState(false);
  const [selectedService, setSelectedService] = useState<any>([]);

  const handleServiceChange = (value: any) => {
    setSelectAllService(false)
    setSelectedService(value);
    setFiltersData((prev: any) => ({
      ...prev,
      service_type: value.join(",") || null,
    }));
    
  };

  const handleTypeChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      type: value || null,
    }));
  };

  const handleMotChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      mot: value || null,
    }));
  };

  const handleStatusChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      status: value || null,
    }));
  };

  const handleSelectAllService = (checked: boolean) => {
    setSelectAllService(checked);
    setSelectedService(checked ? defaultServiceSelectedValues : []);
    setFiltersData((prev: any) => ({
      ...prev,
      service_type: checked ? defaultServiceSelectedValues?.map(option => option.value).join(",") : null,
    }));
  };

  const handleClearAll = () => {
    setSelectAllService(false);
    setSelectedService([]);
    setFiltersData((prev: any) => ({
      ...prev,
      service_type: null,
    }));
  };


  // const handleChange = (value: any, key: string) => {
  //   setField((prev) => ({ ...prev, [key]: value }));
  // };

  return (
    <>
      <div
        className={
          !isShow
            ? " mt-5 flex w-full items-start justify-end space-x-4 pb-5"
            : "hidden"
        }
      >
        <div className=" w-auto md:w-full ">
          <Select
            mode="multiple"
            className="multiSelect select-status rounded-md border-2 bg-white py-1"
            placeholder="Select Type"
            style={{ width: "100%", minWidth: "150px" }}
            maxTagCount="responsive"
            onChange={handleServiceChange}
            value={selectedService}
            options={serviceFilterArray}
            
            dropdownRender={(menu) => (
              <div>
                <div className="p-10 flex justify-between items-center" >
                  <Checkbox checked={selectAllService} onChange={(e) => handleSelectAllService(e.target.checked)}>
                    All
                  </Checkbox>
                  <Tooltip title="Clear all" >
                    <Button type="text" disabled={selectedService.length === 0}  icon={<CloseCircleOutlined />} onClick={handleClearAll}/>
                  </Tooltip>
                </div>
                {menu}
              </div>
            )}
          />
        </div>
        <div className="">
          <Select
            // mode="multiple"
            className="multiSelect select-status rounded-md border-2 bg-white  py-1"
            placeholder="Type"
            style={{ width: "100%", minWidth: "150px" }}
            maxTagCount="responsive"
            onChange={handleTypeChange}
            value={values.type}
            options={[
              {
                value: "0",
                label: (
                  <div className="  text-12 font-medium leading-18 ">
                    Client
                  </div>
                ),
              },
              {
                value: "1",
                label: (
                  <div className="text-12 font-medium leading-18 ">Admin</div>
                ),
              },
            ]}
          />
        </div>
        <div className="mr-16   w-full">
          <Select
            defaultValue={null}
            style={{ width: "100%", minWidth: "100px" }}
            placeholder="MOT"
            onChange={handleMotChange}
            value={values.mot}
            options={[
              {
                label: "Ocean",
                value: "ocean",
              },
              {
                label: "Air",
                value: "air",
              },
              {
                label: "Road",
                value: "road",
              },
              {
                label: "Rail",
                value: "rail",
              },
            ]}
          />
        </div>
        <div className="w-full rounded-lg  ">
          <Select
            placeholder="Status"
            style={{ width: "100%", minWidth: "110px" }}
            className="select-status  w-full border-2 bg-white  "
            onChange={handleStatusChange}
            value={values.status}
            options={[
              {
                value: "booked",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl  bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
                      Booked
                    </div>
                  </div>
                ),
              },
              {
                value: "pending",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-purple-50 px-8 py-2 text-12 font-medium leading-18 text-purple-700">
                      Pending
                    </div>
                  </div>
                ),
              },
              {
                value: "failed",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
                      Failed
                    </div>
                  </div>
                ),
              },
              {
                value: "complete",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-sky-50 px-8 py-2 text-12 font-medium leading-18 text-blue-800">
                      Complete
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
        {/* <div className="mr-16">
          <DataTableSearch
            query={pagination?.query}
            handleSearch={handleSearch}
            clearSearch={clearSearch}
          />
        </div> */}
      </div>
      <div
        className={
          isShow
            ? " mt-5 flex w-full items-start justify-end space-x-2 pb-5"
            : "hidden"
        }
      >
        <div className="">
          <div className=" relative w-auto md:w-full ">
            <Select
              mode="multiple"
              className="multiSelect select-status rounded-md border-2 bg-white  py-1"
              placeholder="Show"
              defaultValue={columnOption}
              style={{ width: "100%", minWidth: "135px" }}
              maxTagCount="responsive"
              // onChange={(value) => handleChange(value, "reference")}
              onChange={sendFilteredColumns}
              suffixIcon={
                <>
                  <span className="absolute text-indigo-900 -left-[99px] text-base -top-[10px] !z-10 !cursor-pointer rounded-2xl bg-white px-28 py-7">
                    Show
                  </span>
                  <Icon.McsIcEye />
                </>
              }
              // value={field.reference}
            >
              {columnOption.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
}
