import React from "react";
import SideBarComponent from "./sidebar-results.component";
interface SideBarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  ref?: any;
  setIsSidebarOpen?: any;
}
const SideBarResultsContainer = (props: SideBarProps) => {
  return <SideBarComponent {...props} />;
};

export default SideBarResultsContainer;
