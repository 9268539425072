import React from "react";
import "./toast-container.style.css";
import { ToastContainer, toast } from "react-toastify";

const ToastContainerComponent = () => {
  return (
    <ToastContainer
      autoClose={3300}
      position={toast.POSITION.TOP_RIGHT}
      theme="dark"
      hideProgressBar={true}
    />
  );
};

export default ToastContainerComponent;
