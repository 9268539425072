import React, { useEffect, useMemo, useState } from "react";

import SiderBarHeader from "../../../molecules/sidebar-header";
import { Icon } from "src/components/atoms/icons";
import SidebarItem from "../../sidebar-item";
import { customerSideBarData, sideBarData } from "../sidebar-main/sidebar.data";
import { NavLink } from "react-router-dom";
import SidebarFilters from "../sidebar-filters";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { getFilterCountQuotation } from "src/helpers";
import {
  AdminSidebarFilterData,
  CustomerSidebarFilterData,
} from "../sidebar-filters/sidebar-filters.data";

const activeClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] bg-primary-300";
const inactiveClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] hover:bg-primary-100";

const SideBarResultsComponent = ({ isSidebarOpen, toggleSidebar }: any) => {
  const quotationsData = useAppSelector(quotationsSelector);

  const sidebars = useMemo(
    () => (location.href.includes("admin") ? sideBarData : customerSideBarData),
    [location],
  );

  const sidebarFilters = useMemo(
    () =>
      location.href.includes("admin")
        ? AdminSidebarFilterData
        : CustomerSidebarFilterData,
    [location],
  );

  const [data, setData] = useState<any>(sidebarFilters);

  useEffect(() => {
    console.log(quotationsData?.quotations, "quotations?>>???");
    setData({
      // Services: [
      //   {
      //     label: `Door to door (${getFilterCountQuotation(quotationsData?.quotations, "door-to-door")})`,
      //     value: "door-to-door",
      //   },
      //   {
      //     label: `Door to port (${getFilterCountQuotation(quotationsData?.quotations, "door-to-port")})`,
      //     value: "door-to-port",
      //   },
      //   {
      //     label: "Port to door (0)",
      //     value: "port-to-door",
      //   },
      //   {
      //     label: "Port to port (0)",
      //     value: "port-to-port",
      //   },
      // ],
      "Shipping Mode": [
        {
          label: `Ocean (${getFilterCountQuotation(quotationsData?.quotations, "ocean")})`,
          value: "ocean",
        },
        {
          label: `Air (${getFilterCountQuotation(quotationsData?.quotations, "air")})`,
          value: "air",
        },
        {
          label: `Rail (${getFilterCountQuotation(quotationsData?.quotations, "rail")})`,
          value: "rail",
        },
        {
          label: `Road (${getFilterCountQuotation(quotationsData?.quotations, "road")})`,
          value: "road",
        },
      ],
    });
  }, [quotationsData.quotations]);

  const isLoading = false;
  return (
    <>
      <div className={`flex justify-between`}>
        <SiderBarHeader />
        {isSidebarOpen && (
          <button
            className="flex px-16 py-10 md:hidden"
            onClick={toggleSidebar}
          >
            <Icon.McsIcMenu />
          </button>
        )}
      </div>
      <div className="flex h-full w-full gap-x-2">
        <ul
          className={`theme-custom-scroll absolute left-0 h-full w-full ${location.href.includes("admin") ? "bg-primary-100" : "border-primary-300 bg-brand-color"} `}
        >
          {sidebars &&
            sidebars.map((val, i) => (
              <li key={i}>
                <NavLink
                  to={val.route}
                  className={`${
                    location.pathname === val.route ||
                    val.matchingRoutes?.includes(location.pathname)
                      ? activeClass
                      : inactiveClass
                  } group relative`}
                >
                  <div
                    className={`${!location.href.includes("admin") ? "grayscale invert group-hover:grayscale group-hover:invert-0" : ""}`}
                  >
                    {val.Icon}
                  </div>
                  <span className="absolute left-[-250px] z-20 flex h-full w-[calc(100%-52px)] items-center bg-primary-100  pl-10 transition-all duration-500 group-hover:left-[52px]">
                    {val?.label}
                  </span>
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="absolute left-[52px] h-full w-[calc(100%-52px)] overflow-y-auto bg-white">
          {Object.keys(data).map((val: any, idx) => {
            return <SidebarFilters key={idx} title={val} values={data[val]} />;
          })}
        </div>
      </div>
    </>
  );
};

export default SideBarResultsComponent;
