import React, { useEffect, useState } from "react";
import DataTableSearch from "../datatable-search";
import { IDataTable } from "../../types";

const DatetableHeaderComponent = ({
  title,
  description,
  button,
  handleSearch,
  pagination,
  clearSearch,
  columns,
  handleColumnChange,
  filters,
  placeholder,
}: Partial<IDataTable>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  //console.log(button, "button is ");
  return (
    <div className="mb-8 flex flex-wrap items-center justify-between gap-3">
      <div className="flex flex-col gap-1">
        <h2 className="text-24 font-bold leading-32 text-gray-800">{title}</h2>
        <div className="text-14 font-normal leading-20 text-gray-500-base">
          {pagination?.loading ? "Loading..." : description}
        </div>
      </div>
      <div className="flex w-full gap-3 xl:w-auto">
        <div className="flex w-full flex-wrap items-center justify-normal gap-3 xl:w-auto">
          <div className="filter-select-input flex w-full flex-col xl:w-auto">
            {filters}
          </div>
          <div className=" ">
            <div className="mr-16 ">
              <DataTableSearch
                query={pagination?.query}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="flex w-full sm:w-auto ">{button}</div>
        </div>
      </div>
    </div>
  );
};

export default DatetableHeaderComponent;
