import React from "react";
import { IConfirmDeleteModal } from "./confirm-delete.component.types";
import CustomButton from "src/components/atoms/button";
import CustomModal from "src/components/molecules/custom-modal";
import "./style.css";

const ConfirmDeleteComponent = ({
  title,
  message,
  deleteBtnTitle = "Delete",
  cancelBtnTitle = "Cancel",
  isOpen,
  onConfirm,
  openModal,
  toggle,
  loading,
  onCancel,
  removeImg,
  img
}: IConfirmDeleteModal) => {
  const modalProps = {
    title: null,
  };
  return (
    <CustomModal
      isOpen={isOpen}
      openModal={openModal}
      toggle={toggle}
      width={320}
      innerClass="custom-delete-modal"
      footer={
        <div className="w-full gap-[12px] xl:flex">
          <div className="flex-1">
            <CustomButton
              variant="secondary"
              onClick={onCancel || toggle}
              disabled={loading}
              className="w-full"
            >
              {cancelBtnTitle}
            </CustomButton>
          </div>
          <div className="mt-10 flex-1 xl:mt-0">
            <CustomButton
              variant="delete"
              onClick={onConfirm}
              loading={loading}
              className="w-full"
            >
              {deleteBtnTitle}
            </CustomButton>
          </div>
        </div>
      }
    >
      <div>
        <div className="flex flex-col items-center">
          <img
            className={removeImg ? "hidden" : ""}
            src={img||"/assets/images/mcs-deleteconfirmation.svg"}
            alt="profile-image"
          />
          <h3 className="mb-8 mt-20 text-center text-18 font-bold leading-28 text-gray-800">
            {title}
          </h3>
          <p className="text-center text-14 font-normal leading-20 text-gray-400">
            {message}
          </p>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDeleteComponent;
