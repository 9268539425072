import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AddCustomerComponent from "./add-customer.component";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import useLoadingError from "src/hooks/useLoadingError";
import {
  useAddUserMutation,
  useUpdateUserMutation,
} from "src/services/api-service/user";
import { IResponse } from "src/services/types";
import { userSchema } from "src/validations/user-schema";
import useTitleCase from "src/hooks/useTitleCase";

const AddCustomerContainer = ({
  handleCloseAddModal,
  reFetchData,
  updateId,
}: any) => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [addItem] = useAddUserMutation<any>();
  const [updateItem] = useUpdateUserMutation<any>();
  const { titleCasedString, handleInputChange } = useTitleCase();
  const [closeModalState, setCloseModalState] = useState(false);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      // registrationNumber: "",
      // paymentTerms: "",
      // vatNumber: "",
      street: "",
      // buildingNo: "",
      postalCode: "",
      city: "",
      country: null,
      contactPersonName: "",
      contactPersonPhoneNumber: "",
      contactPersonEmail: "",
    },
    validationSchema: userSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      // reFetchData(false);
      startLoading();
      let APIHIT;
      !updateId &&
        (APIHIT = addItem({
          ...values,
          name: titleCasedString["name"] || values?.name,
        }));
      updateId &&
        (APIHIT = updateItem({
          ...values,
          name: titleCasedString["name"] || values?.name,
          id: updateId,
        }));
      APIHIT &&
        APIHIT.unwrap()
          .then((data) => {
            if (data?.data) {
              showToast(
                TOASTR_TYPES.SUCCESS,
                capitalizeFirstLetter(data?.message),
              );
              closeModal(data);
              reFetchData(true);
            }
          })
          .catch((err: IResponse) => {
            // showToast(TOASTR_TYPES.ERROR, err?.message);
          })
          .finally(() => {
            setTimeout(() => {
              stopLoading();
            }, 1000);
          });
    },
  });

  const closeModal = (data: any) => {
    setCloseModalState(true);
    document.body.style.overflow = "auto";
    handleCloseAddModal({
      id: data?.data?.id || data?.data?.customerid,
      name: data?.data?.name || data?.data?.customername,
    });
  };

  useEffect(() => {
    handleInputChange(formik.values.name, "name");
  }, [formik.values.name]);

  return (
    <AddCustomerComponent
      formik={formik}
      resetError={reset}
      handleCloseAddModal={handleCloseAddModal}
      updateId={updateId}
      closeModal={closeModalState}
    />
  );
};

export default AddCustomerContainer;
