import Label from "../label";
import React, { useState, useEffect } from "react";
import ErrorLabel from "../error-label";
import { ICountryPicker, Country } from "./types";
import { Input, Select } from "antd";
import { Icon } from "../icons";
import "./style.css";

const CountrySelecterComponent = ({
  id,
  name,
  label,
  size = "large",
  classes,
  placeholder,
  disabled,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  onFocus,
  onBlur,
  onChange,
  autoFocus,
  suffix,
  countries,
  value,
  allowCustomValue = true,
}: ICountryPicker) => {
  const [searchvalue, setSearchValue] = useState(value || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setSearchValue(value || "");
  }, [value]);

  const commonInputProps: any = {
    name,
    searchvalue,
    onBlur,
    onChange: handleInputChange,
    placeholder,
    prefix: <Icon.McsIcMapPin />,
    suffix,
    size,
    value,
    disabled,
    className: classes?.inputClassName,
    status: showError && errorMessage ? "error" : "",
  };

  const [filteredOptions, setFilteredOptions] = useState(countries);

  useEffect(() => {
    setFilteredOptions(countries);
  }, [countries]);

  const style = !allowCustomValue
    ? { caretColor: "transparent", cursor: "pointer" }
    : {};

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <div className="countrySelectWrapper">
        <div className="prefix-icon-wrapper">{<Icon.McsIcMapPin />}</div>
        <Select
          style={style}
          popupClassName="!bg-primary-50"
          placeholder={placeholder}
          size="large"
          className="w-full"
          value={searchvalue || undefined}
          showSearch={true}
          optionLabelProp="label"
          onChange={(value: any) => {
            onSelect(value);
            setSearchValue(value);
          }}
          onSearch={(value: string) => {
            // Filter options based on the search value
            const filtered: any[] = countries.filter(
              (option) =>
                option.name.toLowerCase().includes(value.toLowerCase()) ||
                option.code.toLowerCase().includes(value.toLowerCase()),
            );
            setFilteredOptions(filtered);
          }}
        >
          {filteredOptions.map((item: any) => (
            <option
              value={item.name || item.code}
              key={item.code}
              label={item.name}
            >
              <div className="flex flex-col gap-2">
                <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                  {item.name}
                </span>
                <span>{`${item.name} (${item.code})`}</span>
              </div>
            </option>
          ))}
        </Select>
      </div>

      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default CountrySelecterComponent;
