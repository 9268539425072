import React from 'react'

const McsIcPasswrod = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.125 4.3746C13.788 4.3746 14.4239 4.63799 14.8928 5.10683C15.3616 5.57567 15.625 6.21156 15.625 6.8746M18.125 6.8746C18.1251 7.60386 17.9657 8.32432 17.6579 8.98546C17.3502 9.6466 16.9015 10.2324 16.3434 10.7018C15.7853 11.1712 15.1313 11.5129 14.4272 11.7028C13.7231 11.8927 12.986 11.9263 12.2675 11.8013C11.7983 11.7204 11.3017 11.8229 10.965 12.1596L8.75 14.3746H6.875V16.2496H5V18.1246H1.875V15.7763C1.875 15.2788 2.0725 14.8013 2.42417 14.4504L7.84 9.0346C8.17667 8.69793 8.27917 8.20127 8.19833 7.7321C8.08016 7.04974 8.10503 6.35027 8.27136 5.67802C8.43768 5.00577 8.74184 4.37541 9.16456 3.82687C9.58728 3.27834 10.1193 2.82359 10.727 2.49146C11.3347 2.15933 12.0047 1.95706 12.6947 1.89746C13.3846 1.83786 14.0794 1.92223 14.7351 2.14523C15.3907 2.36823 15.9929 2.725 16.5034 3.19292C17.0139 3.66084 17.4217 4.2297 17.7008 4.86347C17.98 5.49724 18.1244 6.18208 18.125 6.8746Z"
      stroke="#1D2939"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default McsIcPasswrod