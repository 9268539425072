import React from "react";

const McsIcCaretDouble = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3.50014L7.5 7.29389L3.35625 3.1439C3.28396 3.07607 3.19394 3.03009 3.09661 3.01129C2.99927 2.99248 2.89861 3.00162 2.80625 3.03765C2.71525 3.0759 2.63761 3.14023 2.58311 3.22253C2.52861 3.30484 2.49969 3.40143 2.5 3.50014L2.5 13.5001C2.49969 13.5989 2.52861 13.6955 2.58311 13.7778C2.63761 13.8601 2.71525 13.9244 2.80625 13.9626C2.89861 13.9987 2.99927 14.0078 3.09661 13.989C3.19394 13.9702 3.28396 13.9242 3.35625 13.8564L7.5 9.7064V13.5001C7.49969 13.5989 7.52861 13.6955 7.58311 13.7778C7.63761 13.8601 7.71525 13.9244 7.80625 13.9626C7.89861 13.9987 7.99927 14.0078 8.09661 13.989C8.19394 13.9702 8.28396 13.9242 8.35625 13.8564L13.3563 8.8564C13.4487 8.76083 13.5003 8.63309 13.5003 8.50015C13.5003 8.3672 13.4487 8.23947 13.3563 8.1439L8.35625 3.1439C8.28396 3.07607 8.19394 3.03009 8.09661 3.01129C7.99927 2.99248 7.89861 3.00162 7.80625 3.03765C7.71525 3.0759 7.63761 3.14023 7.58311 3.22253C7.52861 3.30484 7.49969 3.40143 7.5 3.50014Z"
        fill="white"
      />
    </svg>
  );
};

export default McsIcCaretDouble;
