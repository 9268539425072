import React, { useEffect, useRef, useState } from "react";
import PannelLayoutComponent from "./panel-layout.component";
import { useLocation } from "react-router-dom";
import { IPanelLayout } from "./types";

const PanelLayoutContainer = ({
  wrapperClass = "",
  children,
  breadcrumbsData,
  isResultsPage,
}: Partial<IPanelLayout>) => {
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const paths = ["users", "admin-users", "featured-members", "user-feedback"];
    const currentPath = location?.pathname?.split("/");

    paths.forEach((path) => {
      if (!currentPath.includes(path)) {
        sessionStorage.removeItem(`${path}-datatable`);
      }
    });
  }, [location]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };

    const handleClickOutside = (event: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";
    }
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <PannelLayoutComponent
      isResultsPage={isResultsPage}
      sidebarRef={sidebarRef}
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
      toggleSidebar={toggleSidebar}
      wrapperClass={wrapperClass}
      breadcrumbsData={breadcrumbsData}
    >
      {children}
    </PannelLayoutComponent>
  );
};

export default PanelLayoutContainer;
