import React from 'react'

const IcUploadImage = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={44}
  height={43}
  viewBox="0 0 44 43"
  fill="none"
>
  <path
    d="M38.0343 1.83765H32.779C30.5089 1.83765 29.1504 3.19615 29.1504 5.46627V10.7215C29.1504 12.9916 30.5089 14.3501 32.779 14.3501H38.0343C40.3044 14.3501 41.6629 12.9916 41.6629 10.7215V5.46627C41.6629 3.19615 40.3044 1.83765 38.0343 1.83765ZM38.4275 7.75427C38.213 7.96877 37.927 8.07602 37.641 8.07602C37.355 8.07602 37.069 7.96877 36.8545 7.75427L36.5328 7.43252V11.4365C36.5328 12.0621 36.0323 12.5626 35.4066 12.5626C34.781 12.5626 34.2805 12.0621 34.2805 11.4365V7.43252L33.9588 7.75427C33.5298 8.18327 32.8148 8.18327 32.3858 7.75427C31.9568 7.32527 31.9568 6.61027 32.3858 6.18127L34.6201 3.9469C34.7095 3.85752 34.8346 3.78602 34.9598 3.7324C34.9955 3.71452 35.0313 3.71452 35.067 3.69665C35.1564 3.6609 35.2458 3.64302 35.353 3.64302C35.3888 3.64302 35.4245 3.64302 35.4603 3.64302C35.5854 3.64302 35.6926 3.6609 35.8178 3.71452C35.8356 3.71452 35.8356 3.71452 35.8535 3.71452C35.9786 3.76815 36.0859 3.83965 36.1753 3.92902C36.1931 3.9469 36.1931 3.9469 36.211 3.9469L38.4454 6.18127C38.8744 6.61027 38.8744 7.32527 38.4275 7.75427Z"
    fill="#E0DBDB"
  />
  <path
    d="M16.639 18.6042C18.9886 18.6042 20.8933 16.6995 20.8933 14.35C20.8933 12.0004 18.9886 10.0957 16.639 10.0957C14.2895 10.0957 12.3848 12.0004 12.3848 14.35C12.3848 16.6995 14.2895 18.6042 16.639 18.6042Z"
    fill="#E0DBDB"
  />
  <path
    d="M38.0339 14.35H37.1937V22.5904L36.9614 22.3937C35.5671 21.1961 33.3149 21.1961 31.9206 22.3937L24.4846 28.7751C23.0904 29.9727 20.8381 29.9727 19.4439 28.7751L18.8361 28.2746C17.567 27.1664 15.5471 27.0591 14.1171 28.0244L7.43187 32.511C7.03862 31.51 6.80625 30.3481 6.80625 28.9896V14.0104C6.80625 8.96962 9.46962 6.30625 14.5104 6.30625H29.15V5.46612C29.15 4.75112 29.2751 4.14337 29.5611 3.625H14.5104C8.00387 3.625 4.125 7.50387 4.125 14.0104V28.9896C4.125 30.938 4.46462 32.6361 5.126 34.0661C6.66325 37.4624 9.95225 39.375 14.5104 39.375H29.4896C35.9961 39.375 39.875 35.4961 39.875 28.9896V13.9389C39.3566 14.2249 38.7489 14.35 38.0339 14.35Z"
    fill="#E0DBDB"
  />
</svg>

  )
}

export default IcUploadImage