import React from "react";

const McsIcShip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3966_42362)">
        <path
          d="M1.66797 16.6658C1.85728 16.9295 2.10795 17.1432 2.39832 17.2884C2.6887 17.4336 3.01006 17.5059 3.33464 17.4991C3.65922 17.5059 3.98057 17.4336 4.27095 17.2884C4.56133 17.1432 4.81199 16.9295 5.0013 16.6658C5.19061 16.4021 5.44128 16.1884 5.73166 16.0432C6.02203 15.898 6.34339 15.8257 6.66797 15.8325C6.99255 15.8257 7.31391 15.898 7.60428 16.0432C7.89466 16.1884 8.14533 16.4021 8.33464 16.6658C8.52394 16.9295 8.77461 17.1432 9.06499 17.2884C9.35537 17.4336 9.67672 17.5059 10.0013 17.4991C10.3259 17.5059 10.6472 17.4336 10.9376 17.2884C11.228 17.1432 11.4787 16.9295 11.668 16.6658C11.8573 16.4021 12.1079 16.1884 12.3983 16.0432C12.6887 15.898 13.0101 15.8257 13.3346 15.8325C13.6592 15.8257 13.9806 15.898 14.2709 16.0432C14.5613 16.1884 14.812 16.4021 15.0013 16.6658C15.1906 16.9295 15.4413 17.1432 15.7317 17.2884C16.022 17.4336 16.3434 17.5059 16.668 17.4991C16.9925 17.5059 17.3139 17.4336 17.6043 17.2884C17.8947 17.1432 18.1453 16.9295 18.3346 16.6658"
          stroke="#667085"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33333 15.0007L2.5 10.834H17.5L15.8333 14.1673"
          stroke="#667085"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16797 10.834V5.83398H10.8346L14.168 10.834"
          stroke="#667085"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83333 5.83333V2.5H5"
          stroke="#667085"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3966_42362">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default McsIcShip;
