import React from 'react'

const McsIcNotificationBell = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.2438 20.8236C14.3853 21.5558 13.2576 22 12.0226 22C10.7875 22 9.65985 21.5558 8.80136 20.8236M19.3245 12.801V9.05886C19.3245 5.15027 16.0675 2 12.0226 2C7.97766 2 4.67656 5.01597 4.67656 9.05886V12.7757C4.67656 13.3441 4.58487 13.9088 4.40503 14.4481L3.50594 17.144C3.48132 17.2178 3.5363 17.294 3.61415 17.294H20.3786C20.4609 17.294 20.5193 17.2165 20.4941 17.1409L19.5889 14.4266C19.4137 13.9014 19.3245 13.3529 19.3245 12.801Z"
    stroke="#1D2939"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>



  )
}

export default McsIcNotificationBell