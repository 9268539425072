import React from "react";

const McsIcBooking = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7938 5.66509C19.7906 5.65985 19.7882 5.65443 19.7849 5.64918C19.7821 5.64491 19.7789 5.64107 19.7761 5.63684C19.6337 5.41947 19.4307 5.2396 19.1873 5.11518L10.7873 0.830378C10.5469 0.708537 10.2758 0.644539 10 0.644531C9.72426 0.644524 9.45314 0.708506 9.21269 0.830334L0.812599 5.11527C0.565037 5.24172 0.359331 5.42548 0.216699 5.64759C0.215185 5.64997 0.213379 5.65209 0.211914 5.65447C0.209326 5.65866 0.207397 5.66302 0.204907 5.66725C0.0708526 5.88257 0.000297878 6.12534 0 6.37232V14.9157C0.000275722 15.1713 0.0756318 15.4224 0.218393 15.6432C0.361153 15.864 0.566218 16.0468 0.812699 16.1728L9.21269 20.4576C9.43641 20.5711 9.68714 20.6343 9.94365 20.6418C9.95962 20.6429 9.97546 20.6443 9.9917 20.6444C9.99453 20.6445 9.99736 20.6445 10.0002 20.6445C10.0219 20.6445 10.0433 20.6431 10.0646 20.6416C10.3182 20.633 10.566 20.5699 10.7873 20.4576L19.1874 16.1727C19.4339 16.0467 19.6389 15.864 19.7817 15.6431C19.9244 15.4223 19.9997 15.1713 20 14.9157V6.37232C19.9997 6.12451 19.9287 5.88094 19.7938 5.66509ZM10 2.08743L17.5813 5.9547L14.9089 7.33377L7.26845 3.48083L10 2.08743ZM10.1016 9.81465L2.43289 5.94743L5.63779 4.31259L13.2878 8.17038L10.1016 9.81465ZM10.8131 18.7858L10.9014 11.0653L14.1135 9.40765V12.4951C14.1135 12.6866 14.1978 12.8702 14.3478 13.0056C14.4978 13.141 14.7013 13.2171 14.9135 13.2171C15.1256 13.2171 15.3291 13.141 15.4792 13.0056C15.6292 12.8702 15.7135 12.6866 15.7135 12.4951V8.58197L18.4 7.19557V14.9157L10.8131 18.7858Z"
        fill="#291770"
      />
    </svg>
  );
};

export default McsIcBooking;
