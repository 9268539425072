import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Skeleton } from "antd";
import type { CheckboxProps } from "antd";
import { Collapse } from "antd";
import "./quotation-results-card.css";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import CustomModal from "src/components/molecules/custom-modal";
import useModal from "src/hooks/useModal";
import QuotationVerticalSteps from "../quotation-vertical-steps";
import QuotationHorizontalSteps from "../quotation-horizontal-steps";
import { Select } from "antd";
import TextInput from "src/components/atoms/text-input";
import QuotationSelected from "../../elements/quotation-selected";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { ENDPOINTS } from "src/store/endpoints";
import { useAppSelector, useAppDispatch } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";
import { formatDataForUpdatePricings } from "src/helpers";
import { setLoading } from "src/store/features/alerts";
import useFormattedDate from "src/hooks/useFormattedDate";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import {
  convertToLowerCaseWithSpaces,
  formatDateQuotation,
  removeCommas,
  roundToTwoDecimalPoints,
} from "src/components/molecules/crop-modal-easy/utils";
import {
  setFilteredQuotations,
  setSelectedQuotations,
} from "src/store/features/quotations";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import Item from "antd/es/list/Item";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useSearchParams } from "react-router-dom";
import QuotationRoutes from "src/components/atoms/quotation-routes";

const QuotationResultsCardComponentCustomer = () => {
  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();

  const [url] = useSearchParams();
  const quotationId: string = url?.get("id") || "";

  const [isDisabled, setIsDisabled] = useState(false);
  const { data, quotations, filteredQuotations, selectedQuotations } =
    useAppSelector(quotationsSelector);

  const user = useAppSelector(userSelector);
  const [currentCard, setCurrentCard] = useState(-1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onCheckboxChange: CheckboxProps["onChange"] = (id) => {
    const result = [...selectedQuotations];
    const index = result.indexOf(id);
    if (index > -1) {
      result.splice(index, 1);
    } else {
      result.push(id);
    }
    dispatch(setSelectedQuotations(result));
  };

  const { isOpen, toggle } = useModal();
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const isCustomerPage = useMemo(
    () => (location.href.includes("admin") ? false : true),
    [location],
  );

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };
  const [isEdit, setIsEdit] = useState(-1);
  const [isSave, setIsSave] = useState(false);

  const onEditHandle = async (id: any) => {
    try {
      dispatch(setLoading(true));
      const baseUrl = process.env.REACT_APP_API_URL;
      const updateEndpoint = ENDPOINTS.ADMIN.UPDATE_PRICE;

      const dataToSend = formatDataForUpdatePricings(newArr);

      const token = user?.access_token;
      const updateUrl = baseUrl + updateEndpoint + "/" + id;

      const response = await fetch(updateUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        showToast(
          TOASTR_TYPES.SUCCESS,
          `You successfully updated the pricings.`,
        );
        getDetailsMutation({ id: quotationId });
        // window.location.reload();
      } else {
        showToast(TOASTR_TYPES.ERROR, `Something went wrong.`);
      }
    } finally {
      dispatch(setLoading(false));
    }

    setIsEdit(-1);
    setIsSave(true);
  };

  const handleClose = () => {
    //setNewArr({ arr, arr1, arr2 });
    if (quotations?.length > 0 && currentCard > 0) {
      const priceBreakUp = quotations[currentCard - 1].priceBreakUp;

      setNewArr({
        arr: priceBreakUp?.preCarriage,
        arr1: priceBreakUp?.origin,
        arr2: priceBreakUp?.oceanFreight,
        arr3: priceBreakUp?.destination,
        arr4: priceBreakUp?.postCarriage,
      });
    }
    setIsEdit(-1);
    setIsSave(false);
  };

  useEffect(() => {
    handleClose();
  }, [filteredQuotations]);

  const handleSortChange = (value: string) => {
    const results: any[] = [...filteredQuotations];
    if (value.includes("date")) {
      results.sort((a: any, b: any) => {
        const dateA: any = new Date(a[value]);
        const dateB: any = new Date(b[value]);
        return dateA - dateB;
      });
    } else {
      results.sort((a: any, b: any) => {
        return a[value] - b[value];
      });
    }

    dispatch(setFilteredQuotations(results));
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  const arr = [
    {
      type: "Trucking Rate",
      qty: 1,
      charges: "EUR 1.25",
    },
  ];
  const arr1 = [
    {
      type: "Bill of Landing",
      qty: 1,
      charges: "EUR 0.23",
    },
    {
      type: "Customs Clearance",
      qty: 1,
      charges: "EUR 0.36",
    },
    {
      type: "Handling",
      qty: 1,
      charges: "EUR 0.24",
    },
    {
      type: "Stuffing",
      qty: 1,
      charges: "EUR 0.45/M3",
    },
    {
      type: "Solas/VGM",
      qty: 1,
      charges: "EUR 0.35",
    },
  ];
  const arr2 = [
    {
      type: "Ocean Freight",
      qty: 1,
      charges: "EUR 0.12/M3",
    },
  ];

  const [newArr, setNewArr] = useState<any>({ arr, arr1, arr2 });

  useEffect(() => {
    let disabled = false;

    const checkArray = (array: any) => {
      if (array && array.length > 0) {
        return array.some((item: any) => {
          return !(
            item.type &&
            // item.charges &&
            item?.rate_basis &&
            item?.qty &&
            item?.per_rate_basis
          );
        });
      }
      return false;
    };

    disabled = checkArray(newArr.arr1) || checkArray(newArr.arr2);

    setIsDisabled(disabled);
  }, [newArr]);

  useEffect(() => {
    if (quotations?.length > 0 && currentCard > 0) {
      const priceBreakUp = quotations[currentCard - 1].priceBreakUp;

      // alert(JSON.stringify(priceBreakUp))

      setNewArr({
        arr: priceBreakUp?.preCarriage,
        arr1: priceBreakUp?.origin,
        arr2: priceBreakUp?.oceanFreight,
        arr3: priceBreakUp?.destination,
        arr4: priceBreakUp?.postCarriage,
      });
    }
  }, [currentCard]);

  const handleChange = (
    value: any,
    key: string,
    i: number,
    arrName?: string,
  ) => {
    let newArrTest: any;

    switch (arrName) {
      case "arr1": {
        newArrTest = [...newArr["arr1"]];
        const testObj: any = JSON.parse(JSON.stringify(newArrTest[i]));
        testObj[key] = value;
        newArrTest[i] = testObj;
        setNewArr((prev: any) => ({ ...prev, arr1: newArrTest }));
        break;
      }
      case "arr2": {
        newArrTest = [...newArr["arr2"]];
        const testObj: any = JSON.parse(JSON.stringify(newArrTest[i]));
        testObj[key] = value;
        newArrTest[i] = testObj;
        setNewArr((prev: any) => ({ ...prev, arr2: newArrTest }));
        break;
      }
      case "arr3": {
        newArrTest = [...newArr["arr3"]];
        const testObj: any = JSON.parse(JSON.stringify(newArrTest[i]));
        testObj[key] = value;
        newArrTest[i] = testObj;
        setNewArr((prev: any) => ({ ...prev, arr3: newArrTest }));
        break;
      }
      case "arr4": {
        newArrTest = [...newArr["arr4"]];
        const testObj: any = JSON.parse(JSON.stringify(newArrTest[i]));
        testObj[key] = value;
        newArrTest[i] = testObj;
        setNewArr((prev: any) => ({ ...prev, arr4: newArrTest }));
        break;
      }
      default: {
        newArrTest = [...newArr["arr"]];
        const testObj: any = JSON.parse(JSON.stringify(newArrTest[i]));
        testObj[key] = value;
        newArrTest[i] = testObj;
        setNewArr((prev: any) => ({ ...prev, arr: newArrTest }));
        break;
      }
    }
  };

  const handleDel = (index: number, arrName?: string) => {
    let newArrTest: any;
    switch (arrName) {
      case "arr1": {
        newArrTest = [...newArr["arr1"]];
        newArrTest.splice(index, 1);
        setNewArr((prev: any) => ({ ...prev, arr1: newArrTest }));
        break;
      }
      case "arr2": {
        newArrTest = [...newArr["arr2"]];
        newArrTest.splice(index, 1);
        setNewArr((prev: any) => ({ ...prev, arr2: newArrTest }));
        break;
      }
      case "arr3": {
        newArrTest = [...newArr["arr3"]];
        newArrTest.splice(index, 1);
        setNewArr((prev: any) => ({ ...prev, arr3: newArrTest }));
        break;
      }
      case "arr4": {
        newArrTest = [...newArr["arr4"]];
        newArrTest.splice(index, 1);
        setNewArr((prev: any) => ({ ...prev, arr4: newArrTest }));
        break;
      }
      default: {
        newArrTest = [...newArr["arr"]];
        newArrTest.splice(index, 1);
        // setNewArr({ ...newArr, arr: newArr });
        setNewArr((prev: any) => ({ ...prev, arr: newArrTest }));
        // setArr(newArray);
        break;
      }
    }
  };

  const requestQuote = (id: number, quotationId: string) => {
    navigate(`/confirm-quotation/${id}?quoteId=${quotationId}`);
  };

  return (
    <>
      <div className="flex justify-between py-10 pt-20">
        <label className="m-0 w-2/5 py-6 pr-5 text-slate-500">
          Showing {filteredQuotations?.length || 0} results
        </label>
        <form className="flex justify-between">
          <div className={`mr-24 ${isCustomerPage ? "hidden" : "hidden"}`}>
            <Select
              placeholder="View Prices In"
              style={{ width: "100%" }}
              onChange={handleSortChange}
              disabled
              options={[
                { label: "USD", value: "usd" },
                { label: "EUR", value: "eur" },
                { label: "THB", value: "thb" },
                { label: "CDF", value: "cdf" },
              ]}
            />
          </div>
          {/* <div className="m-0 mr-5 w-full  py-6 text-end ">Sort by:</div> */}
          <div className="flex" style={{ width: "100%" }}>
            <div className="m-0 mr-5 w-full py-6 text-end ">Sort by:</div>
            <Select
              placeholder="Price"
              className="w-fit"
              // disabled
              style={{ width: "100%", minWidth: 200 }}
              onChange={handleSortChange}
              options={[
                { label: "Price", value: "price" },
                // { label: "Fastest Route", value: "fastest" },
                { label: "Transit Time", value: "transit_time" },
                { label: "Cargo Ready Date", value: "date" },
              ]}
            />
          </div>
        </form>
      </div>
      <hr />
      <div className="py-10"></div>
      <div className="mb-80">
        {Array.isArray(filteredQuotations) &&
          filteredQuotations?.map((quotItem: any, i: any) =>
            quotItem ? (
              <div
                key={quotItem.id}
                className={`relative mb-30 flex flex-col rounded-[10px] border-primary-100 ${selectedQuotations?.includes(quotItem.id) ? "bg-primary-100" : "bg-primary-50"} px-24 shadow-card-shadow`}
              >
                <div className="absolute left-[24px] top-[6px]">
                  <Checkbox
                    checked={selectedQuotations?.includes(quotItem.id)}
                    onChange={() => onCheckboxChange(quotItem.id)}
                  ></Checkbox>
                </div>

                <div className="flex justify-between border-b border-[#AFB6D1] px-44 py-8">
                  <div className="text-14 font-semibold leading-20 text-primary-600">
                    Carrier
                  </div>
                  <div className="text-14 font-semibold leading-20 text-primary-600">
                    Route
                  </div>
                  <div className="text-14 font-semibold leading-20 text-primary-600">
                    Pricing
                  </div>
                </div>
                <div className="flex justify-between gap-10 overflow-x-auto border-b border-[#AFB6D1] py-8 pe-44">
                  <div className="flex flex-col text-center">
                    <img
                      className="mx-auto w-[74px]"
                      src="/assets/images/mcs-marinair-logo-1.svg"
                      alt="marinair-logo"
                      title="marinair-logo"
                    />
                    <p className="text-16 font-semibold leading-24 text-gray-700">
                      Marinair Cargo Services
                    </p>
                  </div>
                  <QuotationRoutes
                    source={data?.source_country}
                    destination={data?.destination_country}
                    date={quotItem.date}
                    mode={quotItem.mode_of_transport}
                    isStop={quotItem.isTransshipment == true ? true : false}
                    transit={quotItem.Transshipment}
                    transitTime={quotItem?.transit_time}
                  />
                  <div className="flex flex-col items-center justify-center gap-2">
                    <p>
                      <span className="pe-4 text-16 font-normal leading-24 text-gray-600">
                        EUR
                      </span>
                      <span className="text-24 font-semibold leading-32 text-gray-700">
                        {roundToTwoDecimalPoints(quotItem.price)}
                      </span>
                    </p>
                    <p className="text-12 font-normal leading-18 text-gray-600">
                      Valid until{" "}
                      {moment(quotItem.valid_till).format("MMM D YYYY")}
                    </p>

                    {isCustomerPage && (
                      <CustomButton
                        variant="secondary"
                        onClick={() => requestQuote(quotItem.id, quotationId)}
                        className="w-full rounded-lg text-14 font-semibold"
                        disabled={true}
                        title="Coming soon"
                      >
                        Request to Book
                      </CustomButton>
                    )}
                  </div>
                </div>
                <div>
                  <Collapse
                    destroyInactivePanel
                    activeKey={currentCard}
                    items={[
                      {
                        key: i + 1,
                        label: (
                          <div className="flex items-center justify-center">
                            <div className="expand-text text-12  font-semibold leading-18 text-primary-400">
                              Expand for price breakdown and timeline
                            </div>
                            <div className="shrink-back-text text-12 font-semibold leading-18 text-primary-400">
                              Shrink Back
                            </div>
                            <div className="icon">
                              <Icon.McsIcCollapseDown />
                            </div>
                          </div>
                        ),

                        children: (
                          <>
                            {!isCustomerPage && (
                              <div className="flex justify-end py-12 pl-44">
                                {isEdit !== quotItem.id ? (
                                  <CustomButton
                                    className=""
                                    variant="primary-icon-btn"
                                    onClick={() => setIsEdit(quotItem.id)}
                                  >
                                    <div className="flex items-center gap-0.5">
                                      <i className="shrink-0">
                                        <Icon.McsIcEditWhite />
                                      </i>
                                      <span>Edit Pricings</span>
                                    </div>
                                  </CustomButton>
                                ) : (
                                  <>
                                    <CustomButton
                                      variant="primary-icon-btn"
                                      onClick={() => onEditHandle(quotItem?.id)}
                                      className="mr-8"
                                      disabled={isDisabled}
                                    >
                                      <div className="flex items-center gap-0.5 ">
                                        <i className="shrink-0">
                                          <Icon.IcTickArrow />
                                        </i>
                                        <span>Save change</span>
                                      </div>
                                    </CustomButton>
                                    <CustomButton
                                      variant="secondary-icon-btn"
                                      onClick={handleClose}
                                    >
                                      <div className="flex items-center gap-0.5">
                                        <i className="text-invert shrink-0">
                                          <Icon.IcCloseBtn />
                                        </i>
                                        <span>Cancel</span>
                                      </div>
                                    </CustomButton>
                                  </>
                                )}
                              </div>
                            )}
                            <div className="flex justify-between">
                              <div className="gap-35 flex w-[40%] flex-col">
                                <div>
                                  <QuotationVerticalSteps
                                    item={data}
                                    quotItem={quotItem}
                                  />
                                </div>
                                {/* <div>
                                <button
                                  // variant="infolink"
                                  onClick={handleOpenAddModal}
                                >
                                  <div className="flex items-center gap-2 ">
                                    <i className="shrink-0">
                                      <Icon.McsIcInfo />
                                    </i>
                                    <span className="font-inter text-12 font-normal leading-18 text-primary-400 ">
                                      Check port remarks here
                                    </span>
                                  </div>
                                </button>
                              </div> */}
                              </div>
                              <div className="w-[60%]">
                                <div className="flex w-full items-center  justify-between  border-b border-[#AFB6D1] py-10 text-gray-800">
                                  <p className="w-1/4 pr-10 text-start font-semibold">
                                    Description
                                  </p>
                                  <p className="w-1/4 pr-10 text-center font-semibold">
                                    Calculation Quantity
                                  </p>
                                  <p className="w-1/4 pr-10 text-end font-semibold">
                                    Tariff
                                  </p>
                                  <p className="w-1/4 pr-10 text-end font-semibold">
                                    Charges
                                  </p>
                                </div>
                                <div
                                  className={
                                    newArr?.arr?.length
                                      ? "flex w-full justify-between py-10"
                                      : "hidden"
                                  }
                                >
                                  <p className="leaing-18 font-medium text-primary-600">
                                    Pre-Carriage
                                  </p>
                                </div>
                                <div
                                  className={
                                    newArr?.arr?.length
                                      ? "border-b border-[#AFB6D1] pb-10"
                                      : "hidden"
                                  }
                                >
                                  {newArr?.arr?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex w-full justify-between "
                                        >
                                          <p className="w-1/4 pr-10 text-start font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.type}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                value={item.type}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/4 pr-10 text-center font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.qty}</p>
                                              // <p>1</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                value={item.qty}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                allowNumbersOnly={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/4 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>
                                                {roundToTwoDecimalPoints(
                                                  item.charges,
                                                )}
                                              </p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.charges}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "charges",
                                                    index,
                                                    "arr",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                allowDecimals={true}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8 custom-input",
                                                  inputClassName: "",
                                                }}
                                                disabled={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          {isEdit == quotItem.id && (
                                            <button
                                              disabled={index === 0}
                                              onClick={() => {
                                                handleDel(index, "arr");
                                              }}
                                              className="mt-10  flex cursor-pointer items-start disabled:cursor-not-allowed"
                                            >
                                              <Icon.McsIcDelete />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    },
                                  )}
                                  {isEdit == quotItem.id && (
                                    <button
                                      onClick={() => {
                                        setIsDisabled(true);
                                        setNewArr({
                                          ...newArr,
                                          arr: [
                                            ...newArr.arr,
                                            {
                                              type: "",
                                              qty: 1,
                                              charges: "",
                                            },
                                          ],
                                        });
                                      }}
                                      className="flex text-nowrap font-inter text-12 font-normal leading-18 text-primary-400"
                                    >
                                      + Add another pricing line
                                    </button>
                                  )}
                                </div>
                                <div
                                  className={
                                    newArr?.arr1?.length
                                      ? "flex w-full justify-between py-10"
                                      : "hidden"
                                  }
                                >
                                  <p className="font-medium leading-18 text-primary-600">
                                    Origin
                                  </p>
                                </div>
                                <div
                                  className={
                                    newArr?.arr1?.length
                                      ? "border-b border-[#AFB6D1] pb-10"
                                      : "hidden"
                                  }
                                >
                                  {newArr?.arr1?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div key={index}>
                                          <div className="flex w-full justify-between pb-10">
                                            <p className="w-1/5 pr-10 text-start font-inter text-12 font-normal leading-18 text-primary-400">
                                              {isEdit !== quotItem.id && (
                                                <p>{item.type}</p>
                                              )}
                                              {isEdit == quotItem.id && (
                                                <TextInput
                                                  value={item.type}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "type",
                                                      index,
                                                      "arr1",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input",
                                                    inputClassName: "",
                                                  }}
                                                ></TextInput>
                                              )}
                                            </p>
                                            <p className="w-[30%] pr-10 text-center font-inter text-12 font-normal leading-18 text-primary-400">
                                              {isEdit !== quotItem.id && (
                                                <p>
                                                  {item.qty} x{" "}
                                                  {item?.rate_basis ==
                                                  "Per_wm" ? (
                                                    <>
                                                      per M<sup>3</sup>
                                                    </>
                                                  ) : (
                                                    convertToLowerCaseWithSpaces(
                                                      item?.rate_basis,
                                                    )
                                                  )}
                                                </p>
                                              )}
                                              {isEdit == quotItem.id && (
                                                <div className="grid grid-cols-12 gap-1">
                                                  <TextInput
                                                    value={item.qty}
                                                    onChange={(e: any) =>
                                                      handleChange(
                                                        e.target.value,
                                                        "qty",
                                                        index,
                                                        "arr1",
                                                      )
                                                    }
                                                    name="text"
                                                    type="text"
                                                    classes={{
                                                      containerClassName:
                                                        "mb-24 lg:mb-8  custom-input col-span-4",
                                                      inputClassName: "",
                                                    }}
                                                    allowNumbersOnly={true}
                                                  />
                                                  <TextInput
                                                    value={convertToLowerCaseWithSpaces(
                                                      item.rate_basis,
                                                    )}
                                                    onChange={(e: any) =>
                                                      handleChange(
                                                        e.target.value,
                                                        "rate_basis",
                                                        index,
                                                        "arr1",
                                                      )
                                                    }
                                                    name="text"
                                                    type="text"
                                                    classes={{
                                                      containerClassName:
                                                        "mb-24 lg:mb-8  custom-input  col-span-8",
                                                      inputClassName: "",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </p>
                                            <p className="flex w-1/5 justify-end gap-1 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                              {isEdit !== quotItem.id && (
                                                <>
                                                  EUR{" "}
                                                  <p>
                                                    {item.per_rate_basis ==
                                                    "0.00"
                                                      ? item?.charges
                                                      : item.per_rate_basis}
                                                  </p>
                                                  {/* item.per_rate_basis =="0.00" */}
                                                </>
                                              )}
                                              {isEdit == quotItem.id && (
                                                <TextInput
                                                  value={removeCommas(
                                                    item.per_rate_basis ==
                                                      "0.00"
                                                      ? item?.charges
                                                      : item.per_rate_basis,
                                                  )}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "per_rate_basis",
                                                      index,
                                                      "arr1",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  allowDecimals={true}
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input",
                                                    inputClassName: "",
                                                  }}
                                                ></TextInput>
                                              )}
                                            </p>
                                            <p className="w-1/5 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                              {isEdit !== quotItem.id && (
                                                <p>{item.charges}</p>
                                              )}
                                              {isEdit == quotItem.id && (
                                                <TextInput
                                                  // value={item.charges}
                                                  value={
                                                    item.per_rate_basis ==
                                                    "0.00"
                                                      ? removeCommas(
                                                          item?.charges,
                                                        ) * item?.qty
                                                      : removeCommas(
                                                          item.per_rate_basis,
                                                        ) * item?.qty
                                                  }
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "charges",
                                                      index,
                                                      "arr1",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  allowNumbersOnly={true}
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input",
                                                    inputClassName: "",
                                                  }}
                                                  disabled={true}
                                                ></TextInput>
                                              )}
                                            </p>
                                            {isEdit == quotItem.id && (
                                              <button
                                                disabled={index <= 0}
                                                onClick={() => {
                                                  handleDel(index, "arr1");
                                                }}
                                                className="mt-10  flex cursor-pointer items-start disabled:cursor-not-allowed"
                                              >
                                                <Icon.McsIcDelete />
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                  {isEdit == quotItem.id && (
                                    <button
                                      onClick={() => {
                                        setIsDisabled(true);
                                        setNewArr({
                                          ...newArr,
                                          arr1: [
                                            ...newArr.arr1,
                                            {
                                              type: "",
                                              qty: 1,
                                              charges: "",
                                            },
                                          ],
                                        });
                                        // setArr1([
                                        //   ...arr1,
                                        //   {
                                        //     carriage: "",
                                        //     quantity: "",
                                        //     tariff: "",
                                        //     charges: "",
                                        //     fill: false,
                                        //   },
                                        // ]);
                                      }}
                                      className="flex text-nowrap font-inter text-12 font-normal leading-18 text-primary-400"
                                    >
                                      + Add another pricing line
                                    </button>
                                  )}
                                </div>

                                <div
                                  className={
                                    newArr?.arr2?.length
                                      ? "border-b border-[#AFB6D1] pb-10"
                                      : "hidden"
                                  }
                                >
                                  <div className="flex w-full justify-between py-10">
                                    <p className="font-medium leading-18 text-primary-600">
                                      Ocean Freight
                                    </p>
                                  </div>
                                  {newArr?.arr2?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex w-full justify-between "
                                        >
                                          <p className="w-1/5 pr-10 text-start font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.type}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.type}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr2",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-[30%] pr-10 text-center font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <>
                                                <p>
                                                  {item.qty} x{" "}
                                                  {item?.rate_basis ==
                                                  "Per_wm" ? (
                                                    <>
                                                      per M<sup>3</sup>
                                                    </>
                                                  ) : (
                                                    item?.rate_basis
                                                  )}
                                                </p>
                                              </>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <div className="grid grid-cols-12 gap-1">
                                                <TextInput
                                                  value={item.qty}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "qty",
                                                      index,
                                                      "arr2",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input col-span-4",
                                                    inputClassName: "",
                                                  }}
                                                  allowNumbersOnly={true}
                                                />
                                                <TextInput
                                                  value={convertToLowerCaseWithSpaces(
                                                    item.rate_basis,
                                                  )}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "rate_basis",
                                                      index,
                                                      "arr2",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input  col-span-8",
                                                    inputClassName: "",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </p>
                                          <p className="w-1/5 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>EUR {item.per_rate_basis}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.per_rate_basis}
                                                name="text"
                                                type="text"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "per_rate_basis",
                                                    index,
                                                    "arr2",
                                                  )
                                                }
                                                allowDecimals={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/5 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.charges}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                // value={item.charges}
                                                value={
                                                  item.per_rate_basis == "0.00"
                                                    ? removeCommas(
                                                        item?.charges,
                                                      ) * item?.qty
                                                    : removeCommas(
                                                        item.per_rate_basis,
                                                      ) * item?.qty
                                                }
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "charges",
                                                    index,
                                                    "arr2",
                                                  )
                                                }
                                                name="text"
                                                type="number"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                disabled={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          {isEdit == quotItem.id && (
                                            <button
                                              disabled={index === 0}
                                              onClick={() => {
                                                handleDel(index, "arr2");
                                              }}
                                              className="cursor-pointer p-8 disabled:cursor-not-allowed"
                                            >
                                              <Icon.McsIcDelete />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    },
                                  )}
                                  {isEdit == quotItem.id && (
                                    <button
                                      onClick={() => {
                                        setIsDisabled(true);
                                        setNewArr({
                                          ...newArr,
                                          arr2: [
                                            ...newArr.arr2,
                                            {
                                              type: "",
                                              qty: 1,
                                              charges: "",
                                            },
                                          ],
                                        });
                                      }}
                                      className="flex text-nowrap font-inter text-12 font-normal leading-18 text-primary-400"
                                    >
                                      + Add another pricing line
                                    </button>
                                  )}
                                </div>
                                <div
                                  className={
                                    newArr?.arr3?.length
                                      ? "border-b border-[#AFB6D1] pb-10"
                                      : "hidden"
                                  }
                                >
                                  <div className="flex w-full justify-between py-10">
                                    <p className="font-medium leading-18 text-primary-600">
                                      Destination
                                    </p>
                                  </div>
                                  {newArr?.arr3?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex w-full justify-between "
                                        >
                                          <p className="w-1/5 pr-10 text-start font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.type}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.type}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr3",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-[30%] pr-10 text-center font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <>
                                                <p>
                                                  {item.qty} x{" "}
                                                  {item?.rate_basis ==
                                                  "Per_wm" ? (
                                                    <>
                                                      per M<sup>3</sup>
                                                    </>
                                                  ) : (
                                                    item?.rate_basis
                                                  )}
                                                </p>
                                              </>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <div className="grid grid-cols-12 gap-1">
                                                <TextInput
                                                  value={item.qty}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "qty",
                                                      index,
                                                      "arr3",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input col-span-4",
                                                    inputClassName: "",
                                                  }}
                                                  allowNumbersOnly={true}
                                                />
                                                <TextInput
                                                  value={convertToLowerCaseWithSpaces(
                                                    item.rate_basis,
                                                  )}
                                                  onChange={(e: any) =>
                                                    handleChange(
                                                      e.target.value,
                                                      "rate_basis",
                                                      index,
                                                      "arr3",
                                                    )
                                                  }
                                                  name="text"
                                                  type="text"
                                                  classes={{
                                                    containerClassName:
                                                      "mb-24 lg:mb-8  custom-input  col-span-8",
                                                    inputClassName: "",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </p>
                                          <p className="w-1/5 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>EUR {item.per_rate_basis}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.per_rate_basis}
                                                name="text"
                                                type="text"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "per_rate_basis",
                                                    index,
                                                    "arr3",
                                                  )
                                                }
                                                allowDecimals={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/5 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.charges}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                // value={item.charges}
                                                value={
                                                  item.per_rate_basis == "0.00"
                                                    ? removeCommas(
                                                        item?.charges,
                                                      ) * item?.qty
                                                    : removeCommas(
                                                        item.per_rate_basis,
                                                      ) * item?.qty
                                                }
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "charges",
                                                    index,
                                                    "arr3",
                                                  )
                                                }
                                                name="text"
                                                type="number"
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                disabled={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          {isEdit == quotItem.id && (
                                            <button
                                              disabled={index === 0}
                                              onClick={() => {
                                                handleDel(index, "arr3");
                                              }}
                                              className="cursor-pointer p-8 disabled:cursor-not-allowed"
                                            >
                                              <Icon.McsIcDelete />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    },
                                  )}
                                  {isEdit == quotItem.id && (
                                    <button
                                      onClick={() => {
                                        setIsDisabled(true);
                                        setNewArr({
                                          ...newArr,
                                          arr3: [
                                            ...newArr.arr3,
                                            {
                                              type: "",
                                              qty: 1,
                                              charges: "",
                                            },
                                          ],
                                        });
                                      }}
                                      className="flex text-nowrap font-inter text-12 font-normal leading-18 text-primary-400"
                                    >
                                      + Add another pricing line
                                    </button>
                                  )}
                                </div>

                                <div
                                  className={
                                    newArr?.arr4?.length
                                      ? "flex w-full justify-between py-10"
                                      : "hidden"
                                  }
                                >
                                  <p className="leaing-18 font-medium text-primary-600">
                                    Post-Carriage
                                  </p>
                                </div>
                                <div
                                  className={
                                    newArr?.arr4?.length
                                      ? "border-b border-[#AFB6D1] pb-10"
                                      : "hidden"
                                  }
                                >
                                  {newArr?.arr4?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex w-full justify-between "
                                        >
                                          <p className="w-1/4 pr-10 text-start font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.type}</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr4",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                value={item.type}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/4 pr-10 text-center font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>{item.qty}</p>
                                              // <p>1</p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "type",
                                                    index,
                                                    "arr4",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                value={item.qty}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8  custom-input",
                                                  inputClassName: "",
                                                }}
                                                allowNumbersOnly={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          <p className="w-1/4 pr-10 text-end font-inter text-12 font-normal leading-18 text-primary-400">
                                            {isEdit !== quotItem.id && (
                                              <p>
                                                {roundToTwoDecimalPoints(
                                                  item.charges,
                                                )}
                                              </p>
                                            )}
                                            {isEdit == quotItem.id && (
                                              <TextInput
                                                value={item.charges}
                                                onChange={(e: any) =>
                                                  handleChange(
                                                    e.target.value,
                                                    "charges",
                                                    index,
                                                    "arr4",
                                                  )
                                                }
                                                name="text"
                                                type="text"
                                                allowDecimals={true}
                                                classes={{
                                                  containerClassName:
                                                    "mb-24 lg:mb-8 custom-input",
                                                  inputClassName: "",
                                                }}
                                                disabled={true}
                                              ></TextInput>
                                            )}
                                          </p>
                                          {isEdit == quotItem.id && (
                                            <button
                                              disabled={index === 0}
                                              onClick={() => {
                                                handleDel(index, "arr4");
                                              }}
                                              className="mt-10  flex cursor-pointer items-start disabled:cursor-not-allowed"
                                            >
                                              <Icon.McsIcDelete />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    },
                                  )}
                                  {isEdit == quotItem.id && (
                                    <button
                                      onClick={() => {
                                        setIsDisabled(true);
                                        setNewArr({
                                          ...newArr,
                                          arr4: [
                                            ...newArr.arr4,
                                            {
                                              type: "",
                                              qty: 1,
                                              charges: "",
                                            },
                                          ],
                                        });
                                      }}
                                      className="flex text-nowrap font-inter text-12 font-normal leading-18 text-primary-400"
                                    >
                                      + Add another pricing line
                                    </button>
                                  )}
                                </div>

                                <div className="flex w-full items-center justify-between py-10">
                                  <p className="font-medium leading-18  text-primary-600">
                                    Total
                                  </p>
                                  <span className="text-18 font-semibold leading-32 text-primary-500-base">
                                    EUR{" "}
                                    {roundToTwoDecimalPoints(quotItem.price)}
                                  </span>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        ),
                      },
                    ]}
                    onChange={(key: any) => {
                      if (currentCard == i + 1) {
                        setCurrentCard(-1);
                      } else {
                        setCurrentCard(i + 1);
                      }
                    }}
                    className="custom-collapse"
                  />
                </div>
                <CustomModal
                  title="Port Remarks"
                  isOpen={isAddModalOpen}
                  toggle={handleCloseAddModal}
                  maskClosable={true}
                  icon={<Icon.McsIcChevronBack />}
                  width={955}
                  innerClass="custom-remarks-modal"
                >
                  <div className="flex flex-col gap-2">
                    <p className="text-12 font-normal leading-18 text-primary-400">
                      BRSSZ
                    </p>
                    <ul className="modal-list">
                      <li>Fumigation required for all wooden packages.</li>
                      <li>MANIFEST CLOSING 1 DAY BEFORE SAILING</li>
                      <li>Original B/L needed.</li>
                      <li>
                        B/L must mention ncm code, please advise before vessel
                        departing.
                      </li>
                      <li>Customs Clearance at Port of Entry by Consignee.</li>
                      <li>CNPJ number of consignee is mandatory.</li>
                      <li>Oceanfreight to be show on HBL.</li>
                      <li>
                        DG cargo : For all hazardous cargo MSDS required prior
                        shipping and subject approval carriers/agent approval.
                        (Imo 2 is not accepted)
                      </li>
                      <li>
                        Manifest Correction Eur 250,* + all additional charges
                        caused by correction
                      </li>
                      <li>
                        Personal effects / Household goods : to Brasil not
                        acceptable
                      </li>
                      <li>
                        Marks and Numbers: All goods must have marks and
                        sequential identification number on the total of
                        packages contemplated by document shipments.
                      </li>
                    </ul>
                  </div>
                </CustomModal>
              </div>
            ) : (
              <Skeleton.Button
                className="my-8"
                size="large"
                key={quotItem.id}
                block={true}
                active={true}
              />
            ),
          )}
      </div>
      <QuotationSelected />
    </>
  );
};

export default QuotationResultsCardComponentCustomer;
