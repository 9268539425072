export const TABS = {
  SETTINGS: {
    tab: "0",
    value: "General Settings",
  },
  INTEGRATION: {
    tab: "1",
    value: "Integration",
  },
  PASSWORD: {
    tab: "2",
    value: "Change Password",
  },
};
