import React from 'react'

const McsIcChevronRight = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={16}
  height={16}
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    d="M6 3L11 8L6 13"
    stroke="#D4D9E0"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcChevronRight