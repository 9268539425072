import React, { useState } from "react";
import { Button, Skeleton, Space } from "antd";
import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonNode from "antd/es/skeleton/Node";

const Loader: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const showTime = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300000);
  };

  return (
    <>
      {/* <Space direction="vertical" style={{ width: "100%" }} size={16}>
        <Button onClick={showTime}>Show Skeleton</Button>
        {loading && (
          <div className="flex flex-col gap-4 ">
            <div className="inline-block">
              <SkeletonNode
                className="bg-[#291770]"
                active
                style={{ width: 202, height: 44 }}
              >
                <div className=" h-5 w-[170px]  bg-[#00406C]  "></div>
              </SkeletonNode>
            </div>
            <div className="inline-block">
              <SkeletonNode
                className="bg-[#291770]"
                active
                style={{ width: 202, height: 44 }}
              >
                <div className=" h-5 w-[170px]  bg-[#00406C]  "></div>
              </SkeletonNode>
            </div>
            <div className="inline-block">
              <SkeletonNode
                className="bg-primary-50"
                active
                style={{ width: 202, height: 44 }}
              >
                <div className=" h-5 w-[170px]  bg-primary-100"></div>
              </SkeletonNode>
            </div>
            <div className="inline-block">
              <SkeletonNode
                className="bg-primary-50"
                active
                style={{ width: 202, height: 44 }}
              >
                <div className=" h-5 w-[170px]  bg-primary-100"></div>
              </SkeletonNode>
            </div>
          </div>
        )}
      </Space> */}

      {/* Filter bar loader  */}

      {/* <Space direction="vertical" style={{ width: "100%" }} size={12}>
        <Button onClick={showTime}>Show Skeleton</Button>
        {loading && (
          <div className="flex  flex-col gap-3 ">
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#CCDEE9]  from-0% to-white  to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-white  from-0% to-[#CCDEE9] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#CCDEE9]  from-0% to-white  to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-white  from-0% to-[#CCDEE9] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#CCDEE9]  from-0% to-white  to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-white  from-0% to-[#CCDEE9] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#CCDEE9]  from-0% to-white  to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-white  from-0% to-[#CCDEE9] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
          </div>
        )}
      </Space> */}

      {/* <Space direction="vertical" style={{ width: "100%" }} size={12}>
        <Button onClick={showTime}>Show Skeleton</Button>
        {loading && (
          <div className="flex  flex-col gap-3 ">
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#00406C]  from-0% to-[#407BA4]   to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r  from-[#407BA4]  from-0%
                  to-[#00406C] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r from-[#00406C]  from-0% to-[#407BA4]   to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
            <div className="inline-block">
              <Skeleton.Button
                className="  bg-gradient-to-r  from-[#407BA4]  from-0%
                  to-[#00406C] to-100% "
                active
                style={{ width: 202, height: 22 }}
              />
            </div>
          </div>
        )}
      </Space> */}
    </>
  );
};

export default Loader;
