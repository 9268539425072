import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { authTokenSelector } from "src/store/features/auth/selectors";
import { authTokenCusSelector } from "src/store/features/auth/customer/selectors";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { clearToken } from "src/utils";
import { logout } from "src/store/features/auth/actions";
import { setAuthToken } from "src/store/features/auth";
import { setAuthCusToken } from "src/store/features/auth/customer";

interface IAppRoute {
  Component: any;
  isAuthProtected: boolean;
  isCustomer?: boolean;
}

const AppRoute = ({
  Component,
  isAuthProtected,
  isCustomer = false,
}: IAppRoute) => {
  const adminToken = useAppSelector(authTokenSelector);
  const cusToken = useAppSelector(authTokenCusSelector);
  const dispatch = useAppDispatch();

  const token = isCustomer ? cusToken : adminToken;

  useEffect(() => {
    if (isAuthProtected && !token) {
      dispatch(logout());
      dispatch(setAuthToken(""));
      dispatch(setAuthCusToken(""));
      clearToken();
    }
  }, [token, isAuthProtected]);

  if (token || !isAuthProtected) {
    return <Component />;
  } else {
    return <Navigate to={`${isCustomer ? "/login" : "/admin/login"}`} />;
  }
};

export default AppRoute;
