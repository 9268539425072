import React from "react";

const AuthLayoutComponent = ({ children }: any) => {
  return (
    <div className="h-full">
      <div className="min-h-screen grid-cols-12 xl:grid">
        <div className="col-span-4">
          <div className="image-container hidden h-full xl:block">
            <img
              className="h-full w-full object-cover"
              src="/assets/images/mcs-ship.svg"
              alt="Ship"
              title="Marinair Cargo Services"
            />
          </div>
        </div>
        <div className="col-span-8 flex h-screen items-center justify-center pl-20 pr-20 xl:p-0">
          <div className="mx-auto w-[454px]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayoutComponent;
