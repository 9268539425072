import React from "react";

const McsIcEditWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.2059 4.51425L14.8529 7.29691M10.4412 17.5H17.5M3.38235 13.7898L2.5 17.5L6.02941 16.5724L16.2524 5.82581C16.5832 5.47793 16.769 5.00616 16.769 4.51425C16.769 4.02234 16.5832 3.55057 16.2524 3.20269L16.1006 3.04315C15.7697 2.69537 15.3209 2.5 14.8529 2.5C14.385 2.5 13.9362 2.69537 13.6053 3.04315L3.38235 13.7898Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcEditWhite;
