import React from 'react'

const McsIcFilePlaceholder = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.54038 1.78788C9.82577 1.6267 10.1742 1.6267 10.4596 1.78788L17.0221 5.4944C17.3173 5.66113 17.5 5.97477 17.5 6.31485V13.6858C17.5 14.0259 17.3173 14.3395 17.0221 14.5063L10.4596 18.2128C10.1742 18.374 9.82577 18.374 9.54038 18.2128L2.97788 14.5063C2.68268 14.3395 2.5 14.0259 2.5 13.6858V6.31485C2.5 5.97477 2.68268 5.66113 2.97788 5.4944L9.54038 1.78788Z"
      stroke="#004677"
      strokeWidth="1.5"
    />
  </svg>
  
  )
}

export default McsIcFilePlaceholder