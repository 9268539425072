import React from "react";
import { IFormHeader } from "./form-header.types";

const FormHeader = ({ title, description }: IFormHeader) => {
  return (
    <div className="mb-32 ">
      <div className="flex mb-32">
        <div>
          <img
            className="w-full "
            src="/assets/images/mcs-logo.svg"
            alt="mcs"
            title="mcs"
          />
        </div>
        <div className="px-32">
          <div className="border-l h-full border-gray-500-base"></div>
        </div>
        <div>
          <img
            className="w-full"
            src="/assets/images/mcs-marinair-logo-1.svg"
            alt="Marinair"
            title="Marinair"
          />
        </div>
      </div>
      <h1 className="mb-12 text-32 sm:text-36 font-semibold leading-40 sm:leading-44 text-primary-500-base tracking-0.72">
        {title}
      </h1>
      <p className="text-14 sm:text-16 font-normal leading-20 sm:leading-24 text-gray-500-base">
        {description}
      </p>
    </div>
  );
};

export default FormHeader;
