import React from 'react'
import PaginationComponent from './pagination.component'

const PaginationContainer = ({handlePageChange,pagination}:any) => {
    
    return (
        <PaginationComponent pagination={pagination} handlePageChange={handlePageChange}/>
    )
}

export default PaginationContainer