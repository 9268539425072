import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Admin User Management APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminsList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.ADMIN_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getAdminById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.ADMIN_BY_ID + `/${data.id}/edit`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    addAdmin: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const formData = new FormData();
        const role:string = filteredParams?.role;
        formData.append("name", filteredParams?.name);
        formData.append("role", role.toLowerCase());
        formData.append("email", filteredParams?.email);
        formData.append("password", filteredParams?.password);

        return {
          url: ENDPOINTS.ADMIN.ADD_ADMIN,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateAdmin: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const formData = new FormData();
        const role:string = filteredParams?.role;
        formData.append("name", filteredParams?.name);
        formData.append("role", role.toLowerCase());
        formData.append("email", filteredParams?.email);
        formData.append("password", filteredParams?.password);

        const data = {
          name: filteredParams?.name,
          role: role.toLowerCase(),
          email: filteredParams?.email,
          password: filteredParams?.password,
        };

        return {
          url: ENDPOINTS.ADMIN.UPDATE_ADMIN + `/${body?.id}`,
          method: "PUT",
          body: data,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteAdmin: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_ADMIN + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useAddAdminMutation,
  useDeleteAdminMutation,
  useGetAdminsListMutation,
  useGetAdminByIdMutation,
  useUpdateAdminMutation,
} = APIS;
