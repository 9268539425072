import React from "react";
import ChangePasswordComponent from "./change-password.component";
import { IResponse } from "src/services/types";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { useChangePasswordMutation } from "src/services/api-service/settings";
import useLoadingError from "src/hooks/useLoadingError";
import { useFormik } from "formik";
import { changePasswordSchema } from "src/validations/change-password-schema";

const ChangePasswordContainer = () => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [changePasswordMutation] = useChangePasswordMutation<any>();

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(data?: any) {
    formik.setTouched({
      current_password: true,
      new_password: true,
      confirm_password: true,
    });
    console.log(formik);
    if (formik?.isValid) {
      startLoading();
      try {
        await changePasswordMutation(formik?.values)
          .then((data: any) => {
            const response: any = data;
            response?.data && formik.resetForm();
            response?.data &&
              showToast(TOASTR_TYPES.SUCCESS, response?.data?.message);
          })
          .catch((err: any) => {
            // console.log(err)
          });
      } catch (err) {
        // showToast(TOASTR_TYPES.ERROR, err?.message);
      } finally {
        formik.resetForm();
      }
      stopLoading();
    }
  }

  return (
    <ChangePasswordComponent
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      handleSubmit={handleSubmit}
    />
  );
};

export default ChangePasswordContainer;
