import React from 'react'

const IcOffcanvasBackArrow = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
    stroke="#E0DBDB"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>


  )
}

export default IcOffcanvasBackArrow