import React, { useEffect, useState } from "react";
import Select from "antd/es/select";
import CustomSelect from "src/components/atoms/custom-select";
import { useQuotationsContext } from "src/context/quotations-context";
import TextInput from "src/components/atoms/text-input";
import { extractNumbers } from "src/utils";

// const defaultFields = [{ carriage: "", type: "", amount: "", fill: false }];
export default function PricingDetails() {
  const { formikQuotation } = useQuotationsContext();

  const [arr, setArr] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);
  const [arr1, setArr1] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);
  const [arr2, setArr2] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);

  const [charges, setCharges] = useState<any>({
    "pre-carriage": [],
    fob: [],
    "ocean-freight": [],
  });

  const handleChange = (
    value: any,
    key: string,
    i: number,
    arrName?: string,
    increment?: boolean,
  ) => {
    let newArr: any;
    switch (arrName) {
      case "arr1": {
        newArr = [...arr1];
        newArr[i][key] = value;
        !increment && arr1.slice(i, 1);
        increment && setArr1(newArr);
        break;
      }
      case "arr2": {
        newArr = [...arr2];
        newArr[i][key] = value;
        !increment && arr2.slice(i, 1);
        increment && setArr2(newArr);
        setArr2(newArr);
        break;
      }
      default: {
        newArr = [...arr];
        newArr[i][key] = value;
        !increment && arr.slice(i, 1);
        increment && setArr(newArr);
        setArr(newArr);
        break;
      }
    }
    formikQuotation.setFieldValue("charges", charges);
  };

  const crudCharges = (
    index: number,
    type: string,
    arrName: string,
    increment: boolean,
  ) => {
    if (!increment && charges[type]?.length === index + 1) {
      charges[type].splice(index, 1);
    }
    if (
      increment &&
      arrName === "arr" &&
      arr[index].carriage &&
      arr[index].amount
    ) {
      charges[type][index] = {
        [arr[index].carriage]: arr[index].amount,
      };
    }
    if (
      increment &&
      arrName === "arr1" &&
      arr1[index].carriage &&
      arr1[index].amount
    ) {
      charges[type][index] = {
        [arr1[index].carriage]: arr1[index].amount,
      };
    }
    if (
      increment &&
      arrName === "arr2" &&
      arr2[index].carriage &&
      arr2[index].amount
    ) {
      charges[type][index] = {
        [arr2[index].carriage]: arr2[index].amount,
      };
    }
  };

  const handleInput = (index: number, arrName: string) => {
    let newArray: any[] = [];
    if (arrName === "arr") {
      newArray = [...arr];
      newArray.splice(index, 1);
      setArr(newArray);
    }
    if (arrName === "arr1") {
      newArray = [...arr1];
      newArray.splice(index, 1);
      setArr1(newArray);
    }
    if (arrName === "arr2") {
      newArray = [...arr2];
      newArray.splice(index, 1);
      setArr2(newArray);
    }
  };

  useEffect(() => {
    formikQuotation.setFieldValue("charges", charges);
  }, [charges]);

  return (
    <div className="mb-24 px-24 pb-16">
      <h1 className="mb-32 text-24 font-semibold">Pricing Details</h1>
      <div className=" grid  gap-2 ">
        {arr.map((item, index) => {
          return (
            <div key={index} className=" flex gap-5 ">
              <div className="w-full">
                <p className="mb-4 font-medium text-gray-600">Pre-Carrige</p>
                <Select
                  placeholder="Select here..."
                  defaultValue="Type here..."
                  style={{ width: "100%" }}
                  onChange={(value: any) =>
                    handleChange(value, "carriage", index)
                  }
                  value={item?.carriage || null}
                  disabled={item.fill}
                  options={[
                    { label: "Bill of Lading", value: "Bill of Lading" },
                    { label: "Trucking Rate", value: "Trucking Rate" },
                    {
                      label: "Customs Clearance",
                      value: "Customs Clearance",
                    },
                  ]}
                />
              </div>

              <div className="w-1/2">
                <TextInput
                  name="amount"
                  type="text"
                  disabled={item.fill}
                  value={item.amount}
                  onChange={(e: any) =>
                    handleChange(
                      extractNumbers(e.target.value),
                      "amount",
                      index,
                      "arr",
                    )
                  }
                  required={true}
                  placeholder="Amount"
                  label="Amount"
                />
              </div>
              <div className=" mt-20 flex w-full items-center space-x-6">
                {!!item.carriage && !!item.amount && !item.fill && (
                  <>
                    <button
                      onClick={() => {
                        handleChange(true, "fill", index, "arr");
                        crudCharges(index, "pre-carriage", "arr", true);
                      }}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/Tick.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr", false);
                        handleChange("", "amount", index, "arr", false);
                        crudCharges(index, "pre-carriage", "arr", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Cross-red.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {item.fill && (
                  <>
                    <button
                      onClick={() => handleChange(false, "fill", index, "arr")}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/PencilSimpleLine.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr", false);
                        handleChange("", "amount", index, "arr", false);
                        handleChange(false, "fill", index, "arr");
                        crudCharges(index, "pre-carriage", "arr", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Trash-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        setArr([
                          ...arr,
                          { carriage: "", type: "", amount: "", fill: false },
                        ]);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/plus-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {index > 0 && (
                  <button
                    onClick={() => {
                      handleInput(index, "arr");
                      crudCharges(index, "pre-carriage", "arr", false);
                    }}
                  >
                    <img
                      className=""
                      src={"/assets/icons/remove-icon.svg"}
                      alt="maersk"
                    ></img>
                  </button>
                )}
              </div>
            </div>
          );
        })}
        {arr1.map((item, index) => {
          return (
            <div key={index} className=" flex gap-5 ">
              <div className="w-full">
                <p className="mb-4 font-medium text-gray-600">FOB-Carrige</p>
                <Select
                  placeholder="Select here..."
                  defaultValue="Type here..."
                  style={{ width: "100%" }}
                  onChange={(value: any) =>
                    handleChange(value, "carriage", index, "arr1")
                  }
                  value={item.carriage || null}
                  disabled={item.fill}
                  options={[
                    { label: "Bill of Lading", value: "Bill of Lading" },
                    { label: "Trucking Rate", value: "Trucking Rate" },
                    {
                      label: "Customs Clearance",
                      value: "Customs Clearance",
                    },
                  ]}
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  name="amount"
                  type="text"
                  disabled={item.fill}
                  value={item.amount}
                  onChange={(e: any) =>
                    handleChange(
                      extractNumbers(e.target.value),
                      "amount",
                      index,
                      "arr1",
                    )
                  }
                  required={true}
                  placeholder="Amount"
                  label="Amount"
                />
              </div>
              <div className=" mt-20 flex w-full items-center space-x-6">
                {!!item.carriage && !!item.amount && !item.fill && (
                  <>
                    <button
                      onClick={() => {
                        handleChange(true, "fill", index, "arr1");
                        crudCharges(index, "fob", "arr1", true);
                      }}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/Tick.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr1", false);
                        handleChange("", "amount", index, "arr1", false);
                        crudCharges(index, "fob", "arr1", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Cross-red.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {item.fill && (
                  <>
                    <button
                      onClick={() => handleChange(false, "fill", index, "arr1")}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/PencilSimpleLine.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr1", false);
                        handleChange("", "amount", index, "arr1", false);
                        handleChange(false, "fill", index, "arr1");
                        crudCharges(index, "fob", "arr1", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Trash-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        setArr1([
                          ...arr1,
                          { carriage: "", type: "", amount: "", fill: false },
                        ]);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/plus-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {index > 0 && (
                  <button
                    onClick={() => {
                      handleInput(index, "arr1");
                      crudCharges(index, "fob", "arr1", false);
                    }}
                  >
                    <img
                      className=""
                      src={"/assets/icons/remove-icon.svg"}
                      alt="maersk"
                    ></img>
                  </button>
                )}
              </div>
            </div>
          );
        })}
        {arr2.map((item, index) => {
          return (
            <div key={index} className=" flex gap-5 ">
              <div className="w-full">
                <p className="mb-4 font-medium text-gray-600">Ocean Freight</p>
                <Select
                  placeholder="Select here..."
                  defaultValue="Type here..."
                  style={{ width: "100%" }}
                  onChange={(value: any) =>
                    handleChange(value, "carriage", index, "arr2")
                  }
                  value={item.carriage || null}
                  disabled={item.fill}
                  options={[
                    { label: "Bill of Lading", value: "Bill of Lading" },
                    { label: "Trucking Rate", value: "Trucking Rate" },
                    {
                      label: "Customs Clearance",
                      value: "Customs Clearance",
                    },
                  ]}
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  name="amount"
                  type="text"
                  disabled={item.fill}
                  value={item.amount}
                  onChange={(e: any) =>
                    handleChange(
                      extractNumbers(e.target.value),
                      "amount",
                      index,
                      "arr2",
                    )
                  }
                  required={true}
                  placeholder="Amount"
                  label="Amount"
                />
              </div>
              <div className=" mt-20 flex w-full items-center space-x-6">
                {!!item.carriage && !!item.amount && !item.fill && (
                  <>
                    <button
                      onClick={() => {
                        handleChange(true, "fill", index, "arr2");
                        crudCharges(index, "ocean-freight", "arr2", true);
                      }}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/Tick.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr2", false);
                        handleChange("", "amount", index, "arr2", false);
                        crudCharges(index, "ocean-freight", "arr2", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Cross-red.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {item.fill && (
                  <>
                    <button
                      onClick={() => {
                        handleChange(false, "fill", index, "arr2");
                        crudCharges(index, "ocean-freight", "arr2", true);
                      }}
                    >
                      <img
                        className="flex items-center"
                        src={"/assets/icons/PencilSimpleLine.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        handleChange("", "carriage", index, "arr2", false);
                        handleChange("", "amount", index, "arr2", false);
                        handleChange(false, "fill", index, "arr2");
                        crudCharges(index, "ocean-freight", "arr2", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/Trash-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        setArr2([
                          ...arr2,
                          { carriage: "", type: "", amount: "", fill: false },
                        ]);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/plus-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  </>
                )}

                {index > 0 && (
                  <button
                    onClick={() => {
                      handleInput(index, "arr2");
                      crudCharges(index, "ocean-freight", "arr2", false);
                    }}
                  >
                    <img
                      className=""
                      src={"/assets/icons/remove-icon.svg"}
                      alt="maersk"
                    ></img>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="grid grid-cols-2 lg:grid-cols-6">
        <div className="mb-6 mt-20 font-medium text-gray-600">
          <CustomSelect
            name="test"
            options={[{ label: "300", value: "300" }]}
            required={true}
            placeholder="$ 300"
            label="Total Billing Price"
          />
        </div>
      </div> */}
    </div>
  );
}
