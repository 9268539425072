import React, { useEffect } from "react";
import CargoShipperComponent from "./cargo-shipper.component";
import { useQuotationsContext } from "src/context/quotations-context";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { alertsSelector } from "src/store/features/alerts/selectors";

const CargoShipperContainer = ({toggle}:any) => {
  const { nextLink,setNextLink } = useQuotationsContext();
  const navigate = useNavigate();
  const alerts = useAppSelector(alertsSelector);

  useEffect(() => {
    toggle(false);
    setNextLink("");
    if(nextLink) {
      nextLink!=='same'&&navigate(nextLink);
    }
  },[nextLink])

  useEffect(() => {
    if(!alerts?.loading) {
      toggle(false);
      if(nextLink) {
        nextLink!=='same'&&navigate(nextLink);
      } else {
        toggle(false);
      }
    }
  }, [alerts?.loading])

  return <CargoShipperComponent />;
};

export default CargoShipperContainer;
