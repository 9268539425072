import React, { useEffect } from "react";
import { Icon } from "src/components/atoms/icons";

const DataTableSearchComponent = ({
  query,
  handleSearch,
  clearSearch,
  placeholder,
}: any) => {
  useEffect(() => {
    clearSearch && clearSearch();
  }, [location.pathname]);
  return (
    <div className="relative order-3 w-full xl:order-2 xl:w-[260px]">
      <input
        className="w-full text-ellipsis rounded-md border border-border bg-white py-9 pl-44 pr-42 text-14 font-normal leading-20 text-gray-800 placeholder:text-gray-500-base focus:border-primary-300 focus:shadow-input-field-focus-shadow focus:outline-none"
        placeholder={placeholder || "Search"}
        value={query}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
      <div className="absolute right-[14px] top-[10px] flex items-center">
        <button
          onClick={() => {
            clearSearch && clearSearch();
          }}
        >
          <i hidden={query ? false : true}>
            <Icon.McsIcMenu />
          </i>
        </button>
      </div>
      <div className="absolute left-[14px] top-[10px]">
        <i>
          <Icon.McsIcSearch />
        </i>
      </div>
    </div>
  );
};

export default DataTableSearchComponent;
