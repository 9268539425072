import React from "react";

const McsIcBuilding = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 17.0938H18.75"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 17.0938V3.34375C11.25 3.17799 11.1842 3.01902 11.0669 2.90181C10.9497 2.7846 10.7908 2.71875 10.625 2.71875H3.125C2.95924 2.71875 2.80027 2.7846 2.68306 2.90181C2.56585 3.01902 2.5 3.17799 2.5 3.34375V17.0938"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.0938V8.34375C17.5 8.17799 17.4342 8.01902 17.3169 7.90181C17.1997 7.7846 17.0408 7.71875 16.875 7.71875H11.25"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5.84375H7.5"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 10.8438H8.75"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13.9688H7.5"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 13.9688H15"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 10.8438H15"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcBuilding;
