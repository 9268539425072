import React from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./datatable";

const QuotationHistoryComponent = () => {
  return (
    <>
      <ContentWrapper>
        <Datatable />
      </ContentWrapper>
    </>
  );
};

export default QuotationHistoryComponent;
