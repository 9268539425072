import { useState } from 'react';

const useCustomDrawer = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openDrawer = () => {
    setIsOpen(true);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    toggle,
    openDrawer,
    closeDrawer,
  };
};

export default useCustomDrawer;
