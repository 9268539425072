import React from "react";

import { IErrorLabel } from "./error-label.types";
const ErrorLabel = ({ message, className = "" }: IErrorLabel) => {
  return (
    <>
      {message ? (
        <div
          className={`mt-4 text-helper-text text-14 font-medium leading-20 ${className}`}
        >
          {message}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ErrorLabel;
