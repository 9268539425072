import React, { useState } from 'react';
import { Input, Select } from "antd";
import { IInputGruop } from "./input-group.types";
import Label from '../label';
import ErrorLabel from '../error-label';
import countryCode from 'src/mockup/country-code.json';

interface CountryCode {
  code: string;
  dialCode: string;
}

const InputGroup = ({
    id,
    name,
    label,
    type,
    size = "large",
    classes,
    placeholder,
    disabled,
    readonly,
    value,
    required = false,
    errorMessage = "",
    allowNumbersOnly,
    showError,
    onChange,
    onFocus,
    onBlur,
    autoFocus,
    prefix,
    suffix,
  }: IInputGruop) => {
    const { Option } = Select;
    const [selectedPrefix, setSelectedPrefix] = useState(() => {
      const defaultCountry = countryCode.find((country: CountryCode) => country.dialCode === "+1 (555)"); // Set the initial value to the dial code you want
      return defaultCountry ? defaultCountry.dialCode : "+1 (555)"; // Default to "+1" if the dial code is not found
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;
  
      if (allowNumbersOnly) {
        inputValue = inputValue.replace(/\D/g, "");
        inputValue = inputValue.replace(/^0+/, "");
      }
  
      e.target.value = inputValue;
      onChange && onChange(e);
    };

    const commonInputProps: any = {
        name,
        value,
        type,
        onBlur,
        onChange: handleInputChange,
        prefix,
        suffix,
        size,
        disabled,
        classNames: {
          input:
            "placeholder:text-16 text-16 placeholder:text-gray-500-base-light text-gray-800 font-normal leading-24 border border-gray-300 rounded-lg shadow-input-field-shadow" +
            classes?.inputClassName,
        },
        status: showError && errorMessage ? "error" : "",
    };

    const handleSelectChange = (value: string) => {
      const selectedCountry = countryCode.find((country: CountryCode) => country.code === value);
      setSelectedPrefix(selectedCountry?.dialCode || "+1 (555");

      const updatedValue = `${selectedCountry?.dialCode || "+1 (555)"}`;
      const syntheticEvent = {
        target: {
          name,
          value: updatedValue,
        },
      };
    
      onChange && onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
    
    };

    const defaultPrefix = countryCode.find((country: CountryCode) => country.dialCode === selectedPrefix)?.code || "US";
    const sortedCountryCode = countryCode.slice().sort((a, b) => a.code.localeCompare(b.code));
    const inputValue = selectedPrefix ? `${value}` : value;

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const cursorPosition = e.currentTarget.selectionStart || 0;
      const prefixLength = selectedPrefix.length;
      if (cursorPosition <= prefixLength && e.key === 'Backspace') {
        e.preventDefault(); // Prevent removing the prefix
      }
    };

    const handleFocus = () => {
      const syntheticEvent = {
        target: {
          name,
          value: selectedPrefix,
        },
      };
    
      onChange && onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
    }

    const selectBefore = (
        <Select value={defaultPrefix} onChange={handleSelectChange}>
          {sortedCountryCode?.map((country: CountryCode) => (
            <Option key={country.code} value={country.code}>{`${country.code}`}</Option>
          ))}
        </Select>
      );
    
    return (
        <div className={classes?.containerClassName}>
        {label && <Label id={id || name} name={label} required={required} />}
        <Input addonBefore={selectBefore}
        {...commonInputProps}
        value={inputValue}
        placeholder={`${selectedPrefix} ${placeholder}`}
        onFocus={handleFocus}
        // autoFocus={autoFocus}
        onWheel={(e: any) => e.preventDefault()}
        onKeyDown={handleKeyDown}
         />
        {showError && ( 
          <ErrorLabel
            message={errorMessage}
            className={classes?.errorLabelClass}
          />
        )}
      </div>
    );
  };

export default InputGroup;
