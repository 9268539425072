"use client";
import React from "react";
import FormHeader from "src/pages/auth/elements/form-header";
import CustomButton from "src/components/atoms/button";
import CustomInputOTP from "src/components/molecules/custom-otp-input";
import { IVerifyOtpForm } from "./verify-otp-form.types";
import ErrorLabel from "src/components/atoms/error-label";
import ResendOtpButton from "src/components/molecules/resend-otp-button";

const VerifyOtpForm = ({
  error,
  loading,
  handleSubmit,
  setOtp,
  resetError,
}: IVerifyOtpForm) => {
  return (
    <div>
      <FormHeader
        title="Reset password"
        description="Enter the 6-digit verification code that was sent to your email to change your password."
      />
      <form onSubmit={handleSubmit}>
        <div className="mb-32">
          <CustomInputOTP setOtp={setOtp} />
          {Boolean(error) && <ErrorLabel message={error} />}
          <ResendOtpButton
            keyName="otpRequestTimestamp"
            type={1}
            resetError={resetError}
          />
        </div>
        <CustomButton
          className="w-full"
          loading={loading}
          htmlType="submit"
          variant="primary"
        >
          Next
        </CustomButton>
      </form>
    </div>
  );
};

export default VerifyOtpForm;
