import React, { useEffect } from "react";
import VerifyOtpFormContainer from "./elements/verify-otp-form";
import AuthLayout from "src/components/layouts/auth-layout";
import { TITLES } from "src/types";

const VerifyOtp = () => {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.VERIFY_OTP;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <AuthLayout>
      <VerifyOtpFormContainer />
    </AuthLayout>
  );
};

export default VerifyOtp;
