import { useFormik } from "formik";

export interface IAdminInitialValues {
  name: string;
  email: string;
  role: any;
  password: string;
}

export interface IAdminCrudForm {
  formik: ReturnType<typeof useFormik<IAdminInitialValues>>;
  error: string | null | undefined;
  resetError: () => void;
  loading: boolean;
  getId: (id: string) => void;
  closeModal?: boolean;
  setCloseModal: (value: boolean) => void;
  reFetchData?: boolean;
}

export enum ROLES {
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  USER = "user",
}

export const ROLES_OPTIONS = [
  {
    value: ROLES.ADMIN,
    label: "Admin",
    allowed: [ROLES.SUPER_ADMIN],
  },
  {
    value: ROLES.USER,
    label: "User",
    allowed: [ROLES.SUPER_ADMIN,ROLES.ADMIN],
  },
]
