import React from "react";

const AppLoader = () => {
  return (
    <div className="theme-custom-loader flex min-h-[500px] items-center justify-center">
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center gap-3">
          <div className="relative h-12 w-48">
            <img
              src="/assets/images/loader.png"
              alt="Airplane Logo"
              className="h-auto w-full"
            />
            <div className="border-3 absolute left-1/2 top-1/2 h-16 w-16 animate-spin rounded-full border border-l-0 border-t-0 border-solid border-blue-500 border-gray-800"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoader;
