import React from 'react'

const McsIcUploder = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={112}
  height={112}
  viewBox="0 0 112 112"
  fill="none"
>
  <path
    d="M59.5 94.5V73.5H73.5L56 52.5L38.5 73.5H52.5V94.5H35V94.325C34.412 94.36 33.852 94.5 33.25 94.5C26.2881 94.5 19.6113 91.7344 14.6884 86.8116C9.76562 81.8887 7 75.2119 7 68.25C7 54.782 17.185 43.806 30.254 42.301C31.3998 36.3111 34.5971 30.9076 39.2959 27.02C43.9948 23.1324 49.9014 21.0037 56 21C62.0994 21.0034 68.007 23.1319 72.707 27.0194C77.407 30.9069 80.6057 36.3105 81.753 42.301C94.822 43.806 104.993 54.782 104.993 68.25C104.993 75.2119 102.227 81.8887 97.3046 86.8116C92.3817 91.7344 85.7049 94.5 78.743 94.5C78.155 94.5 77.588 94.36 76.993 94.325V94.5H59.5Z"
    fill="#1D2939"
  />
</svg>

  )
}

export default McsIcUploder