import Label from "../label";
import React, { useState, useEffect } from "react";
import ErrorLabel from "../error-label";
import { ICountryPicker, Country } from "./types";
import { AutoComplete, Input } from "antd";
import { Icon } from "../icons";

const CountryPickerComponent = ({
  id,
  name,
  label,
  size = "large",
  classes,
  placeholder,
  disabled,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  onFocus,
  onBlur,
  onChange,
  autoFocus,
  suffix,
  countries,
  value,
  allowCustomValue = true,
}: ICountryPicker) => {
  const [searchvalue, setSearchValue] = useState(value || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setSearchValue(value || "");
  }, [value]);

  const commonInputProps: any = {
    name,
    searchvalue,
    onBlur,
    onChange: handleInputChange,
    placeholder,
    prefix: <Icon.McsIcMapPin />,
    suffix,
    size,
    value,
    disabled,
    className: classes?.inputClassName,
    status: showError && errorMessage ? "error" : "",
  };

  const [filteredOptions, setFilteredOptions] = useState(countries);

  useEffect(() => {
    setFilteredOptions(countries);
  }, [countries]);

  const renderItem = (e: any) => ({
    value: e.name || e.code,
    // value: e.code,
    label: (
      <div className="flex flex-col gap-2">
        <span className="!text-12 !font-medium !leading-18 !text-gray-700">
          {e.name}
        </span>
        <span>{`${e.name} (${e.code})`}</span>
      </div>
    ),
  });

  const style = !allowCustomValue
    ? { caretColor: "transparent", cursor: "pointer" }
    : {};

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <AutoComplete
        style={style}
        popupClassName="!bg-primary-50"
        options={filteredOptions?.map((e: any) => renderItem(e))}
        size="large"
        className="w-full"
        value={searchvalue}
        showSearch={true}
        onSearch={(value: string) => {
          // Filter options based on the search value
          const filtered: any[] = countries.filter(
            (option) =>
              option.name.toLowerCase().includes(value.toLowerCase()) ||
              option.code.toLowerCase().includes(value.toLowerCase()),
          );
          setFilteredOptions(filtered);
        }}
        onSelect={(value: any) => {
          onSelect(value);
          setSearchValue(value);
        }}
        onInputKeyDown={(e: any) => {
          if (!allowCustomValue) e.preventDefault();
        }}
      >
        <Input
          {...commonInputProps}
          onFocus={onFocus}
          autoFocus={autoFocus}
          style={
            !allowCustomValue
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
        />
      </AutoComplete>
      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default CountryPickerComponent;
