import React, { useState } from "react";
import RadioInputComponent from "src/components/atoms/radio-input";
import TextInput from "src/components/atoms/text-input";
import useModal from "src/hooks/useModal";
import CustomCard from "src/components/molecules/custom-card";
import CustomSelect from "src/components/atoms/custom-select";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";

const PersonalInformationComponent = ({
  formik,
  error,
  resetError,
  loading,
  handleSubmit,
  toggle,
}: any) => {
  // Radio button
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const placeholders: any = {
    first_name: "Enter First Name",
    last_name: "Enter Last Name",
    email: "Enter Email Address",
    phone: "ex. +31 123-4567-89",
    display_name: "Enter Display Name",
  };

  const handleOptionChange = (value: string) => {
    formik.setFieldValue("gender", value);
    // setSelectedOption(value);
  };

  // Radio button options
  const options = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];

  return (
    <CustomCard title="Personal Information">
      <form
        className="grid gap-x-5 gap-y-5 xl:grid-cols-12"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      >
        {[
          "first_name",
          "last_name",
          "role",
          "email",
          // "phone",
          "display_name",
        ].map((fieldName) => (
          <div
            key={fieldName}
            className={`col-span-12 grid ${
              fieldName === "phone" ? "lg:col-span-12" : "lg:col-span-6"
            }`}
          >
            {fieldName === "role" ? (
              <CustomSelect
                label="Role"
                name={fieldName}
                placeholder="Select an option"
                options={options}
                value={formik?.values?.[fieldName]}
                showError={Boolean(formik.touched[fieldName])}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors[fieldName]}
                allowClear
                required={true}
                disabled={true}
                suffixIcon={<Icon.McsIcDownArrow />}
              />
            ) : (
              <TextInput
                name={fieldName}
                type={
                  fieldName === "email"
                    ? "email"
                    : fieldName === "role"
                      ? "text"
                      : fieldName === "phone"
                        ? "number"
                        : "text"
                }
                label={
                  fieldName === "first_name"
                    ? "First Name"
                    : fieldName === "last_name"
                      ? "Last Name"
                      : fieldName === "role"
                        ? "Role"
                        : fieldName === "email"
                          ? "Email Address"
                          : fieldName === "phone"
                            ? "Phone Number"
                            : "Display Name"
                }
                placeholder={placeholders[fieldName]}
                value={formik?.values?.[fieldName]}
                showError={Boolean(formik.touched[fieldName])}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors[fieldName]}
                classes={{
                  containerClassName: "custom-input",
                  inputClassName: "",
                }}
                required={
                  fieldName !== "last_name" &&
                  fieldName !== "phone" &&
                  fieldName !== "display_name"
                }
                disabled={!toggle}
              />
            )}
          </div>
        ))}

        {/* <div className="col-span-12 lg:col-span-6">
          <label className="mb-4 block text-14 font-medium leading-20 text-gray-700">
            Gender
          </label>
          <div className="gap-2 sm:flex">
            <div
              className={
                (toggle ? "" : "disable-input") +
                " customradio-input gap-[10]  sm:flex-1 sm:gap-0"
              }
            >
              <RadioInputComponent
                label="Male"
                selectedOption={formik.values.gender}
                onChange={handleOptionChange}
                disabled={!toggle}
              />
            </div>
            <div
              className={
                (toggle ? "" : "disable-input") + " customradio-input sm:flex-1"
              }
            >
              <RadioInputComponent
                label="Female"
                selectedOption={formik.values.gender}
                onChange={handleOptionChange}
                disabled={!toggle}
              />
            </div>
          </div>
        </div> */}
        <div className="col-span-12 lg:col-span-6"></div>

        <div className="mt-8" hidden={!toggle}>
          <CustomButton block={false} className="px-16" onClick={handleSubmit}>
            Save
          </CustomButton>
        </div>
      </form>
    </CustomCard>
  );
};

export default PersonalInformationComponent;
