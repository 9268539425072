import { Select } from "antd";
import React from "react";
import { Icon } from "src/components/atoms/icons";
const { Option } = Select;

interface Props {
  columnOption: string[];
  sendFilteredColumns: (data: any) => void;
  values: any;
  setFiltersData: any;
  isShow: boolean;
}
export default function Filters({
  columnOption,
  sendFilteredColumns,
  values,
  setFiltersData,
  isShow,
}: Props) {
  const handleStatusChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      status: value || null,
    }));
  };

  return (
    <>
      {/* <div
        className={
          !isShow
            ? " mt-5 flex w-full items-start justify-end space-x-4 pb-5"
            : "hidden"
        }
      >
        <div className="w-full rounded-lg  ">
          <Select
            placeholder="Status"
            style={{ width: "100%", minWidth: "110px" }}
            className="select-status  w-full border-2 bg-white  "
            onChange={handleStatusChange}
            value={values.status}
            options={[
              {
                value: "booked",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl  bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
                      Booked
                    </div>
                  </div>
                ),
              },
              {
                value: "pending",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-purple-50 px-8 py-2 text-12 font-medium leading-18 text-purple-700">
                      Pending
                    </div>
                  </div>
                ),
              },
              {
                value: "failed",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
                      Failed
                    </div>
                  </div>
                ),
              },
              {
                value: "complete",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-sky-50 px-8 py-2 text-12 font-medium leading-18 text-blue-800">
                      Complete
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div> */}
      <div
        className={
          isShow
            ? "mt-5 flex w-full items-start justify-end space-x-2 pb-5"
            : "hidden"
        }
      >
        <div className="">
          <div className="w-auto md:w-full ">
            <Select
              mode="multiple"
              className="multiSelect select-status rounded-md border-2 bg-white py-1"
              placeholder="Show"
              defaultValue={columnOption}
              style={{ width: "100%", minWidth: "135px" }}
              maxTagCount="responsive"
              // onChange={(value) => handleChange(value, "reference")}
              onChange={sendFilteredColumns}
              suffixIcon={<Icon.McsIcEye />}
              // value={field.reference}
            >
              {columnOption.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
}
