import React, { useEffect } from "react";
import { TITLES } from "src/types";
import QuotationHistoryDatatable from "./quotation-history-datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";

export default function Bookings() {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.QUOTATION_HISTORY;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <ContentWrapper>
      <QuotationHistoryDatatable />
    </ContentWrapper>
  );
}
