import { ICommonState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  otherUser: {},
  dashboard: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOtherUser(state, action) {
      state.otherUser = action.payload;
    },
    setDashboardData(state, action) {
      state.dashboard = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { setOtherUser, setDashboardData } = userSlice.actions;
