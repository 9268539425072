import React, { useState, useEffect, useRef } from "react";
import CustomDropdown from "src/components/atoms/custom-dropdown";
import Menu from "../menu";
import StatusSelect from "src/components/atoms/status-select";
import useFormattedDate from "src/hooks/useFormattedDate";
import { formatDateQuotation } from "src/components/molecules/crop-modal-easy/utils";

export const columns = (handleOpenDeleteModal: any) => {
  return [
    {
      headerName: "Reference No.",
      field: "reference_no",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Creation date",
      field: "created_at",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      valueGetter: (params: any) =>
        formatDateQuotation(
          params?.data?.main_quotation?.created_at || "",
        ).split(",")[0] +
        ", " +
        formatDateQuotation(
          params?.data?.main_quotation?.created_at || "",
        ).split(",")[1],
    },
    {
      headerName: "Origin",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      valueGetter: (params: any) =>
        params?.data?.main_quotation?.source_country,
    },
    {
      headerName: "Destination",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      valueGetter: (params: any) =>
        params.data?.main_quotation?.destination_country,
    },
    {
      headerName: "MOT",
      field: "mode_of_transport",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      valueGetter: (params: any) => params.data.mode_of_transport.toUpperCase(),
    },
    {
      headerName: "Service Type",
      field: "service_type",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      valueGetter: (params: any) => params.data.service_type.toUpperCase(),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      cellStyle: { paddingLeft: 5, paddingRight: 5 },
      minWidth: 150,
      resizable: true,
      cellRenderer: (params: any) => {
        return <StatusSelect value={params.data.status} id={params.data.id} />;
      },
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 60,
      cellRenderer: (params: any) => (
        <Menu params={params} handleOpenDeleteModal={handleOpenDeleteModal} />
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
