import React from 'react'

const McsIcCreateNewPlus = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 3L12 21M21 12L3 12"
    stroke="#1D2939"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default McsIcCreateNewPlus