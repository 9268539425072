import React from 'react'

import SideBarHeaderCompoent from './sidebar-header-component'

const SiderBarHeaderContainer = () => {
  return (
    <SideBarHeaderCompoent />
  )
}

export default SiderBarHeaderContainer