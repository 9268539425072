import React from "react";

const McsIcUserDotsThreeVertical = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 10.9375C10.5178 10.9375 10.9375 10.5178 10.9375 10C10.9375 9.48223 10.5178 9.0625 10 9.0625C9.48223 9.0625 9.0625 9.48223 9.0625 10C9.0625 10.5178 9.48223 10.9375 10 10.9375Z"
        fill="#667085"
      />
      <path
        d="M10 5.9375C10.5178 5.9375 10.9375 5.51777 10.9375 5C10.9375 4.48223 10.5178 4.0625 10 4.0625C9.48223 4.0625 9.0625 4.48223 9.0625 5C9.0625 5.51777 9.48223 5.9375 10 5.9375Z"
        fill="#667085"
      />
      <path
        d="M10 15.9375C10.5178 15.9375 10.9375 15.5178 10.9375 15C10.9375 14.4822 10.5178 14.0625 10 14.0625C9.48223 14.0625 9.0625 14.4822 9.0625 15C9.0625 15.5178 9.48223 15.9375 10 15.9375Z"
        fill="#667085"
      />
    </svg>
  );
};

export default McsIcUserDotsThreeVertical;
