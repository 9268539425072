import React, { useEffect } from "react";
import { useFormik } from "formik";
import useLoadingError from "src/hooks/useLoadingError";

import ResetPasswordForm from "./reset-password-form.component";
import { resetPasswordSchema } from "src/validations/reset-password-schema";
import useRecaptcha from "src/hooks/useRecaptcha";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "src/services/api-service/auth";

const ResetPasswordFormContainer = () => {
  const [resetPassword] = useResetPasswordMutation();
  const { loading, error, setErrorMsg, reset, startLoading, stopLoading } =
    useLoadingError();
  const [url] = useSearchParams();
  const token = url?.get("token");
  const { setRecaptcha, isVerified, recaptcha } = useRecaptcha();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(`/admin/login`);
    }
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (isVerified) {
        startLoading();
        resetPassword({
          password: values.password,
          // confirm_password: values.confirmPassword,
          token,
        })
          .unwrap()
          .then(() => {
            navigate(`/admin/reset-password-success`);
          })
          .catch((err) => {
            setErrorMsg(
              err?.data?.message || "The token does not match or is invalid",
            );
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
  });

  return (
    <ResetPasswordForm
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      isVerified={isVerified}
      recaptcha={recaptcha}
      setRecaptcha={setRecaptcha}
    />
  );
};

export default ResetPasswordFormContainer;
