import React from "react";
import QuotationResultsFiltersContainer from "./quotation-results-filters.container";

const QuotationsResultFilters = ({ getDetails, isCustomer }: any) => {
  return (
    <QuotationResultsFiltersContainer
      getDetails={getDetails}
      isCustomer={isCustomer}
    />
  );
};

export default QuotationsResultFilters;
