import React from "react";

const MscIcChevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M7.41 8.79508L6 10.2051L12 16.2051L18 10.2051L16.59 8.79508L12 13.3751L7.41 8.79508Z"
        fill="#0D5B92"
      />
    </svg>
  );
};

export default MscIcChevronDown;
