import React from "react";

const McsIcUploadCloud = () => {
  return (
    <svg
      width="98"
      height="74"
      viewBox="0 0 98 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 73.5V52.5H66.5L49 31.5L31.5 52.5H45.5V73.5H28V73.325C27.412 73.36 26.852 73.5 26.25 73.5C19.2881 73.5 12.6113 70.7344 7.68845 65.8116C2.76562 60.8887 0 54.2119 0 47.25C0 33.782 10.185 22.806 23.254 21.301C24.3998 15.3111 27.5971 9.90759 32.2959 6.02C36.9948 2.13241 42.9014 0.0036961 49 0C55.0994 0.00341364 61.007 2.13187 65.707 6.01937C70.407 9.90687 73.6057 15.3105 74.753 21.301C87.822 22.806 97.993 33.782 97.993 47.25C97.993 54.2119 95.2274 60.8887 90.3046 65.8116C85.3817 70.7344 78.7049 73.5 71.743 73.5C71.155 73.5 70.588 73.36 69.993 73.325V73.5H52.5Z"
        fill="#004677"
      />
    </svg>
  );
};

export default McsIcUploadCloud;
