// import React from "react";
// import QuotationVerticalStepsContainer from "./quotation-vertical-steps.container";

import QuotationVerticalSteps from "./quotation-vertical-steps.container";

// const QuotationVerticalSteps = (props:any) => {
//   console.log(props,"item???????????")
//   return <QuotationVerticalStepsContainer item={props?.item} />;
// };

// export default QuotationVerticalSteps;

export default QuotationVerticalSteps