import React, { useState } from "react";
import { Steps } from "antd";
import { Icon } from "src/components/atoms/icons";
import "./style.css";
import { findIconByValue } from "src/utils";

const QuotationHorizontalStepsComponent = ({
  value,
  mode,
}: {
  value: any;
  mode: string;
}) => {
  const icon = findIconByValue(mode);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-evenly">
          <div>{!icon ? <Icon.McsIcShip /> : icon.icon}</div>
        </div>
        <div>
          <Steps
            progressDot
            className="custom-horizontal-steps"
            items={[
              {
                title: "",
              },
              {
                title: "",
              },
            ]}
          />
        </div>
        <div className="text-center text-12 font-normal leading-18 text-gray-600">
          {value}
        </div>
      </div>
    </>
  );
};

export default QuotationHorizontalStepsComponent;
