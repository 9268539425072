import React from 'react'

const McsIcCirclePlus = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 1.875A8.125 8.125 0 1018.125 10 8.14 8.14 0 0010 1.875zm3.125 8.75h-2.5v2.5a.624.624 0 11-1.25 0v-2.5h-2.5a.625.625 0 110-1.25h2.5v-2.5a.625.625 0 011.25 0v2.5h2.5a.625.625 0 110 1.25z"
      fill="#fff"
    />
  </svg>

  )
}

export default McsIcCirclePlus