import React, { useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./elements/customers/datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { IBreadcrumbItem, TITLES } from "src/types";

const UserManagement = () => {
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Customer Management",
    },
  ];

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.USER_MANAGEMENT;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <>
      <PanelLayout breadcrumbsData={breadcrumbsData}>
        <ContentWrapper>
          <Datatable />
        </ContentWrapper>
      </PanelLayout>
    </>
  );
};

export default UserManagement;
