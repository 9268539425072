import React, { useEffect } from "react";
import AuthLayout from "src/components/layouts/auth-layout";
import ForgotPasswordForm from "../elements/forgot-password-form";
import { TITLES } from "src/types";

const ForgotPassword = () => {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.FORGOT_PASSWORD;
  useEffect(() => {
    document.title = TAB_TITLE+ ' | ' +APP_TITLE;
  }, []);
  
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPassword;
