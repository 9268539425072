import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "src/components/molecules/breadcrumbs";
import ProfileDropDown from "src/components/molecules/profile-dropdown";
import { IBreadcrumbItem } from "src/types";

interface Layout {
  breadcrumbsData: IBreadcrumbItem[];
  children: React.ReactElement;
}

export default function Layout({ breadcrumbsData, children }: Layout) {
  return (
    <div className="main-container bg-[#FBFBFB]">
      <div className={`relative left-0 top-0 block min-h-screen `}>
        {/* md:pl-[250px] */}
        <div className="sticky top-0 z-10 flex border-b border-border bg-white">
          <div className="max-h-[2.875rem] basis-1/4 px-14 py-4">
            {/* px-14 pb-8 pt-14 */}
            <Link
              to={"/admin/quotations?tab=Generate Quotations"}
              className="inline-block max-h-[2.875rem]"
            >
              <img
                src="/assets/images/mcs-logo.svg"
                alt="MCS"
                height={46}
                className="h-12"
              />
            </Link>
          </div>
          <div className="flex w-full flex-wrap items-center gap-y-4 px-24 py-13">
            <BreadCrumbs breadcrumbsData={breadcrumbsData} />
            <ProfileDropDown />
          </div>
        </div>
        <div className="mx-auto max-w-7xl py-32">{children}</div>
      </div>
    </div>
  );
}
