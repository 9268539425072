import React from "react";
import { Icon } from "src/components/atoms/icons";
import useFormattedDate from "src/hooks/useFormattedDate";

export const columns = (handleOpenDeleteModal: any) => {
  return [
    {
      headerName: "Customer Name",
      field: "name",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      rowDrag: true,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Latest Activity",
      field: "updated_at",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
      cellRenderer: ({ value, data }: any) => (
        <div>{useFormattedDate(value)}</div>
      ),
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: () => (
        <div className="flex h-full items-center justify-end">
          <button
            className="cursor-pointer p-8"
            onClick={handleOpenDeleteModal}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
