import React from "react";
import ReactPaginate from "react-paginate";
import { Icon } from "src/components/atoms/icons";

const PaginationComponent = ({ pagination, handlePageChange }: any) => {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <div className="flex items-center gap-2">
            Next
            <Icon.McsIcNext />
          </div>
        }
        onPageChange={(e) => {
          handlePageChange(e?.selected + 1);
        }}
        pageRangeDisplayed={2}
        pageCount={pagination?.totalPages || 1}
        previousLabel={
          <div className="flex items-center gap-2">
            <Icon.McsIcPrevious />
            Previous
          </div>
        }
        renderOnZeroPageCount={null}
        className="flex flex-wrap items-center rounded-lg border border-transparent lg:flex-nowrap lg:border-gray-300 lg:shadow-pagination-shadow"
        previousClassName="bg-[#FBFBFB] lg:bg-white lg:hover:bg-gray-300 transition duration-[0.4s] rounded-[7px] lg:rounded-r-none lg:border-r border-transparent lg:border-gray-200"
        previousLinkClassName="text-gray-700 lg:text-gray-800 text-14 font-semibold leading-20 flex py-10 lg:pl-16 pr-16"
        nextClassName="bg-[#FBFBFB] lg:bg-white lg:hover:bg-gray-300 transition duration-[0.4s] rounded-[7px] lg:rounded-l-none"
        nextLinkClassName="text-gray-700 lg:text-gray-800 text-14 font-semibold leading-20 flex py-10 pl-16 lg:pr-16"
        pageClassName="bg-transparent lg:bg-gray-50 hover:bg-gray-300 transition duration-[0.4s] border-transparent lg:border-r lg:border-gray-200 rounded-lg lg:rounded-none"
        pageLinkClassName="text-gray-800 lg:text-gray-800 text-14 font-semibold leading-20 flex py-10 px-17"
        breakClassName="bg-transparent lg:bg-gray-50 hover:bg-gray-300 transition duration-[0.4s] border-transparent lg:border-r lg:border-gray-200 rounded-lg lg:rounded-none"
        breakLinkClassName="text-gray-800 lg:text-gray-800 text-14 font-semibold leading-20 flex py-10 px-17"
        activeClassName="!bg-primary-100"
        activeLinkClassName="!text-gray-800"
        disabledClassName="cursor-not-allowed lg:hover:bg-white opacity-50"
        disabledLinkClassName="cursor-not-allowed opacity-70 pointer-events-none"
        forcePage={pagination?.currentPage - 1}
      />
    </div>
  );
};

export default PaginationComponent;
