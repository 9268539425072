import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";
import useFormattedDate from "src/hooks/useFormattedDate";

export const columns = (
  { handleOpenDeleteModal }: any,
  { handleOpenEditModal }: any,
) => {
  return [
    {
      headerName: "Name",
      field: "customername",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 300,
    },
    {
      headerName: "Group",
      field: "groupname",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => {
        return <span>{value || "-"}</span>;
      },
    },
    {
      headerName: "Users",
      field: "total_users",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 70,
      cellRenderer: ({ value }: any) => {
        let className = "";

        if (value === "-" || !value) {
          className += "cursor-pointer block";
        } else {
          className += "text-blue-1 underline block";
        }

        return (
          <Link to={"/admin/customer-management"} className={className}>
            {value || "-"}
          </Link>
        );
      },
    },
    {
      headerName: "Quotations",
      field: "total_quotations",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 70,
      cellRenderer: ({ value }: any) => {
        let className = "";

        if (value === "-" || !value) {
          className += "cursor-pointer block";
        } else {
          className += "text-blue-1 underline block";
        }

        return (
          <Link to={"/admin/customer-management"} className={className}>
            {value || "-"}
          </Link>
        );
      },
    },
    {
      headerName: "Bookings",
      field: "booked_quotations",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 70,
      cellRenderer: ({ value }: any) => {
        let className = "";

        if (value === 0 ?? !value) {
          className += "cursor-pointer block";
        } else {
          className += "text-blue-1 underline block";
        }

        return (
          <Link to={"/admin/customer-management"} className={className}>
            {value || "-"}
          </Link>
        );
      },
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 150,
    },
    {
      headerName: "Latest Activity",
      field: "last_update",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 100,
      resizable: true,
      cellRenderer: ({ value }: any) =>
        value ? (
          <div className="!mt-6 block leading-17">
            <span>
              {useFormattedDate(value).split(",")[0] +
                ", " +
                useFormattedDate(value).split(",")[1]}
              ,
            </span>
            <p>{useFormattedDate(value).split(",")[2]}</p>
          </div>
        ) : (
          "-"
        ),
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: ({ value, data }: any) => (
        <div className="flex h-full items-center justify-end">
          <button
            className="cursor-pointer p-8"
            onClick={() => handleOpenEditModal(data.id)}
          >
            <Icon.McsIcEdit />
          </button>

          <button
            className="cursor-pointer p-8"
            onClick={() => handleOpenDeleteModal(data.id)}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
