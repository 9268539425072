import React from "react";
import SignUpForm from "./sign-up-form.component";
import { useFormik } from "formik";
import useLoadingError from "src/hooks/useLoadingError";
import { useCustomerSignUpMutation } from "src/services/api-service/customer-auth";
import { showToast } from "src/utils";
import { useNavigate } from "react-router-dom";
import { IResponse } from "src/services/types";
import { signUpSchema } from "src/validations/sign-up-schema";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";

const SignUpFormContainer = () => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [signUp] = useCustomerSignUpMutation<any>();

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signUpSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      startLoading();
      console.log(values);
      signUp(values)
        .unwrap()
        .then((data) => {
          // persistToken(data?.data?.access_token);
          // dispatch(setUser(data?.data));
          // dispatch(setAuthToken(data?.data?.access_token));
          // getCurrentUser({});
          showToast(TOASTR_TYPES.SUCCESS, data.message);
          navigate("/login");
        })
        .catch(({ errors }) => {
          showToast(TOASTR_TYPES.ERROR, errors?.message);
        })
        .finally(() => {
          stopLoading();
        });
    },
  });

  return (
    <SignUpForm
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
    />
  );
};

export default SignUpFormContainer;
