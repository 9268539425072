import React from "react";

const McsIcAir = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 20.25H20.25"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.51245 7.5H4.12495L5.99995 9.75H8.99995L7.82808 6.23438C7.79102 6.12172 7.7812 6.00188 7.79943 5.8847C7.81767 5.76751 7.86343 5.65632 7.93297 5.56025C8.0025 5.46418 8.09383 5.38597 8.19945 5.33204C8.30508 5.2781 8.42198 5.24999 8.54058 5.25H10.125L14.25 9.75H19.5C20.2956 9.75 21.0587 10.0661 21.6213 10.6287C22.1839 11.1913 22.5 11.9544 22.5 12.75V15H5.9812C5.33689 14.999 4.71002 14.7906 4.19335 14.4057C3.67669 14.0207 3.29769 13.4796 3.11245 12.8625L1.79058 8.46563C1.75687 8.35335 1.74996 8.23473 1.77039 8.11929C1.79082 8.00386 1.83802 7.89482 1.90821 7.80093C1.9784 7.70703 2.06963 7.6309 2.17456 7.57864C2.2795 7.52638 2.39523 7.49945 2.51245 7.5V7.5Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcAir;
