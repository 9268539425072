import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPortsList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.PORTS_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      // onQueryStarted: () => onQueryStarted(store.dispatch, true),
      // transformResponse: (res: IResponse) =>
      //   transFormResponse(res, store.dispatch, true),
      // transformErrorResponse: (res: IErrorResponse) =>
      // transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const { useGetPortsListMutation } = APIS;
