import React from "react";
import QuotationResultsFiltersComponent from "./quotation-results-filters.component";

const QuotationResultsFiltersContainer = ({ getDetails, isCustomer }: any) => {
  return (
    <QuotationResultsFiltersComponent
      getDetails={getDetails}
      isCustomer={isCustomer}
    />
  );
};

export default QuotationResultsFiltersContainer;
