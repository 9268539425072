import Label from "../label";
import React, { useEffect, useState } from "react";
import ErrorLabel from "../error-label";
import { ILocationPicker, Postcode } from "./types";
import { AutoComplete, Input } from "antd";
import { Icon } from "../icons";

const LocationPickerComponent = ({
  id,
  name,
  label,
  size = "large",
  classes,
  placeholder,
  disabled,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  onFocus,
  onBlur,
  onChange,
  autoFocus,
  suffix,
  value,
  postcodes = [],
  countryCode,
}: ILocationPicker) => {
  const filterOptions = (value: string, countryCode: string | undefined) => {
    // Filter the postcodes based on the selected country code, if provided
    const filteredByCountryCode = countryCode
      ? postcodes.filter(
          (postcode) =>
            postcode.country_code.toLowerCase() === countryCode.toLowerCase(),
        )
      : postcodes;

    const filtered = filteredByCountryCode.filter(
      (option) =>
        option.zip_code.includes(value) ||
        option.area_local.toLowerCase().includes(value.toLowerCase()) ||
        option.area_english.toLowerCase().includes(value.toLowerCase()) ||
        option.country_code.toLowerCase().includes(value.toLowerCase()),
    );

    return filtered;
  };

  const [filteredOptions, setFilteredOptions] = useState(
    filterOptions(value || "", countryCode),
  );

  const [searchValue, setSearchValue] = useState(value || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChange?.(e.target.value); // Pass the countryCode to onChange
  };

  useEffect(() => {
    setSearchValue(value || "");
    const initialFilteredOptions = filterOptions(value || "", countryCode);
    setFilteredOptions(initialFilteredOptions);
  }, [value, countryCode, postcodes]);

  const commonInputProps: any = {
    name,
    searchValue,
    onBlur,
    onChange: handleInputChange,
    placeholder,
    prefix: <Icon.McsIcMapPin />,
    suffix,
    size,
    value,
    disabled,
    className: classes?.inputClassName,
    status: showError && errorMessage ? "error" : "",
  };

  const renderItem = (e: any) => ({
    // value: e.id.toString(),
    value: `${e.id.toString()}-${e.zip_code.toString()}`,
    key: e.id.toString(),
    label: (
      <div className="flex flex-col gap-2" key={e.id}>
        <span className="!text-12 !font-medium !leading-18 !text-gray-700">
          {e.area_english} ({e.area_local})
        </span>
        <span>
          {e.zip_code}, {e.country_code}
        </span>
      </div>
    ),
  });

  console.log(filteredOptions, "filteredOptions>>>");

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <AutoComplete
        popupClassName="!bg-primary-50"
        options={filteredOptions?.map((e: any) => renderItem(e))}
        size="large"
        className="w-full"
        value={searchValue}
        showSearch={true}
        onSearch={(value: string) => {
          // setFilteredOptions(filterOptions(value || "", countryCode));
        }}
        onSelect={(value: string) => {
          const zip_code = value.split("-")[1];
          onSelect?.(zip_code);
          setSearchValue(zip_code);
        }}
        disabled={disabled}
      >
        <Input
          {...commonInputProps}
          onFocus={onFocus}
          autoFocus={autoFocus}
          value={"test"}
        />
      </AutoComplete>

      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default LocationPickerComponent;
