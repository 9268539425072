import React, { useEffect } from "react";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import { TITLES } from "src/types";
import BookingDatatable from "./components/booking-datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";

export default function Bookings() {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.BOOKINGS;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <CustomerPanelLayout breadcrumbsData={[{ title: "Bookings" }]}>
      <ContentWrapper>
        <BookingDatatable />
      </ContentWrapper>
    </CustomerPanelLayout>
  );
}
