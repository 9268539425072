import React from "react";

const McsIcUserManagement = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10 14.707C11.7259 14.707 13.125 13.3079 13.125 11.582C13.125 9.85614 11.7259 8.45703 10 8.45703C8.27411 8.45703 6.875 9.85614 6.875 11.582C6.875 13.3079 8.27411 14.707 10 14.707Z"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3125 9.70704C16.0404 9.70582 16.7586 9.87472 17.4096 10.2003C18.0607 10.5258 18.6267 10.999 19.0625 11.582"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.9375 11.582C1.37328 10.999 1.93928 10.5258 2.59036 10.2003C3.24144 9.87472 3.95957 9.70582 4.6875 9.70704"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 17.5195C5.91158 16.6766 6.55163 15.9662 7.34722 15.4693C8.14282 14.9724 9.06198 14.709 10 14.709C10.938 14.709 11.8572 14.9724 12.6528 15.4693C13.4484 15.9662 14.0884 16.6766 14.5 17.5195"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.68747 9.70703C4.21298 9.70751 3.74814 9.57295 3.34728 9.31906C2.94642 9.06517 2.62608 8.70245 2.42371 8.27327C2.22134 7.84409 2.14529 7.36618 2.20444 6.89538C2.26359 6.42459 2.4555 5.98034 2.75775 5.61457C3.06 5.24879 3.4601 4.97658 3.91131 4.82974C4.36252 4.68291 4.8462 4.66751 5.30583 4.78536C5.76546 4.9032 6.18206 5.14942 6.50696 5.49524C6.83186 5.84106 7.05163 6.2722 7.1406 6.73828"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8594 6.73828C12.9483 6.2722 13.1681 5.84106 13.493 5.49524C13.8179 5.14942 14.2345 4.9032 14.6941 4.78536C15.1538 4.66751 15.6375 4.68291 16.0887 4.82974C16.5399 4.97658 16.94 5.24879 17.2422 5.61457C17.5445 5.98034 17.7364 6.42459 17.7955 6.89538C17.8547 7.36618 17.7786 7.84409 17.5763 8.27327C17.3739 8.70245 17.0536 9.06517 16.6527 9.31906C16.2518 9.57295 15.787 9.70751 15.3125 9.70703"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcUserManagement;
