import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Auth APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("email", body?.email);
        formData.append("password", body?.password?.trim());

        return {
          url: ENDPOINTS.ADMIN.LOGIN,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    forgotPassword: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("email", body?.email);

        return {
          url: ENDPOINTS.ADMIN.FORGOT_PASSWORD,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    verifyRecaptcha: build.mutation<IResponse, Partial<{ token: string }>>({
      query: (body) => ({
        url: ENDPOINTS.ADMIN.VERIFY_RECAPTCHA,
        method: "POST",
        body,
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    verifyOtp: build.mutation<IResponse, Partial<{ code: any; token: any }>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("otp", body?.code);
        formData.append("token", body?.token);

        return {
          url: ENDPOINTS.ADMIN.VERIFY_OTP,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    resetPassword: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("token", body?.token);
        formData.append("password", body?.password?.trim());
        formData.append("confirm_password", body?.password?.trim());

        return {
          url: ENDPOINTS.ADMIN.RESET_PASSWORD,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useVerifyRecaptchaMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
} = APIS;
