import { AuthCusState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "src/utils";

const initialState: AuthCusState = {
  user: null,
  authToken: getToken(),
};

const authCusSlice = createSlice({
  name: "authCus",
  initialState,
  reducers: {
    setCusUser(state, action) {
      state.user = action.payload;
    },
    setAuthCusToken(state, action) {
      state.authToken = action.payload;
    },
  },
});

export default authCusSlice.reducer;
export const { setAuthCusToken, setCusUser } = authCusSlice.actions;
