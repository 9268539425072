import React from 'react'

const McsIcEye = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M1.6967 10.2683C1.63919 10.0955 1.63919 9.90867 1.6967 9.73583C2.85253 6.25833 6.13336 3.75 10 3.75C13.865 3.75 17.1442 6.25583 18.3025 9.73167C18.3609 9.90417 18.3609 10.0908 18.3025 10.2642C17.1475 13.7417 13.8667 16.25 10 16.25C6.13503 16.25 2.85503 13.7442 1.6967 10.2683Z"
    stroke="#1D2939"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10C7.5 9.33696 7.76339 8.70107 8.23223 8.23223C8.70107 7.76339 9.33696 7.5 10 7.5C10.663 7.5 11.2989 7.76339 11.7678 8.23223C12.2366 8.70107 12.5 9.33696 12.5 10Z"
    stroke="#1D2939"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcEye