import { Button, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Icon } from "src/components/atoms/icons";
import TextInput from "src/components/atoms/text-input";
import UploadModal, { formatFileSize } from "./upload-modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useConfirmQuotationFormMutation } from "src/services/api-service/quotations";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { useNavigate } from "react-router-dom";
import InputGroup from "src/components/atoms/input-group";

export default function ConfirmForm({
  id,
  quotationId,
}: {
  id: string;
  quotationId: string | null;
}) {
  const [confirmQuotationForm] = useConfirmQuotationFormMutation();
  const [fileList, setFileList] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      shipper_customer: "", // *
      shipper_name: "", // *
      address: "", // *
      shipper_reference: "",
      email: "", // *
      contact_person: "", // *
      contact_phone: "",
      additional_info: "",
    },
    validationSchema: yup.object({
      shipper_customer: yup.string().required("Shipper Company is required."),
      shipper_name: yup.string().required("Consignee Company is required."),
      address: yup.string().required("Address is required."),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required."),
      contact_person: yup.string().required("Contact Person is required."),
    }),
    onSubmit: (values) => {
      confirmQuotationForm({
        ...values,
        id,
        files: fileList.filter((file) => file instanceof File),
      })
        .unwrap()
        .then((res: any) => {
          if (Boolean(res.success) === true) {
            showToast(TOASTR_TYPES.SUCCESS, res.message);
            setFileList([]);
            navigate("/bookings");
          } else {
            showToast(TOASTR_TYPES.ERROR, res.message);
          }
        })
        .catch((err: any) => {
          console.log(err, "error");
          showToast(TOASTR_TYPES.ERROR, "Something went wrong.");
        });
    },
  });

  const removeFile = (id: any) => {
    setFileList(fileList.filter((item) => item.uid != id));
  };

  return (
    <>
      <div className="col-span-2">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="rounded-[0.625rem] border border-border">
            <div className="border-b border-border px-24 py-16">
              <h2 className="text-lg font-bold">
                Fill Booking confirmation form
              </h2>
            </div>
            <div className="text[#344054] grid grid-cols-6 gap-5 px-24 py-20">
              <div className="col-span-3">
                <TextInput
                  type="text"
                  label="Shipper Company"
                  placeholder="Enter Here"
                  required
                  {...formik.getFieldProps("shipper_customer")}
                />
                <FormErrorMsg formik={formik} field="shipper_customer" />
              </div>
              <div className="col-span-3">
                <TextInput
                  type="text"
                  label="Consignee Company"
                  placeholder="Enter Here"
                  required
                  {...formik.getFieldProps("shipper_name")}
                />
                <FormErrorMsg formik={formik} field="shipper_name" />
              </div>

              <div className="col-span-3">
                <TextInput
                  type="text"
                  label="Address"
                  placeholder="Enter Here"
                  required
                  {...formik.getFieldProps("address")}
                />
                <FormErrorMsg formik={formik} field="address" />
              </div>
              <div className="col-span-3">
                <TextInput
                  type="text"
                  label="PO# or Shipper Reference (if available)"
                  placeholder="Enter Here"
                  {...formik.getFieldProps("shipper_reference")}
                />
                <FormErrorMsg formik={formik} field="shipper_reference" />
              </div>

              <div className="col-span-2">
                <TextInput
                  type="text"
                  label="Email"
                  placeholder="Enter Here"
                  required
                  {...formik.getFieldProps("email")}
                />
                <FormErrorMsg formik={formik} field="email" />
              </div>
              <div className="col-span-2">
                <TextInput
                  type="text"
                  label="Contact person"
                  placeholder="Enter Here"
                  required
                  {...formik.getFieldProps("contact_person")}
                />
                <FormErrorMsg formik={formik} field="contact_person" />
              </div>
              <div className="col-span-2">
                {/* <TextInput
                  type="text"
                  label="Phone number"
                  placeholder="+1 (555) 000-0000"
                  {...formik.getFieldProps("contact_phone")}
                /> */}
                <InputGroup
                  label="Phone number"
                  placeholder="000-0000"
                  {...formik.getFieldProps("contact_phone")}
                />
                <FormErrorMsg formik={formik} field="contact_phone" />
              </div>

              <div className="col-span-6">
                <label>
                  <span className="mb-6 text-sm">Additional Information</span>
                  <TextArea
                    placeholder="Write your information here"
                    allowClear
                    className="custom-ant-input-affix-wrapper"
                    {...formik.getFieldProps("additional_info")}
                  />
                  <FormErrorMsg formik={formik} field="additional_info" />
                </label>
              </div>
            </div>

            <div className="mx-24 mb-24 flex flex-wrap items-center gap-3">
              <Button
                className="flex items-center hover:!text-slate-500"
                icon={<Icon.McsIcPlusBlack />}
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  setIsModalOpen(true);
                }}
              >
                Upload file here
              </Button>

              {fileList &&
                fileList.map((file) => (
                  <>
                    {parseInt(formatFileSize(file.size ?? 0)) != 0 && (
                      <div
                        className="flex justify-between gap-3 rounded-lg border border-gray-300 px-12 py-5 text-16"
                        key={file.uid}
                      >
                        <span className="text-gray-500-base">{file.name}</span>
                        <button onClick={() => removeFile(file.uid)}>
                          <Icon.McsIcCross fill="red" width="24" height="24" />
                        </button>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="mt-18 flex gap-4">
            <Button
              // htmlType="reset"
              onClick={() => {
                // formik.resetForm();
                // setFileList([]);
                if (quotationId) {
                  return navigate(`/quotations/results?id=${quotationId}`);
                }

                navigate(`/quotations?tab=Generate%20Quotations`);
              }}
              size="large"
              className="hover:!text-slate-500"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              size="large"
              className="bg-orange-600 text-white hover:!bg-orange-500  hover:!text-white"
            >
              Submit & Request Booking
            </Button>
          </div>
        </form>
      </div>

      <UploadModal
        allowMultipleFiles={true}
        open={isModalOpen}
        onOk={() => {
          document.body.style.overflow = "unset";
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setFileList([]);
          document.body.style.overflow = "unset";
          setIsModalOpen(false);
        }}
        fileList={fileList}
        setFileList={setFileList}
      />
    </>
  );
}

const FormErrorMsg = ({ formik, field }: { formik: any; field: string }) => {
  return formik.touched[field] && formik.errors[field] ? (
    <span className="text-sm text-red-500">{formik.errors[field]}</span>
  ) : null;
};
