import React from "react";
import QuotationHorizontalStepsComponent from "./quotation-horizontal-steps.component";

const QuotationHorizontalStepsContainer = ({
  value,
  mode,
}: {
  value: any;
  mode: string;
}) => {
  return <QuotationHorizontalStepsComponent value={value} mode={mode} />;
};

export default QuotationHorizontalStepsContainer;
