import React from "react";
import HeaderSubTitle from "src/components/atoms/header-subtitle";
import { MAILING_ADDRESS, TITLES } from "src/types";

const ContactUs = () => {
  const TITLE = TITLES.CONTACT;
  const mailing = MAILING_ADDRESS;

  const mailTo = () => {
    window.location.href = `mailto:${mailing}`;
  };

  return (
    <div className="flex flex-col gap-4">
      <HeaderSubTitle>{TITLE}</HeaderSubTitle>

      <div
        className="cursor-pointer rounded-xl border-2 border-border-gray-color py-10 lg:w-[35%]"
        onClick={mailTo}
      >
        <div className="flex flex-col items-center gap-2">
          <h3 className="border-border-color font-semibold uppercase text-gray-100">
            Email Us at
          </h3>
          <h4 className="font-semibold">{mailing}</h4>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
