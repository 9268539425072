import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./datatable";
import CustomLink from "src/components/atoms/custom-link";
import ContentWrapper from "src/components/layouts/content-wrapper";
import ContentHeader from "src/components/layouts/content-header";
import { IBreadcrumbItem } from "src/types";
import { useSearchParams } from "react-router-dom";

const CostGroups = () => {
  const [url] = useSearchParams();
  const marginGroupName = url?.get("name") || "";

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Margin Groups",
      href: "/admin/margin-groups",
    },
    {
      title: marginGroupName,
      hidden: false,
    },
  ];

  return (
    <>
      <PanelLayout breadcrumbsData={breadcrumbsData}>
        <>
          <ContentHeader>
            <CustomLink href={""}>Back to Margin Groups</CustomLink>
          </ContentHeader>
          <ContentWrapper>
            <Datatable />
          </ContentWrapper>
        </>
      </PanelLayout>
    </>
  );
};

export default CostGroups;
