import React, { useEffect, useLayoutEffect, useState } from "react";

import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import { useAppDispatch } from "src/store/hook";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import CustomSelect from "src/components/atoms/custom-select";
import {
  useDeleteCostGroupMutation,
  useGetCostGroupsMutation,
  useGetCustomerNamesMutation,
} from "src/services/api-service/margin-groups";
import { useSearchParams } from "react-router-dom";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import AddCustomerContainer from "src/pages/user-management/elements/customers/datatable/add-customer/add-customer.container";
import { setLoading } from "src/store/features/alerts";
import { ENDPOINTS } from "src/store/endpoints";
import useUser from "src/hooks/useUser";

const Datatable = ({ formik, resetError, reFetchData }: any) => {
  const [getListMutation] = useGetCostGroupsMutation<any>();

  const [deleteItemMutation] = useDeleteCostGroupMutation<any>();
  const [getCustomersMutation] = useGetCustomerNamesMutation<any>();

  const [updateId, setUpdateId] = useState("");
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [url] = useSearchParams();
  const marginGroupId = url?.get("id");
  const marginGroupName = url?.get("name") || "";
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadEndpoint = ENDPOINTS.ADMIN.DELETE_COST_GROUP;
  const [isDownload, setIsDownload] = useState(false);

  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    if (isDownload) {
      downloadDataById();
    }
  }, [isDownload]);

  useEffect(() => {
    formik.setFieldValue("name", marginGroupName);
  }, []);

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (reFetchData) {
      handleCloseAddModal();
      getCustomerNames();
      fetchData({ currentPage: pagination?.currentPage, query: "" });
    }
  }, [reFetchData]);

  const [filtersData, setFiltersData] = useState({
    id: marginGroupId,
  });

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "cost-groups-datatable",
    mutation: getListMutation,
    filtersData,
  });

  const user = useUser();

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const [selectedColumns, setSelectedColumns] = useState<any[]>(
    columns(handleOpenDeleteModal),
  );

  const [columnDefs, setColumnDefs] = useState<any[]>(
    columns(handleOpenDeleteModal),
  );

  const handleDownload = () => {
    setIsDownload(true);
  };

  const getFilenameFromHeaders = (headers: any) => {
    const contentDisposition = headers.get("content-disposition");
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        return filenameMatch[1].replace(/['"]/g, "");
      }
    }
    return null;
  };

  const downloadDataById = async () => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl =
        baseUrl +
        downloadEndpoint +
        "/" +
        marginGroupId +
        "/group-customers-pdf";
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const filename = getFilenameFromHeaders(response.headers);
        const blob = new Blob([await response.blob()], {
          type: response.headers.get("content-type") || "",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename || "Margin_Groups.pdf"; // Specify the desired filename here
        link.click();
        showToast(
          TOASTR_TYPES.SUCCESS,
          `You successfully downloaded the datasource`,
        );
      } else {
        console.error(
          "Error downloading file. Server returned:",
          response.status,
        );
      }
    } finally {
      setIsDownload(false);
      dispatch(setLoading(false));
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    console.log(e);
  };
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = () => {
    const body = {
      groupId: marginGroupId,
      customerId: updateId,
    };
    try {
      updateId &&
        deleteItemMutation(body)
          .unwrap()
          .then((data) => {
            getCustomerNames();
            showToast(TOASTR_TYPES.SUCCESS, data?.message);
            fetchData({ currentPage: pagination?.currentPage, query: "" });
          });
    } finally {
      // setIsCrudUpdate(true);
    }
    setDeleteModalOpen(false); // Close the modal after deletion
  };
  const [searchValue, setSearchValue] = useState(""); // State variable for search input value

  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleCellClick = (event: any) => {
    setUpdateId(event?.data?.id);
  };

  const addUpdateData = () => {
    formik.submitForm().then((err: any) => {
      console.log(formik);
    });
  };

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCustomerNames();
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValue]);

  const getCustomerNames = async () => {
    const data: any = await getCustomersMutation({ search: searchValue })
      .unwrap()
      .catch((err: any) => {
        // console.log(err)
      });
    setOptions(data?.data);
  };

  const handleCloseAddCustomerModal = (data: any) => {
    setAddCustomerModalOpen(false);
    if (data) {
      setOptions([data].concat(options));
    }
  };

  return (
    <>
      <CustomDatatable
        title={toTitleCase(marginGroupName)}
        description={`${pagination.total} Total Customer${pagination.total > 1 ? "s" : ""} Attached`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={() => {
          console.log("row clicked");
        }}
        onCellClicked={(event: any) => handleCellClick(event)}
        button={
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center">
              <div className="flex items-center gap-3">
                <CustomButton
                  disabled={!data?.length}
                  variant="secondary-icon-btn"
                  onClick={handleDownload}
                >
                  <div className="flex items-center gap-2">
                    <i className="shrink-0">
                      <Icon.McsIcExportPdf />
                    </i>
                    <span>Export PDF</span>
                  </div>
                </CustomButton>
                <CustomButton
                  variant="primary-icon-btn"
                  onClick={handleOpenAddModal}
                >
                  <div className="flex items-center gap-2">
                    <i className="shrink-0">
                      <Icon.McsIcIconPlus />
                    </i>
                    <span>Attach Customer</span>
                  </div>
                </CustomButton>
              </div>
              {/* add group modal */}
              <CustomModal
                title="Attach Customer"
                isOpen={isAddModalOpen}
                toggle={handleCloseAddModal}
                maskClosable={false}
                icon={<Icon.McsIcChevronBack />}
                width={760}
                innerClass="custom-common-modal"
                footer={
                  <div className="w-full justify-end gap-4 lg:flex">
                    <div className="order-1 lg:order-2">
                      <CustomButton
                        variant="primary"
                        className="w-full"
                        htmlType="submit"
                      >
                        Save
                      </CustomButton>
                    </div>
                    <div className="order-2 mt-10 lg:order-1 lg:mt-0">
                      <CustomButton
                        variant="secondary"
                        onClick={handleCloseAddModal}
                        className="w-full"
                      >
                        Cancel
                      </CustomButton>
                    </div>
                  </div>
                }
              >
                <div className="modal-body flex flex-col gap-4">
                  <div>
                    <TextInput
                      name="text"
                      type="text"
                      label="Group Name"
                      value={marginGroupName}
                      disabled={true}
                      placeholder="Enter name here.."
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomSelect
                      mode="tags"
                      label="Add Customer to this Group"
                      name="newCustomerIds"
                      placeholder="Select an option"
                      value={formik?.values?.newCustomerIds}
                      showError={Boolean(formik.touched.newCustomerIds)}
                      onChange={(e: any) => {
                        resetError();
                        formik.setFieldValue("newCustomerIds", e);
                        // formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.newCustomerIds}
                      options={options}
                      required={true}
                      dropdownRender={(menu) => (
                        <div>
                          <div
                            // onMouseDown={(e) => e.preventDefault()}
                            className="border-b border-neutral-700 px-16 py-10"
                          >
                            <div>
                              <div className="flex justify-between gap-2">
                                <div className="w-full">
                                  {" "}
                                  <TextInput
                                    name="search"
                                    type="text"
                                    placeholder="Search customer"
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                    prefix={<Icon.McsIcSearch />}
                                    suffix={
                                      <button
                                        onClick={() => {
                                          setSearchValue("");
                                        }}
                                      >
                                        <i hidden={searchValue ? false : true}>
                                          <Icon.McsIcMenu />
                                        </i>
                                      </button>
                                    }
                                  />
                                </div>
                                <div>
                                  <CustomButton
                                    variant="primary-icon-btn"
                                    onClick={() => {
                                      handleOpenAddModal();
                                      setAddCustomerModalOpen(true);
                                    }}
                                  >
                                    <div className="flex items-center gap-2">
                                      <i className="shrink-0">
                                        <Icon.McsIcIconPlus />
                                      </i>
                                      <span>Add New</span>
                                    </div>
                                  </CustomButton>
                                </div>
                              </div>
                            </div>
                          </div>
                          {menu}
                        </div>
                      )}
                      suffixIcon={<Icon.McsIcDownArrow />}
                    />
                  </div>
                </div>
              </CustomModal>
              {/* add group modal end */}
            </div>
          </form>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to remove this Customer?"
        message="You can always add the customer back to this group."
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />

      {isAddCustomerModalOpen && (
        <AddCustomerContainer
          handleCloseAddModal={handleCloseAddCustomerModal}
        />
      )}
    </>
  );
};

export default Datatable;
