import React from "react";
import SelectInput from "src/components/atoms/select-input";
import Pagination from "src/components/molecules/pagination";
import { IDataTable } from "../../types";

const DatatableFooterComponent = ({
  pagination,
  handlePageChange,
  handleItemsPerPageChange,
  startDocument,
  endDocument,
  paginationOptions,
}: Partial<IDataTable>) => {
  return (
    <div
      className={`flex flex-wrap items-center justify-between gap-4 px-16 py-8 ${pagination?.total > 10 ? "block" : "hidden"} `}
    >
      <>
        <div className="flex items-center gap-4">
          <span className="text-14 font-normal leading-20 text-gray-800">
            Show{" "}
          </span>

          <SelectInput
            name="select"
            containerClassName="data-limit-select"
            options={paginationOptions}
            onSelect={(value: number) => {
              handleItemsPerPageChange(value);
            }}
            value={pagination?.itemsPerPage}
          />

          <span className="text-14 font-normal leading-20 text-gray-800">
            {" "}
            <p>
              {`${pagination?.total == 0 ? 0 : startDocument || 1}-${
                endDocument || 1
              } of ${pagination?.total || 1}`}
            </p>
          </span>
        </div>
        <Pagination
          pagination={pagination}
          handlePageChange={handlePageChange}
        />
      </>
    </div>
  );
};

export default DatatableFooterComponent;
