import React from "react";

const McsIcTickGreen = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0003 2.64385C26.4136 4.03724 28.4211 6.03674 29.8241 8.4444C31.2271 10.8521 31.977 13.5845 31.9995 16.371C32.022 19.1576 31.3163 21.9017 29.9524 24.3317C28.5884 26.7617 26.6135 28.7933 24.2231 30.2256C21.8327 31.6577 19.1096 32.4408 16.3236 32.4971C13.5375 32.5535 10.785 31.8813 8.33858 30.547C5.89217 29.2127 3.83665 27.2626 2.3755 24.8898C0.914354 22.5169 0.0982558 19.8036 0.00800025 17.0185L0 16.5001L0.00800025 15.9817C0.0976064 13.2184 0.901685 10.5255 2.34186 8.16548C3.78202 5.80554 5.80912 3.859 8.22556 2.51566C10.642 1.17234 13.3652 0.478062 16.1298 0.500528C18.8944 0.522994 21.606 1.26144 24.0003 2.64385ZM21.9315 12.1688C21.656 11.8933 21.2894 11.7278 20.9006 11.7033C20.5117 11.6789 20.1273 11.7972 19.8195 12.036L19.6691 12.1688L14.4002 17.4361L12.3314 15.3689L12.181 15.236C11.8731 14.9974 11.4887 14.8792 11.1 14.9038C10.7113 14.9283 10.3448 15.0938 10.0694 15.3692C9.79395 15.6447 9.62842 16.0111 9.60391 16.3998C9.5794 16.7887 9.69751 17.1729 9.93613 17.4809L10.0689 17.6313L13.269 20.8313L13.4194 20.9641C13.7 21.1818 14.0451 21.3 14.4002 21.3C14.7553 21.3 15.1004 21.1818 15.381 20.9641L15.5314 20.8313L21.9315 14.4312L22.0643 14.2808C22.3031 13.973 22.4214 13.5885 22.3969 13.1997C22.3724 12.8109 22.207 12.4443 21.9315 12.1688Z"
        fill="#27AE60"
      />
    </svg>
  );
};

export default McsIcTickGreen;
