import React from "react";
import CustomDropdown from "src/components/atoms/custom-dropdown";
import { IMenu } from "./types";
import { Icon } from "src/components/atoms/icons";

const MenuComponent = (props: IMenu) => {
  return (
    <CustomDropdown {...props}>
      <i className="p-8">
        <Icon.McsIcUserDotsThreeVertical />
      </i>
    </CustomDropdown>
  );
};

export default MenuComponent;
