import React, { useEffect } from "react";
import AuthLayout from "src/components/layouts/auth-layout";

import SignInForm from "./sign-in-form";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { userCusSelector } from "src/store/features/auth/customer/selectors";
import { useNavigate } from "react-router-dom";
import { TITLES } from "src/types";
import { userSelector } from "src/store/features/auth/selectors";
import { logout } from "src/store/features/auth/actions";
import { setAuthToken } from "src/store/features/auth";
import { clearToken, showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";

const CustomerLogin = () => {
  const user = useAppSelector(userCusSelector);
  const adminUser = useAppSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adminUser) {
      //Logout
      dispatch(logout());
      dispatch(setAuthToken(""));
      clearToken();
      showToast(TOASTR_TYPES.SUCCESS, "You are logged out as admin");
    }

    if (!user) return;
    navigate("/quotations");
  }, [user, adminUser]);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.LOGIN;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <AuthLayout>
      <div className="">
        <SignInForm />
      </div>
    </AuthLayout>
  );
};

export default CustomerLogin;
