import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import McsCustomTabs from "src/components/molecules/mcs-custom-tabs";
import Pricing from "../../../prices/elements/pricings";
import Hubs from "../../../prices/elements/trucking";
import LocalCharges from "../../../prices/elements/local-charges";
import GenerateQuotation from "../generate-quotation";
import QuotationHistory from "../quotation-history";
import { useLocation, useNavigate } from "react-router-dom";
import { TABS } from "./types";
import { useQuotationsContext } from "src/context/quotations-context";

const QuotationTabsComponent = ({ clicked, tabChanged }: any) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("0");
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get("tab") || TABS.QUOTATION.value;
  const id = queryParams.get("id") || "";
  const { resetAllData } = useQuotationsContext();

  useEffect(() => {
    if (tabValue?.toLowerCase() === TABS.QUOTATION.value.toLowerCase()) {
      setActiveTab(TABS.QUOTATION.tab);
      resetAllData();
      navigate(`/admin/quotations?tab=${tabValue}${id ? "&id=" + id : ""}`);
    } else if (tabValue?.toLowerCase() === TABS.HISTORY.value.toLowerCase()) {
      setActiveTab(TABS.HISTORY.tab);
      navigate(`/admin/quotations?tab=${tabValue}`);
    }
  }, [location.search]);

  const handleChange = (index: any) => {
    setActiveTab(index.toString());
    const selectedTab = tabs[index];
    tabChanged(selectedTab.label);
    navigate(
      `/admin/quotations?tab=${selectedTab.label}${id && !selectedTab.label.toLowerCase().includes("history") ? "&id=" + id : ""}`,
    );
  };

  const tabs = [
    {
      label: "Generate Quotations",
      children: <GenerateQuotation clicked={clicked} />,
    },
    {
      label: "Quotation History",
      children: <QuotationHistory />,
    },
  ];
  return (
    <>
      <McsCustomTabs
        tabs={tabs}
        onChange={handleChange}
        defaultActiveKey={activeTab}
      />
    </>
  );
};

export default QuotationTabsComponent;
