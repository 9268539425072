import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import DatatableComponent from "./datatable.component";
import useLoadingError from "src/hooks/useLoadingError";
import { adminUserSchema } from "src/validations/admin-user-schema";
import {
  useAddAdminMutation,
  useUpdateAdminMutation,
} from "src/services/api-service/admin-users";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { IResponse } from "src/services/types";
import { showToast } from "src/utils";
import useTitleCase from "src/hooks/useTitleCase";

const DatatableContainer = () => {

  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [addAdminUser] = useAddAdminMutation<any>();
  const [updateAdminUser] = useUpdateAdminMutation<any>();
  const [id, setId] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const { titleCasedString, handleInputChange } = useTitleCase();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      role: null,
      password: "",
    },
    validationSchema: adminUserSchema(id ? true : false),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    handleInputChange(formik.values.name,'name');
  }, [formik.values.name])

  function handleSubmit(values: any) {
    setReFetchData(false);
    const body = {
      name: titleCasedString?.name||formik.values.name,
      role: values.role,
      email: values.email,
      password: values.password,
      id: id,
    };
    setCloseModal(false);
    startLoading();

    let APIHIT;
    if (!id) {
      APIHIT = addAdminUser(body);
    } else {
      APIHIT = updateAdminUser(body);
    }

    // setId("");
    APIHIT &&
      APIHIT.unwrap()
        .then((data:any) => {
          showToast(
            TOASTR_TYPES.SUCCESS, data?.message||data?.data?.message||data?.data?.original?.message||
            `${values.role === "admin" ? "Admin" : "User"} ${
              id ? "updated" : "created"
            } successfully`,
          );
          setReFetchData(true);
          setCloseModal(true);
        })
        .catch((err: IResponse) => {
          // showToast(TOASTR_TYPES.ERROR, err?.message);
        })
        .finally(() => {
          setTimeout(() => {
            stopLoading();
          }, 1000);
        });
  }

  const getCurrentId = (currentId: any) => {
    setCloseModal(false);
    setId(currentId);
  };

  const setCloseModalState = (value: any) => {
    setCloseModal(value);
  };

  return (
    <DatatableComponent
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      getId={getCurrentId}
      closeModal={closeModal}
      setCloseModal={setCloseModalState}
      reFetchData={reFetchData}
    />
  );
};

export default DatatableContainer;
