import React, { useEffect } from "react";
import { TITLES } from "src/types";
import Layout from "./layout";
import { Card, Collapse, Steps } from "antd";
import { Icon } from "src/components/atoms/icons";
import BackButton from "src/components/atoms/back-button";
import RouteCard from "./components/route-card";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ConfirmForm from "./components/confirm-form";
import useGetSubQuotation from "./useGetSubQuotation";
import "./confirm-quotation.css";

export default function ConfirmQuotation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const quotationId = searchParams.get("quoteId");

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.BOOKINGS;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  if (!id) {
    navigate(-1);
  }

  const { data, isPending, isError } = useGetSubQuotation(id);

  const items = [
    {
      title: "Choose a quotation",
      icon: <Icon.McsIcTickGreen />,
    },
    {
      title: <span className="text-blue-1">Fill Shipping Instructions</span>,
    },
    {
      title: <span className="text-gray-800">Booking Requested</span>,
      icon: (
        <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#C8DCEA]">
          <img
            alt="Success"
            src="/assets/images/success.png"
            className="max-h-full w-[80%]"
          />
        </div>
      ),
    },
  ];

  return (
    <Layout
      breadcrumbsData={[
        {
          title: "Generate Quotation",
          href: `/quotations?tab=Generate%20Quotations`,
        },
        {
          title: "Fill Details",
          href: `/quotations?tab=Generate%20Quotations&id=${quotationId}`,
        },
        { title: "Results", href: `/quotations/results?id=${quotationId}` },
        { title: "Confirm Quotation" },
      ]}
    >
      <div className={`wrapper`}>
        <BackButton />
        <div className="my-24 grid grid-cols-3 items-center gap-x-4 gap-y-6 px-24">
          {/* ===== Steps ===== */}
          <div className="col-span-2">
            <Steps
              current={1}
              labelPlacement="vertical"
              items={items}
              className="confirm-steps"
            />
          </div>

          {/* ===== Features ===== */}
          <Card className="flex flex-col items-center gap-y-3 bg-gradient-to-r from-orange-500 via-orange-400 to-yellow-500">
            <div className="space-y-3">
              <CardItem>Get multiple quotations</CardItem>
              <CardItem>Simple and fast booking</CardItem>
              <CardItem>24/7 Customer support</CardItem>
            </div>
          </Card>

          {/* ===== Form ===== */}
          <ConfirmForm id={id as string} quotationId={quotationId} />

          {/* ===== Charges ===== */}
          <div className="flex flex-col gap-y-4 self-start">
            {isError ? (
              <div className="flex h-36 w-full items-center justify-center rounded-md bg-primary-50">
                <span className="animate-pulse font-bold">
                  Something Went Wrong :{`(`}
                </span>
              </div>
            ) : (
              <>
                <RouteCard data={data} isPending={isPending} />

                <Card size="small" className="bg-primary-50 p-0">
                  <CollpseFee
                    title="Pre - Carriage"
                    feeArray={data?.priceBreakUp?.preCarriage}
                    isBorder={false}
                  />
                  <CollpseFee
                    title="Origin Charges"
                    feeArray={data?.priceBreakUp?.fobCharges}
                  />
                  <CollpseFee
                    title="Ocean Freight"
                    feeArray={data?.priceBreakUp?.oceanFreight}
                  />
                  <div className="flex items-center justify-between border-t border-[#475467]  px-12 pt-8">
                    <span className="text-sm font-medium">Billing Price</span>
                    <span className="text-lg font-semibold text-primary-500-base">
                      EUR {parseFloat(data?.price).toFixed(2)}
                    </span>
                  </div>
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const CollpseFee = ({
  title,
  feeArray,
  isBorder = true,
}: {
  title: string;
  feeArray: any;
  isBorder?: boolean;
}) => {
  const totalFee = feeArray?.reduce(
    (accumulator: number, currentValue: any) =>
      accumulator + Number(currentValue.charges.replace(/,/g, "")),
    0,
  );

  return (
    <Collapse
      collapsible={feeArray?.length < 1 ? "disabled" : "header"}
      bordered={false}
      size="small"
      expandIcon={({ isActive }) => {
        return (
          <>{isActive ? <Icon.McsIcMinusCircle /> : <Icon.McsIcPlusCircle />}</>
        );
      }}
      className="main-confirm-collapse"
      items={[
        {
          key: "1",
          label: (
            <div className="flex w-full select-none !justify-between font-medium">
              <span>{title}</span>
              <span
                className={`font-medium ${feeArray?.length < 1 ? "" : "text-primary-500-base"}`}
              >
                EUR {parseFloat(totalFee).toFixed(2)}
              </span>
            </div>
          ),
          headerClass: `w-full ${isBorder ? "border-t border-[#AFB6D1]" : ""}`,
          children: (
            <>
              {feeArray?.map((pc: any, i: any) => (
                <div
                  key={`${pc.type}-${i}`}
                  className="mx-16 mt-4 grid grid-cols-3 pb-6 text-xs"
                >
                  <span className="line-clamp-1 text-ellipsis">{pc.type}</span>
                  <span className="line-clamp-1 text-ellipsis">{pc.qty}</span>
                  <span className="text-right">EUR {pc.charges}</span>
                </div>
              ))}
            </>
          ),
        },
      ]}
    />
  );
};

const CardItem = ({ children }: { children: string }) => {
  return (
    <div className="flex items-center gap-3">
      <Icon.McsIcCheck />
      <span className="select-none text-base font-semibold text-white">
        {children}
      </span>
    </div>
  );
};
