import React from "react";
import QuotationHorizontalStepsContainer from "./quotation-horizontal-steps.container";

const QuotationHorizontalSteps = ({
  value,
  mode,
}: {
  value: any;
  mode?: string;
}) => {
  return (
    <QuotationHorizontalStepsContainer value={value} mode={mode ?? "ocean"} />
  );
};

export default QuotationHorizontalSteps;
