import React from 'react'

const McsIcExportPdf= () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10.625 6.87461H9.375V2.75742L7.16406 4.96836C7.04699 5.08646 6.8878 5.15323 6.72151 5.15396C6.55522 5.15469 6.39545 5.08933 6.27734 4.97227C6.15924 4.8552 6.09248 4.69601 6.09174 4.52972C6.09101 4.36342 6.15637 4.20365 6.27344 4.08555L9.55469 0.804297C9.67318 0.686934 9.83322 0.621094 10 0.621094C10.1668 0.621094 10.3268 0.686934 10.4453 0.804297L13.7266 4.08555C13.8434 4.20271 13.9091 4.36146 13.9091 4.52695C13.9091 4.69245 13.8434 4.85119 13.7266 4.96836C13.6684 5.02741 13.5991 5.07431 13.5226 5.10632C13.4462 5.13833 13.3641 5.15481 13.2812 5.15481C13.1984 5.15481 13.1163 5.13833 13.0399 5.10632C12.9634 5.07431 12.8941 5.02741 12.8359 4.96836L10.625 2.75742V6.87461ZM15.625 6.87461H10.625V9.99961C10.625 10.1654 10.5592 10.3243 10.4419 10.4416C10.3247 10.5588 10.1658 10.6246 10 10.6246C9.83424 10.6246 9.67527 10.5588 9.55806 10.4416C9.44085 10.3243 9.375 10.1654 9.375 9.99961V6.87461H4.375C4.04348 6.87461 3.72554 7.00631 3.49112 7.24073C3.2567 7.47515 3.125 7.79309 3.125 8.12461V16.2496C3.125 16.5811 3.2567 16.8991 3.49112 17.1335C3.72554 17.3679 4.04348 17.4996 4.375 17.4996H15.625C15.9565 17.4996 16.2745 17.3679 16.5089 17.1335C16.7433 16.8991 16.875 16.5811 16.875 16.2496V8.12461C16.875 7.79309 16.7433 7.47515 16.5089 7.24073C16.2745 7.00631 15.9565 6.87461 15.625 6.87461Z"
    fill="#004677"
  />
</svg>

  )
}

export default McsIcExportPdf