import React from 'react'

const IcCardUsersIcon = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14ZM12 14C7.58172 14 4 16.6863 4 20M12 14C16.4183 14 20 16.6863 20 20"
    stroke="white"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default IcCardUsersIcon