import React from "react";

export const CustomerSidebarFilterData: any = {
  Services: [
    {
      label: "Door to door (0)",
      value: "door-to-door",
    },
    {
      label: "Door to port (0)",
      value: "door-to-port",
    },
    {
      label: "Port to door (0)",
      value: "port-to-door",
    },
    {
      label: "Port to port (0)",
      value: "port-to-port",
    },
  ],
  "Shipping Mode": [
    {
      label: "Ocean (0)",
      value: "ocean",
    },
    {
      label: "Air (0)",
      value: "air",
    },
    {
      label: "Rail (0)",
      value: "rail",
    },
    {
      label: "Road (0)",
      value: "road",
    },
  ],
  // Carriers: [
  //   {
  //     label: "Maersk (5)",
  //     value: "1",
  //   },
  //   {
  //     label: "CMA-CGM (2)",
  //     value: "2",
  //   },
  //   {
  //     label: "HMM (2)",
  //     value: "3",
  //   },
  //   {
  //     label: "EVERGREEN (0)",
  //     value: "4",
  //   },
  //   {
  //     label: "Hapag Lloyd (34)",
  //     value: "5",
  //   },
  //   {
  //     label: "MSC (1)",
  //     value: "6",
  //   },
  //   {
  //     label: "OOCL (4)",
  //     value: "7",
  //   },
  //   {
  //     label: "ZIM (3)",
  //     value: "8",
  //   },

  //   {
  //     label: "ONE (2)",
  //     value: "9",
  //   },
  // ],
};

export const AdminSidebarFilterData: any = {
  "Shipping Mode": [
    {
      label: "Ocean (0)",
      value: "ocean",
    },
    {
      label: "Air (0)",
      value: "air",
    },
    {
      label: "Rail (0)",
      value: "rail",
    },
    {
      label: "Road (0)",
      value: "road",
    },
  ],
};
