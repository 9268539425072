import React from "react";
import SideBarMainComponent from "./sidebar-main.component";
interface SideBarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  ref?: any;
  setIsSidebarOpen?: any;
}
const SideBarMainContainer = (props: SideBarProps) => {
  return <SideBarMainComponent {...props} />;
};

export default SideBarMainContainer;
