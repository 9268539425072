import React from "react";

const McsIcBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.58243 7.55007C4.33922 7.79565 4.33922 8.20435 4.58243 8.44993L9.90618 13.8258C10.1362 14.0581 10.5 14.0581 10.73 13.8258L11.4176 13.1314C11.6608 12.8859 11.6608 12.4772 11.4177 12.2316L7.22802 8L11.4177 3.76837C11.6608 3.52278 11.6608 3.11412 11.4176 2.86856L10.73 2.17424C10.5 1.94192 10.1362 1.94192 9.90618 2.17424L4.58243 7.55007Z"
        fill="#6673A3"
      />
    </svg>
  );
};

export default McsIcBack;
