import React, { useEffect } from "react";
import AuthLayout from "src/components/layouts/auth-layout";

import SignUpForm from "./sign-up-form/index";
import { useNavigate } from "react-router-dom";
import { TITLES } from "src/types";
import useUser from "src/hooks/useUser";

const CustomerSignUp = () => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/quotations");
    }
  }, [user]);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.SIGNUP;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <AuthLayout>
      <SignUpForm />
    </AuthLayout>
  );
};

export default CustomerSignUp;
