import React from "react";

export default function McsIcPath() {
  return (
    <svg
      width="248"
      height="32"
      viewBox="0 0 248 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3354_1821)">
        <g clipPath="url(#clip1_3354_1821)">
          <path
            d="M35.166 14.1667C35.166 14.6087 35.3416 15.0326 35.6542 15.3452C35.9667 15.6577 36.3907 15.8333 36.8327 15.8333C37.2747 15.8333 37.6986 15.6577 38.0112 15.3452C38.3238 15.0326 38.4993 14.6087 38.4993 14.1667C38.4993 13.7246 38.3238 13.3007 38.0112 12.9882C37.6986 12.6756 37.2747 12.5 36.8327 12.5C36.3907 12.5 35.9667 12.6756 35.6542 12.9882C35.3416 13.3007 35.166 13.7246 35.166 14.1667Z"
            stroke="#667085"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.5 14.1667C43.5 14.6087 43.6756 15.0326 43.9882 15.3452C44.3007 15.6577 44.7246 15.8333 45.1667 15.8333C45.6087 15.8333 46.0326 15.6577 46.3452 15.3452C46.6577 15.0326 46.8333 14.6087 46.8333 14.1667C46.8333 13.7246 46.6577 13.3007 46.3452 12.9882C46.0326 12.6756 45.6087 12.5 45.1667 12.5C44.7246 12.5 44.3007 12.6756 43.9882 12.9882C43.6756 13.3007 43.5 13.7246 43.5 14.1667Z"
            stroke="#667085"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.166 14.1641H33.4993V10.8307M32.666 4.16406H41.8327V14.1641M38.4994 14.1641H43.4994M46.8327 14.1641H48.4994V9.16406M48.4994 9.16406H41.8327M48.4994 9.16406L45.9994 4.9974H41.8327"
            stroke="#667085"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.5 7.5H36.8333"
            stroke="#667085"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip2_3354_1821)">
          <path
            d="M119.166 16.6697C119.355 16.9335 119.606 17.1471 119.896 17.2923C120.187 17.4375 120.508 17.5098 120.833 17.503C121.157 17.5098 121.479 17.4375 121.769 17.2923C122.059 17.1471 122.31 16.9335 122.499 16.6697C122.689 16.406 122.939 16.1923 123.23 16.0471C123.52 15.9019 123.841 15.8296 124.166 15.8364C124.491 15.8296 124.812 15.9019 125.102 16.0471C125.393 16.1923 125.643 16.406 125.833 16.6697C126.022 16.9335 126.273 17.1471 126.563 17.2923C126.853 17.4375 127.175 17.5098 127.499 17.503C127.824 17.5098 128.145 17.4375 128.436 17.2923C128.726 17.1471 128.977 16.9335 129.166 16.6697C129.355 16.406 129.606 16.1923 129.896 16.0471C130.187 15.9019 130.508 15.8296 130.833 15.8364C131.157 15.8296 131.479 15.9019 131.769 16.0471C132.059 16.1923 132.31 16.406 132.499 16.6697C132.689 16.9335 132.939 17.1471 133.23 17.2923C133.52 17.4375 133.841 17.5098 134.166 17.503C134.491 17.5098 134.812 17.4375 135.102 17.2923C135.393 17.1471 135.643 16.9335 135.833 16.6697"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M120.833 15.0026L120 10.8359H135L133.333 14.1693"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M121.666 10.8359V5.83594H128.333L131.666 10.8359"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M123.333 5.83333V2.5H122.5"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M209.625 16.875H220.875"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.093 6.25H207.437L208.999 8.125H211.499L210.522 5.19531C210.492 5.10143 210.483 5.00157 210.499 4.90391C210.514 4.80626 210.552 4.7136 210.61 4.63354C210.668 4.55348 210.744 4.48831 210.832 4.44336C210.92 4.39842 211.017 4.37499 211.116 4.375H212.437L215.874 8.125H220.249C220.912 8.125 221.548 8.38839 222.017 8.85723C222.486 9.32607 222.749 9.96196 222.749 10.625V12.5H208.983C208.446 12.4992 207.924 12.3255 207.494 12.0047C207.063 11.6839 206.747 11.233 206.593 10.7187L205.491 7.05469C205.463 6.96112 205.457 6.86227 205.474 6.76608C205.491 6.66988 205.531 6.57901 205.589 6.50077C205.648 6.42253 205.724 6.35908 205.811 6.31553C205.899 6.27198 205.995 6.24954 206.093 6.25V6.25Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="6"
          y1="26.5"
          x2="239"
          y2="26.5"
          stroke="#333F69"
          strokeDasharray="2 2"
        />
        <circle cx="6" cy="26.5" r="5.5" fill="#D1D5E5" stroke="#808CB7" />
        <circle cx="84.666" cy="26.5" r="5.5" fill="#D1D5E5" stroke="#808CB7" />
        <circle
          cx="163.334"
          cy="26.5"
          r="5.5"
          fill="#D1D5E5"
          stroke="#808CB7"
        />
        <circle cx="242" cy="26.5" r="5.5" fill="#D1D5E5" stroke="#808CB7" />
      </g>
      <defs>
        <clipPath id="clip0_3354_1821">
          <rect width="248" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_3354_1821">
          <rect width="20" height="20" fill="white" transform="translate(31)" />
        </clipPath>
        <clipPath id="clip2_3354_1821">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(117.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
