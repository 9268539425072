import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export const SidebarItemComponent = ({
  val,
  activeClass,
  inactiveClass,
  isLoading,
}: any) => {
  const location = useLocation();

  if (isLoading) {
    return <li className="shimmer mx-16 my-12 h-5"></li>;
  }

  return (
    <li>
      <NavLink
        to={val.route}
        className={`${
          location.pathname.includes(val.route) ? activeClass : inactiveClass
        } relative`}
      >
        {val.Icon}
        <span className={`text-14 font-medium leading-20 text-brand-color`}>
          {val.label}
        </span>
        {location.pathname === val.route && (
          <div className="absolute left-0 h-[20px] w-[4px] rounded-r bg-brand-color"></div>
        )}
      </NavLink>
    </li>
  );
};
