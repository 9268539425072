import React from "react";
import Label from "../label";
import ErrorLabel from "../error-label";
import { ISelectInput } from "./select-input.types";
import { Select } from "antd";
const { Option } = Select;
import "./select-input.css";
import { Icon } from "src/components/atoms/icons";

const SelectInput = ({
  id,
  name,
  label,
  value,
  required = false,
  errorMessage = "",
  showError,
  containerClassName = "theme-form-select",
  onSelect,
  options,
  className = "",
  searchable = false,
  popupClassName = "",
  placeholder = "Select an option",
  disabled,
  suffixIcon,
}: ISelectInput) => {
  const filterOption = (
    input: string,
    option?: { label?: string; value?: string; customerid?: string; customername?: string },
    ) => {
    console.log(((option?.customername||option?.value||option?.label) ?? "ff")?.toLowerCase()?.includes(input?.toLowerCase()))
    if((option?.customername||option?.value||option?.label)==='Add Customer') {
      return true;
    }
    return ((option?.label||option?.value||option?.customername) ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
  }
  return (
    <div
      className={`${containerClassName} ${
        showError && errorMessage ? "select-country-error" : ""
      }`}
    >
      {label && <Label id={id || name} name={label} required={required} />}
      <Select
        style={{ width: "100%" }}
        value={value}
        className={className}
        onSelect={(e) => onSelect(e)}
        showSearch={searchable}
        filterOption={filterOption}
        popupClassName={popupClassName}
        suffixIcon={!suffixIcon ? suffixIcon : <Icon.McsIcDownArrow />}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options?.map((item: any, i: number) => {
          return (
            <Option value={item?.value?.toString()||item?.customerid} key={i} label={item?.label || item?.customername}>
              <div className="flex flex-row">
                <span>{item?.label || item?.customername || item?.value}</span>
              </div>
            </Option>
          );
        })}
      </Select>
      {showError && <ErrorLabel message={errorMessage} />}
    </div>
  );
};

export default SelectInput;
