import React from "react";
import QuotationVerticalStepsComponent from "./quotation-vertical-steps.component";

const QuotationVerticalStepsContainer = ({
  item,
  quotItem,
}: {
  item: any;
  quotItem: any;
}) => {
  return <QuotationVerticalStepsComponent item={item} quotItem={quotItem} />;
};

export default QuotationVerticalStepsContainer;
