import React from 'react'

const McsIcRolesAndRights = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={21}
  height={21}
  viewBox="0 0 21 21"
  fill="none"
>
  <path
    d="M6.25 11.8945C7.97589 11.8945 9.375 10.4954 9.375 8.76953C9.375 7.04364 7.97589 5.64453 6.25 5.64453C4.52411 5.64453 3.125 7.04364 3.125 8.76953C3.125 10.4954 4.52411 11.8945 6.25 11.8945Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.875 6.89453H19.375"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.875 10.6445H19.375"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.75 14.3945H19.375"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M1.40625 15.6446C1.68163 14.5691 2.30713 13.6158 3.18414 12.935C4.06115 12.2543 5.13979 11.8848 6.25 11.8848C7.36021 11.8848 8.43885 12.2543 9.31586 12.935C10.1929 13.6158 10.8184 14.5691 11.0938 15.6446"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
  )
}

export default McsIcRolesAndRights