import React from "react";

const McsIcPolygon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54038 1.78788C8.82577 1.6267 9.17423 1.6267 9.45962 1.78788L16.0221 5.4944C16.3173 5.66113 16.5 5.97477 16.5 6.31485V13.6858C16.5 14.0259 16.3173 14.3395 16.0221 14.5063L9.45962 18.2128C9.17423 18.374 8.82577 18.374 8.54038 18.2128L1.97788 14.5063C1.68268 14.3395 1.5 14.0259 1.5 13.6858V6.31485C1.5 5.97477 1.68268 5.66113 1.97788 5.4944L8.54038 1.78788Z"
        stroke="#004677"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default McsIcPolygon;
