import React from "react";
import FormHeader from "../form-header/form-header.component";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { ISignInForm } from "./sign-in-form.types";
import ErrorLabel from "src/components/atoms/error-label";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";

const SignInForm = ({ formik, error, resetError, loading }: ISignInForm) => {
  return (
    <div>
      <FormHeader
        title="Get immediate quotes, Zero waiting time"
        description="Dive into our modern and user-friendly quoting tool instantly. Shipping made simple, just as it should be."
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-32">
          <TextInput
            name="email"
            type="email"
            label="Email Address"
            placeholder="Enter your email address"
            classes={{ containerClassName: "mb-12", inputClassName: "" }}
            value={formik.values.email}
            showError={Boolean(formik.touched.email)}
            onChange={(e: any) => {
              resetError();
              formik.handleChange(e);
              const email = e.target.value.replace(/\s/g, "");
              email && formik.setFieldValue("email", email);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.email}
            autoFocus={true}
            required={true}
            prefix={<Icon.McsIcEmail />}
          />
          <TextInput
            name="password"
            type="password"
            label="Password"
            placeholder="Enter your password"
            classes={{ containerClassName: "mb-12" }}
            value={formik.values.password}
            showError={Boolean(formik.touched.password)}
            onChange={(e) => {
              resetError();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.password}
            required={true}
            prefix={<Icon.McsIcPasswrod />}
            // this is eye icon
            // suffix={<Icon.McsIcEye />}
          />
          {Boolean(error) && <ErrorLabel message={error} />}
          <Link
            to="/admin/forgot-password"
            className="text-16 font-semibold leading-24 text-gray-800 transition duration-[0.4s]"
          >
            Forgot Password?
          </Link>
        </div>
        <CustomButton
          block={true}
          htmlType="submit"
          variant="primary"
          loading={loading}
        >
          Login
        </CustomButton>
      </form>
    </div>
  );
};

export default SignInForm;
