import React from "react";
import LogoutButtonComponent from "./logout-button.component";
import { useAppDispatch } from "src/store/hook";
import { logout } from "src/store/features/auth/actions";
import { setAuthToken } from "src/store/features/auth";
import { clearToken, showToast } from "src/utils";
import { useNavigate } from "react-router-dom";
import { TOASTR_TYPES } from "../toast-container/types";
import useUser from "src/hooks/useUser";
import { setAuthCusToken } from "src/store/features/auth/customer";

const LogoutButtonContainer = () => {
  const user = useUser();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    if (location.href.includes("admin")) {
      dispatch(setAuthToken(""));
    } else {
      dispatch(setAuthCusToken(""));
    }
    clearToken();
    navigate(location.href.includes("admin") ? "/admin/login" : "/login");
    showToast(TOASTR_TYPES.SUCCESS, "You are logged out successfully");
  };

  return <LogoutButtonComponent handleLogout={handleLogout} />;
};

export default LogoutButtonContainer;
