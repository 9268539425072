import React, { useState } from "react";
import CustomButton from "src/components/atoms/button";
import RadioButonComponent from "src/components/atoms/radio-button";
import TextInput from "src/components/atoms/text-input";
import ContentWrapper from "src/components/layouts/content-wrapper";

const IntegrationComponent = ({
  formik,
  resetError
}:any) => {

  return (
    <ContentWrapper>
      <div className="grid xl:grid-cols-12">
        <div className="col-span-12 xl:col-span-6">
          <form className="flex flex-col gap-1 rounded border border-border p-24" onSubmit={formik.handleSubmit}>
            <div className="border-b border-border pb-24">
              <h2 className="text-24 font-bold leading-32 text-gray-800">
                Integration to connect datasource
              </h2>
              <h3 className="text-14 font-normal leading-20 text-gray-500-base">
                Connect to OneDrive
              </h3>
            </div>
            <TextInput
              name="secret_id"
              type="text"
              label="OneDrive Client ID"
              placeholder="OneDrive Client ID"
              value={formik?.values?.secret_id}
              showError={Boolean(formik.touched.secret_id)}
              onChange={(e) => {
                resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                resetError();
              }}
              errorMessage={formik.errors.secret_id}
              classes={{
                containerClassName: "mb-12 mt-24 custom-input",
                inputClassName: "",
              }}
            />
            <TextInput
              name="secret_key"
              type="text"
              label="OneDrive Client Secret Key"
              placeholder="OneDrive Client Secret Key"
              value={formik?.values?.secret_key}
              showError={Boolean(formik.touched.secret_key)}
              onChange={(e) => {
                resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                resetError();
              }}
              errorMessage={formik.errors.secret_key}
              classes={{
                containerClassName: "mb-12 custom-input",
                inputClassName: "",
              }}
            />
            <div className="mt-8">
              <CustomButton block={false} className="px-16 " htmlType="submit" onClick={() => formik.submit}>
                Save
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default IntegrationComponent;
