import parsePhoneNumberFromString from "libphonenumber-js";
import { CONSTANTS } from "src/constants";
import * as yup from "yup";

export const customerUserSchema = yup.object().shape({
  name: yup.string().trim().required("Please enter name"),
  role: yup.string().trim().required("Please select role"),
  customerid: yup.string().trim().required("Please select customer"),
  phoneNumber: yup.string().trim().required("Please enter phone number").test('phone-number', 'Invalid phone number', (value) => {
    if (!value) return true; // Allow empty value
    try {
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber && phoneNumber.isValid();
    } catch (error) {
      return false;
    }
  }),
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
});
