import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import DatatableComponent from "./datatable.component";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { useSearchParams } from "react-router-dom";
import useLoadingError from "src/hooks/useLoadingError";
import {
  useAddCustomerUserMutation,
  useUpdateCustomerUserMutation,
} from "src/services/api-service/user";
import { customerUserSchema } from "src/validations/customer-user-schema";
import useTitleCase from "src/hooks/useTitleCase";

const DatatableContainer = () => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [addItem] = useAddCustomerUserMutation<any>();
  const [updateItem] = useUpdateCustomerUserMutation<any>();
  const [id, setId] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const { titleCasedString, handleInputChange } = useTitleCase();
  const [url] = useSearchParams();
  const customerId = url?.get("id");

  const formik = useFormik({
    initialValues: {
      name: "",
      role: "user",
      customerid: customerId,
      phoneNumber: "",
      email: "",
    },
    validationSchema: customerUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      setReFetchData(false);
      setCloseModal(false);
      startLoading();
      let APIHIT;
      !id &&
        (APIHIT = addItem({
          ...values,
          name: titleCasedString["name"] || values?.name,
        }));
      id &&
        (APIHIT = updateItem({
          ...values,
          customerid: values?.customerid,
          name: titleCasedString["name"] || values?.name,
          id,
        }));
      setId("");
      APIHIT &&
        APIHIT.unwrap()
          .then((data) => {
            showToast(
              TOASTR_TYPES.SUCCESS,
              "You successfully added a user" || data?.message,
            );
            setCloseModal(true);
            setTimeout(() => {
              setReFetchData(true);
            }, 100);
          })
          .catch(() => {
            // showToast(TOASTR_TYPES.ERROR, err?.message);
          })
          .finally(() => {
            stopLoading();
          });
    },
  });

  useEffect(() => {
    handleInputChange(formik.values.name, "name");
  }, [formik.values.name]);

  const getCurrentId = (id: string) => {
    setCloseModal(false);
    setId(id);
  };

  const setCloseModalState = (value: boolean) => {
    setCloseModal(value);
  };

  return (
    <DatatableComponent
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      getId={getCurrentId}
      closeModal={closeModal}
      setCloseModal={setCloseModalState}
      reFetchData={reFetchData}
    />
  );
};

export default DatatableContainer;
