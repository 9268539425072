import { CONSTANTS } from "src/constants";
import * as yup from "yup";

export const adminUserSchema = (isEdit: boolean) => {
  return yup.object().shape({
    role: yup.string().trim().required("Role is required"),
    name: yup.string().trim().required("Please enter name"),
    email: yup
      .string()
      .required("Please enter email")
      .email("Invalid email address")
      .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
    ...(!isEdit
      ? {
          password: yup
            .string()
            // .trim()
            .required("Please enter password")
            .test(
              "no-leading-trailing-space",
              "Password should not start or end with a space",
              (value) => !/^\s|\s$/.test(value),
            )
            .min(8, "Password must be at least 8 characters")
            .matches(
              /^(?=.*[A-Z])(?=.*\d).+$/,
              "Password must contain at least one uppercase letter and one numeric value",
            ),
        }
      : {}),
  });
};
