import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Select } from "antd";
import { Icon } from "../icons";
import { ENDPOINTS } from "src/store/endpoints";
import { useAppDispatch } from "src/store/hook";
import { setLoading } from "src/store/features/alerts";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import useUser from "src/hooks/useUser";

interface StatusEditorProps {
  value: string;
  id: any;
}

const StatusSelectComponent = ({ value, id }: StatusEditorProps) => {
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const updateEndpoint = ENDPOINTS.ADMIN.UPDATE_STATUS;

  const user = useUser();
  const quotationId = id;

  const updateStatusById = async (status: string) => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const updateUrl = baseUrl + "/" + updateEndpoint + quotationId;

      const response = await fetch(updateUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      });
      if (response.ok) {
        showToast(TOASTR_TYPES.SUCCESS, `You successfully updated the status`);
      } else {
        console.error(
          "Error updating status. Server returned:",
          response.status,
        );
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChange = (status: string) => {
    updateStatusById(status);
  };

  const statusArray = [
    {
      value: "booked",
      label: (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
              Booked
            </div>
          </div>
        </>
      ),
    },
    {
      value: "pending",
      label: (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl bg-purple-50 px-8 py-2 text-12 font-medium leading-18 text-purple-700">
              Pending
            </div>
          </div>
        </>
      ),
    },
    {
      value: "failed",
      label: (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
              Failed
            </div>
          </div>
        </>
      ),
    },
    {
      value: "complete",
      label: (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl bg-sky-50 px-8 py-2 text-12 font-medium leading-18 text-blue-800">
              Complete
            </div>
          </div>
        </>
      ),
    },
  ];

  const defaultOptionValues = statusArray;

  return (
    <Select
      defaultValue={value}
      className="select-status w-[99px]"
      popupClassName="status-tag-dropdown"
      onChange={handleChange}
      options={defaultOptionValues}
      suffixIcon={<Icon.McsIcBrandColorChevronDown />}
    />
  );
};

export default StatusSelectComponent;
