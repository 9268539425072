import React from "react";

const McsIcQuestion = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.1445C14.1421 18.1445 17.5 14.7867 17.5 10.6445C17.5 6.5024 14.1421 3.14453 10 3.14453C5.85786 3.14453 2.5 6.5024 2.5 10.6445C2.5 14.7867 5.85786 18.1445 10 18.1445Z"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1875 14.707C10.1875 14.8106 10.1036 14.8945 10 14.8945C9.89645 14.8945 9.8125 14.8106 9.8125 14.707C9.8125 14.6035 9.89645 14.5195 10 14.5195C10.1036 14.5195 10.1875 14.6035 10.1875 14.707Z"
        fill="#291770"
        stroke="#291770"
        strokeWidth="1.5"
      />
      <path
        d="M10 11.8945V11.2695C10.4326 11.2695 10.8556 11.1412 11.2153 10.9009C11.575 10.6605 11.8554 10.3189 12.021 9.91915C12.1866 9.51944 12.2299 9.07961 12.1455 8.65527C12.0611 8.23094 11.8527 7.84116 11.5468 7.53524C11.2409 7.22931 10.8511 7.02097 10.4268 6.93656C10.0024 6.85216 9.56259 6.89548 9.16288 7.06105C8.76317 7.22661 8.42153 7.50699 8.18116 7.86672C7.94079 8.22645 7.8125 8.64939 7.8125 9.08203"
        stroke="#291770"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcQuestion;
