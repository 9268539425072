import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";

export const columns = (
  { handleOpenDeleteModal }: any,
  { handleOpenEditModal }: any,
) => {
  return [
    {
      headerName: "Group Name",
      field: "name",
      width: 100,
      headerClass: " cursor-pointer", // Add this line
      flex: 1,
      rowDrag: true,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 311,
      cellRenderer: ({ value, data }: any) => {
        let className = "";
        className += "block cursor-pointer";
        return (
          <div className={className}>
            <Link to={`cost-groups?id=${data?.id}&name=${data?.name}`}>
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "Customer Type",
      field: "customerType",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      cellRenderer: ({ value, data }: any) => {
        let className = "";
        className += "block cursor-pointer";
        return (
          <div className={className}>
            <Link to={`cost-groups?id=${data?.id}&name=${data?.name}`}>
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "Margin %",
      field: "marginPercentage",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      innerHeight: 100,
      minWidth: 311,
      cellRenderer: ({ value, data }: any) => {
        let className = "";
        className += "block cursor-pointer";
        return (
          <div className={className}>
            <Link to={`cost-groups?id=${data?.id}&name=${data?.name}`}>
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "# of Customers",
      field: "totalCustomers",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 130,
      resizable: true,
      cellRenderer: ({ value, data }: any) => {
        let className = "";
        className += "block cursor-pointer";
        return (
          <div className={className}>
            <Link to={`cost-groups?id=${data?.id}&name=${data?.name}`}>
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "name",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: ({ value, data }: any) => (
        <div className="flex h-full items-center justify-end">
          <button
            className="cursor-pointer p-8"
            onClick={() => handleOpenEditModal(data.id)}
          >
            <Icon.McsIcEdit />
          </button>
          <button
            className="cursor-pointer p-8"
            disabled={value === "Default"}
            onClick={() => handleOpenDeleteModal(data.id)}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
