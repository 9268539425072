import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Customer Management APIs */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getUsersList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.USERS_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getUserById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.USER_BY_ID + `/${data.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    addUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.ADD_USER,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        return {
          url: ENDPOINTS.ADMIN.UPDATE_USER + `/${body?.id}`,
          method: "PUT",
          body: filteredData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_USER + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Customer User Management APIs */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getCustomerUsersList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(
            ([key, value]) => value !== "" && key !== "id" && key !== "name",
          ),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url:
            ENDPOINTS.ADMIN.CUSTOMER_USERS_LIST +
            "/" +
            body?.id +
            `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getCustomerUserById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.CUSTOMER_USER_BY_ID + `/${data.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    addCustomerUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.ADD_CUSTOMER_USER,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateCustomerUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        return {
          url: ENDPOINTS.ADMIN.UPDATE_CUSTOMER_USER + `/update/${body?.id}`,
          method: "PUT",
          body: filteredData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteCustomerUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_CUSTOMER_USER + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getQuotationsById: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(
            ([key, value]) => value !== "" && key !== "id" && key !== "name",
          ),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url:
            ENDPOINTS.ADMIN.CUSTOMER_QUOTATIONS +
            `/${body.id}/quotations` +
            `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getBookingsById: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(
            ([key, value]) => value !== "" && key !== "id" && key !== "name",
          ),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url:
            ENDPOINTS.ADMIN.CUSTOMER_BOOKINGS +
            `/${body.id}/booked-quotations` +
            `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getAllQuotationsByUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: `${ENDPOINTS.ADMIN.CUSTOMER_QUOTATIONS_ALL}/${body?.id}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    downloadAllCustomers: build.mutation<IResponse, void>({
      query: () => ({
        url: ENDPOINTS.ADMIN.EXPORT_ALL_CUSTOMERS,
        method: "GET",
        responseType: "json",  // Ensure the response is treated as JSON
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (response: IResponse) =>
        transFormResponse(response, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUserByIdMutation,
  useGetUsersListMutation,
  useUpdateUserMutation,
  useAddCustomerUserMutation,
  useDeleteCustomerUserMutation,
  useGetCustomerUserByIdMutation,
  useGetCustomerUsersListMutation,
  useUpdateCustomerUserMutation,
  useGetBookingsByIdMutation,
  useGetQuotationsByIdMutation,
  useGetAllQuotationsByUserMutation,
  useDownloadAllCustomersMutation
} = APIS;
