import React from "react";
import SignInForm from "./sign-in-form.component";
import { useFormik } from "formik";
import useLoadingError from "src/hooks/useLoadingError";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { loginSchema } from "src/validations/login-schema";
import { useCustomerLoginMutation } from "src/services/api-service/customer-auth";
import { clearToken, persistToken, showToast } from "src/utils";
import { setAuthCusToken, setCusUser } from "src/store/features/auth/customer";
import { useNavigate } from "react-router-dom";
import { IResponse } from "src/services/types";

const SignInFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [login] = useCustomerLoginMutation<any>();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      startLoading();
      login(values)
        .unwrap()
        .then((data) => {
          persistToken(data?.data?.access_token);
          dispatch(setCusUser(data?.data));
          dispatch(setAuthCusToken(data?.data?.access_token));
          // getCurrentUser({});

          navigate("/quotations");
        })
        .catch((err: IResponse) => {
          console.error(err);
          // showToast(TOASTR_TYPES.ERROR, err?.message);
        })
        .finally(() => {
          stopLoading();
        });
    },
  });

  return (
    <SignInForm
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
    />
  );
};

export default SignInFormContainer;
