import React from "react";

const McsIcSearchWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.4997 17.5007L13.1688 13.1698M13.1688 13.1698C14.341 11.9977 14.9995 10.4079 14.9995 8.75023C14.9995 7.09257 14.341 5.5028 13.1688 4.33065C11.9967 3.1585 10.4069 2.5 8.74926 2.5C7.09159 2.5 5.50182 3.1585 4.32967 4.33065C3.15753 5.5028 2.49902 7.09257 2.49902 8.75023C2.49902 10.4079 3.15753 11.9977 4.32967 13.1698C5.50182 14.342 7.09159 15.0005 8.74926 15.0005C10.4069 15.0005 11.9967 14.342 13.1688 13.1698Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default McsIcSearchWhite;
