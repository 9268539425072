import React from "react";
import { Link } from "react-router-dom";

const SideBarHeaderCompoent = () => {
  const isAdmin = window?.location?.pathname?.includes("/admin");
  return (
    <div className="flex w-full flex-col justify-center md:justify-start">
      <div className="block px-14 pt-14 pb-8">
        <Link to={(isAdmin?'/admin':'')+"/quotations?tab=Generate Quotations"} className="inline-block">
          <img src="/assets/images/mcs-logo-1.svg" alt="Brand Logo" />
        </Link>
      </div>
    </div>
  );
};

export default SideBarHeaderCompoent;
