import React from 'react'

const McsIcSearch = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M17.5 17.5007L13.1692 13.1698M13.1692 13.1698C14.3414 11.9977 14.9999 10.4079 14.9999 8.75023C14.9999 7.09257 14.3414 5.5028 13.1692 4.33065C11.9971 3.1585 10.4073 2.5 8.74962 2.5C7.09196 2.5 5.50219 3.1585 4.33004 4.33065C3.15789 5.5028 2.49939 7.09257 2.49939 8.75023C2.49939 10.4079 3.15789 11.9977 4.33004 13.1698C5.50219 14.342 7.09196 15.0005 8.74962 15.0005C10.4073 15.0005 11.9971 14.342 13.1692 13.1698Z"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcSearch