import React from 'react'

const McsIcProfileDropdownUserManagement = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={16}
  height={16}
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10Z"
    stroke="#344054"
    strokeWidth="1.5"
    strokeMiterlimit={10}
  />
  <path
    d="M3.98828 12.4629C4.36447 11.7219 4.93851 11.0996 5.64674 10.6648C6.35498 10.2301 7.16977 10 8.00078 10C8.83179 10 9.64658 10.2301 10.3548 10.6648C11.0631 11.0996 11.6371 11.7219 12.0133 12.4629"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.5 4.5C13.0523 4.5 13.5 4.05228 13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5C11.9477 2.5 11.5 2.94772 11.5 3.5C11.5 4.05228 11.9477 4.5 12.5 4.5Z"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.5 2.5V1.75"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.632 3L10.9883 2.625"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.632 4L10.9883 4.375"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.5 4.5V5.25"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.3691 4L14.0129 4.375"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.3691 3L14.0129 2.625"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.9563 7.28136C13.9866 7.51972 14.0012 7.75982 14 8.00011C14 9.1868 13.6481 10.3468 12.9888 11.3335C12.3295 12.3202 11.3925 13.0893 10.2961 13.5434C9.19975 13.9975 7.99335 14.1163 6.82946 13.8848C5.66558 13.6533 4.59648 13.0819 3.75736 12.2427C2.91825 11.4036 2.3468 10.3345 2.11529 9.17065C1.88378 8.00676 2.0026 6.80036 2.45673 5.70401C2.91085 4.60765 3.67989 3.67058 4.66658 3.01129C5.65328 2.352 6.81331 2.00011 8 2.00011C8.18572 1.99894 8.37138 2.00728 8.55625 2.02511"
    stroke="#344054"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcProfileDropdownUserManagement