import { IQuotationCard } from "../quotation-card/types";

export interface IGenerateQuotation {
  quotationTypes: IQuotationCard[];
  serviceTypes: IQuotationCard[];
  handleCardClick: (id:any, type:any) => void;
  clicked: boolean;
}

export interface Country {
  name: string;
  code: string;
}

export interface Port {
  name: string;
  locode: string;
  aliases?: string; // If aliases are optional
}
  
export const quotationForOptions = [
  {
    label: "Customer",
    value: "1",
  },
  {
    label: "Continue without customer",
    value: "2",
  },
];
