import React from 'react'

const McsIcInvalid = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5239_15508)">
      <path
        d="M8 1.334a6.667 6.667 0 016.667 6.667 6.667 6.667 0 01-13.33.216L1.333 8l.003-.187A6.666 6.666 0 018 1.334zm.007 8.667l-.085.004a.667.667 0 000 1.324l.078.005.085-.005a.667.667 0 000-1.324l-.078-.004zM8 4.667a.667.667 0 00-.662.589l-.005.078v2.667l.005.078a.667.667 0 001.324 0L8.667 8V5.334l-.005-.078A.667.667 0 008 4.667z"
        fill="#F04438"
      />
    </g>
    <defs>
      <clipPath id="clip0_5239_15508">
        <path fill="#fff" d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>

  )
}

export default McsIcInvalid