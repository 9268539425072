import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./custom-datatable.css";
import { IDataTable } from "../types";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
const DataTableComponent = ({
  columns,
  data,
  description,
  header,
  onCellClicked,
  footer,
  onRowClicked,
  rowDraggable = false,
  setPagination,
}: Partial<IDataTable>) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const overlayLoadingTemplate = "<span></span>";
  const overlayNoDataTemplate =
    '<span class="text-gray-600 font-inter text-sm font-semibold leading-6 tracking-normal text-center">Sorry, there is no data available</span>';

  const [overlayNoRowsTemplate, setOverlayNoRowsTemplate] = useState(
    overlayLoadingTemplate,
  );

  const onGridReady = useCallback((params: any) => {
    // console.log("ready")
  }, []);
  const headerHeight = 36;
  const [rowHeight, setRowHeight] = useState(44);

  useEffect(() => {
    setOverlayNoRowsTemplate(overlayNoDataTemplate);
  }, []);

  useEffect(() => {
    if (description?.toLowerCase().includes("customer")) {
      setRowHeight(48);
    }
  }, [description]);

  return (
    <>
      {header}
      {
        <>
          <div style={containerStyle} className="mb-10">
            <div style={gridStyle} className={"ag-theme-quartz"}>
              <AgGridReact
                rowData={data}
                columnDefs={columns}
                overlayLoadingTemplate={overlayLoadingTemplate}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                rowDragManaged={rowDraggable}
                rowDragEntireRow={rowDraggable}
                onGridReady={onGridReady}
                onRowClicked={onRowClicked}
                onCellClicked={onCellClicked}
                headerHeight={headerHeight}
                rowHeight={rowHeight}
                loadingCellRenderer={true}
                suppressRowDrag={true}
                // suppressRowTransform={true}
                onSortChanged={(params: any) => {
                  console.log(params.columnApi.getColumnState(), "column api");
                  const columns = params.columnApi.getColumnState();
                  const sortedColumn = columns.find((col: any) => col.sort);

                  setPagination((prev: any) => ({
                    ...prev,
                    sorting: sortedColumn
                      ? {
                          columnName: sortedColumn?.colId,
                          sort: sortedColumn?.sort,
                        }
                      : {},
                  }));

                  console.log(params, "sorting");
                }}
                gridOptions={{
                  domLayout: "autoHeight", //remove scroll
                  suppressCellFocus: true, //to remove cell focus
                }}
              />
            </div>
          </div>
          {footer}
        </>
      }
    </>
  );
};

export default DataTableComponent;
