import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import McsCustomTabs from "src/components/molecules/mcs-custom-tabs";
import Pricing from "../pricings";
import Trucking from "../trucking";
import LocalCharges from "../local-charges";

const tabs = [
  {
    label: "Pricings",
    children: <Pricing />,
  },
  {
    label: "Local Charges",
    children: <LocalCharges />,
  },
  {
    label: "Trucking",
    children: <Trucking />,
  },
];

const PricesTabsComponent = ({ tabChanged }: any) => {
  const [activeKey, setActiveKey] = useState(sessionStorage.getItem("defaultActiveKey_Prices")||"0");

  const handleChange = (index: any) => {
    setActiveKey(index.toString());
    tabChanged(tabs[index].label);
    sessionStorage.setItem("defaultActiveKey_Prices", index);
  };

  useEffect(() => {
    const storedKey = sessionStorage.getItem("defaultActiveKey_Prices");
    if (storedKey !== null) {
      setActiveKey(storedKey);
    }
  }, []);

  return (
    <>
      <McsCustomTabs
        tabs={tabs}
        defaultActiveKey={activeKey}
        onChange={handleChange}
      />
    </>
  );
};

export default PricesTabsComponent;
