import { userCusSelector } from "src/store/features/auth/customer/selectors";
import { userSelector } from "src/store/features/auth/selectors";
import { useAppSelector } from "src/store/hook";

export default function useUser() {
  const adminUser = useAppSelector(userSelector);
  const cusUser = useAppSelector(userCusSelector);

  const user = location.href.includes("admin") ? adminUser : cusUser;

  return user;
}
