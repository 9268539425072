import React from 'react'

const McsIcDownload = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.5 13.75v1.875A1.875 1.875 0 004.375 17.5h11.25a1.875 1.875 0 001.875-1.875V13.75M13.75 10L10 13.75m0 0L6.25 10M10 13.75V2.5"
      stroke="#2F80ED"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  
  )
}

export default McsIcDownload