import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Customer Auth APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    customerLogin: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("email", body?.email);
        formData.append("password", body?.password?.trim());

        return {
          url: ENDPOINTS.CUSTOMER.LOGIN,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    customerSignUp: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("email", body?.email);
        formData.append("name", body?.name);
        formData.append("password", body?.password?.trim());
        formData.append("password_confirmation", body?.password?.trim()); // Might delete later. Backend need right now.

        return {
          url: ENDPOINTS.CUSTOMER.SIGNUP,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    customerForgotPassword: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("email", body?.email);

        return {
          url: ENDPOINTS.CUSTOMER.FORGOT_PASSWORD,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    customerVerifyRecaptcha: build.mutation<
      IResponse,
      Partial<{ token: string }>
    >({
      query: (body) => ({
        url: ENDPOINTS.CUSTOMER.VERIFY_RECAPTCHA,
        method: "POST",
        body,
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    customerVerifyOtp: build.mutation<
      IResponse,
      Partial<{ code: any; token: any }>
    >({
      query: (body) => {
        const formData = new FormData();
        formData.append("otp", body?.code);
        formData.append("token", body?.token);

        return {
          url: ENDPOINTS.CUSTOMER.VERIFY_OTP,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    customerResetPassword: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("token", body?.token);
        formData.append("password", body?.password?.trim());

        return {
          url: ENDPOINTS.CUSTOMER.RESET_PASSWORD,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});

export const {
  useCustomerLoginMutation,
  useCustomerSignUpMutation,
  useCustomerForgotPasswordMutation,
  useCustomerVerifyRecaptchaMutation,
  useCustomerVerifyOtpMutation,
  useCustomerResetPasswordMutation,
} = APIS;
