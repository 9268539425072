import React, { useEffect, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import ConnectDatabaseContainer from "./connect-database.container";
import { Link, useSearchParams } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";
import CustomLink from "src/components/atoms/custom-link";
import ContentWrapper from "src/components/layouts/content-wrapper";
import ContentHeader from "src/components/layouts/content-header";
import { IBreadcrumbItem, TITLES } from "src/types";

const ConnectDatabase = () => {
  const [url] = useSearchParams();
  const type = url?.get("type") || "";
  const tabName = url?.get("tab") || "";
  const currentId = url?.get("id");
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Prices & Rates",
    },
    {
      title: type && (type === "local-charge" ? "Local Charges" : type),
    },
    {
      title: (currentId ? "Edit" : "Connect") + " datasource",
    },
  ];
  const [data, setData] = useState(breadcrumbsData);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.PRICES;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
    let tab = "0";
    if (type == "pricing") {
      tab = "0";
    } else if (type == "trucking") {
      tab = "2";
    } else {
      tab = "1";
    }
    sessionStorage.setItem("defaultActiveKey_Prices", tab);
  }, []);

  return (
    <PanelLayout breadcrumbsData={data}>
      <>
        <div className="pt-24">
          <CustomLink href={""}>
            Back to Prices and Rates
            {/* {type && (type === "local-charge" ? "Local Charges" : type)} */}
          </CustomLink>
        </div>
        <ContentWrapper>
          <ConnectDatabaseContainer />
        </ContentWrapper>
      </>
    </PanelLayout>
  );
};

export default ConnectDatabase;
