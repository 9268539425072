import { useEffect, useState } from "react";

function useFormattedDate(dateString: string) {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    function formatDate(dateString: string) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Convert to Netherlands time
      const netherlandsDate = new Date(dateString);
      netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

      // Extract components for formatting
      const month = months[netherlandsDate.getMonth()];
      const day = netherlandsDate.getDate();
      const year = netherlandsDate.getFullYear();
      const hours = netherlandsDate.getHours() + 1;
      const minutes = netherlandsDate.getMinutes();
      const seconds = netherlandsDate.getSeconds();
      const period = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Add leading zeros to minutes and seconds if they are single digits
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

      return dateString
        ? `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
        : "";
    }

    setFormattedDate(formatDate(dateString));
  }, [dateString]);
  return formattedDate;
}

export default useFormattedDate;
