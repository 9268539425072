import { useState } from 'react';

const useTitleCase = () => {
  const [titleCasedString, setTitleCasedString] = useState<any>({});

  const convertToTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleInputChange = (inputString: string, field: string) => {
    setTitleCasedString((prev:any) => ({
      ...prev,
      [field]: convertToTitleCase(inputString)
    }));
  };

  return { titleCasedString, handleInputChange };
};

export default useTitleCase;
