import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  setFilteredQuotations,
  setQuotationFilters,
  setSelectedQuotations,
} from "src/store/features/quotations";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { Skeleton } from 'antd';

const SidebarFiltersComponent = ({ title, values }: any) => {
  const quotationsData = useAppSelector(quotationsSelector);
  const dispatch = useAppDispatch();

  const handleChange = (e: CheckboxChangeEvent) => {
    const value: string = e.target.value;
    const checked: boolean = e.target.checked;
    const filters: string[] = [...quotationsData.quotationFilters]; // Avoid unnecessary copying

    if (checked) {
      filters.push(value); // Add the value to filters if checked
    } else {
      const index = filters.indexOf(value);
      if (index !== -1) {
        filters.splice(index, 1); // Remove the value from filters if unchecked
      }
    }

    const filteredQuotations =
      filters.length !== 0
        ? quotationsData.quotations.filter((item: any) =>
            filters.includes(item?.mode_of_transport),
          )
        : quotationsData.quotations;

    dispatch(setSelectedQuotations([]));
    dispatch(setFilteredQuotations(filteredQuotations));
    dispatch(setQuotationFilters(filters));
  };

  return (
    <>
      <div className="flex flex-col gap-y-3  px-12 py-12">
        <h4 className="text-16 font-semibold leading-24 text-primary-600">
          {title}
        </h4>
        {values?.map((e: any, idx: any) => {
          return !e ? (
              <Skeleton.Button  key={idx} size="small" block={true} active={true}/>
          ) : (
            <div className="skeleton flex items-center gap-x-3" key={e.value}>
              <Checkbox
                id={`label-${e.value}`}
                onChange={handleChange}
                value={e.value}
                checked={quotationsData.quotationFilters.includes(e.value)}
              />
              <label
                htmlFor={`label-${e.value}`}
                className="cursor-pointer text-14 font-medium leading-20 text-primary-600"
              >
                {e.label}
              </label>
            </div>
          );
        })}
      </div>
      <hr className="h-[1px] w-full bg-primary-100"></hr>
    </>
  );
};

export default SidebarFiltersComponent;
