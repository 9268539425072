export interface IBreadcrumbItem {
  title: string;
  hidden?: boolean;
  href?: string;
  isDisabled?: boolean;
}

export enum VARIANTS {
  "PRIMARY" = "primary",
  "SECONDARY" = "secondary",
}

export const TITLES = {
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  FORGOT_PASSWORD: "Forgot Password",
  RESET_PASSWORD: "Reset Password",
  VERIFY_OTP: "Verify OTP",
  QUOTATIONS: "Quotations",
  QUOTATION_HISTORY: "Quotation History",
  MARGIN_GROUPS: "Margin Groups",
  PRICES: "Prices & Rates",
  ADMIN_USER_MANAGEMENT: "Admin User Management",
  USER_MANAGEMENT: "Customer Management",
  SETTINGS: "Settings",
  BOOKINGS: "Bookings",
  BOOKING_DETAILS: "Booking Details",
  HELP_SUPPORT: "Help & Support",
  FAQ: "Frequently Asked Questions",
  CONTACT: "Still need help? Contact us directly!",
};

export const MAILING_ADDRESS = process.env.MAILING_ADDRESS ?? "lcl@marinair.gr";

export type TODO = any;
