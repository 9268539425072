import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({

    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Margin Groups APIs */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getMarginGroups: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.MARGIN_GROUPS_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getCustomerNames: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.USER_NAMES_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getMarginGroupById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.MARGIN_GROUP_BY_ID + `/${data.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    addMarginGroup: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.ADD_MARGIN_GROUP,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateMarginGroup: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.UPDATE_MARGIN_GROUP + `/${body?.id}`,
          method: "PUT",
          body: filteredData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteMarginGroup: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_MARGIN_GROUP + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Cost Groups APIs */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getCostGroups: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => (value !== "")&&(key !== "id")),
        );
        const queryString = new URLSearchParams(filteredParams).toString();
        
        return {
          url: ENDPOINTS.ADMIN.COST_GROUPS_LIST+'/'+body?.id+'/customers'+`?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    addCostGroup: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.ADD_COST_GROUP+'/'+body?.id+'/add-customers',
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteCostGroup: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => (value !== "")),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.DELETE_COST_GROUP+'/'+body?.groupId+'/customers/'+body?.customerId+'?'+queryString,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useAddMarginGroupMutation,
  useDeleteMarginGroupMutation,
  useGetMarginGroupsMutation,
  useUpdateMarginGroupMutation,
  useAddCostGroupMutation,
  useDeleteCostGroupMutation,
  useGetCostGroupsMutation,
  useGetMarginGroupByIdMutation,
  useGetCustomerNamesMutation
} = APIS;
