import React, { useEffect, useState } from "react";
import CustomSelect from "src/components/atoms/custom-select";
import CustomSwitch from "src/components/atoms/custom-switch";
import { Icon } from "src/components/atoms/icons";
import TextInput from "src/components/atoms/text-input";
import { VARIANTS } from "src/types";
import { ICargoItem } from "./types";
import { CONSTANTS } from "src/constants";
import { useQuotationsContext } from "src/context/quotations-context";
import { checkBooleanOptions, extractNumbers } from "src/utils";
import { convertVolume, generateErrorMessage } from "src/helpers";

const CargoItemComponent = ({
  unit,
  id,
  item: itemVal,
  totalDimensions = false,
}: ICargoItem) => {
  const { formikQuotation, removeCargoItem } = useQuotationsContext();

  const LengthLimitation = formikQuotation?.values?.is_cm ? 510 : 200;
  const WidthLimitation = formikQuotation?.values?.is_cm ? 231 : 90;
  const HeightLimitation = formikQuotation?.values?.is_cm ? 255 : 100.4;

  const VolumeLimitation = formikQuotation?.values?.is_cm ? 40 : 1412.59;
  const WeightLimitation = formikQuotation?.values?.is_cm ? 20000 : 44092.452;

  const [errors, setErrors] = useState({
    volume: false,
    weight: false,
    quantity: false,
    length: false,
    width: false,
    height: false,
  });

  useEffect(() => {
    if (formikQuotation?.values?.total_cargo_dimensions) {
      const itemVal = formikQuotation?.values?.cargo_item_specs[0];
      setErrors({
        ...errors,
        volume: itemVal?.volume <= 0 || itemVal?.volume > VolumeLimitation,
        weight: itemVal?.weight <= 0 || itemVal?.weight > WeightLimitation,
      });
    } else {
      setErrors({
        ...errors,
        weight: false,
      });
    }
  }, [
    formikQuotation?.values?.total_cargo_dimensions,
    formikQuotation?.values?.cargo_item_specs,
  ]);

  // useEffect(() => {
  //   const keys: any[] = Object.keys(itemVal);
  //   keys.forEach((field) => {
  //     updateCargoItemValue(
  //       id,
  //       field,
  //       formikQuotation?.values?.cargo_item_specs[id][field],
  //     );
  //   });
  // }, []);

  const clearItems = () => {
    const items: any[] = formikQuotation.values.cargo_item_specs;
    if (formikQuotation.values.total_cargo_dimensions) {
      items.map((item) => {
        return {
          volume: item.volume,
          weight: item.weight,
          stackable: !!item.stackable,
        };
      });
    } else {
      items.map((item) => {
        return {
          volume: item.volume,
          weight: item.weight,
          stackable: !!item.stackable,
          cm_kg: item.cm_kg || 1,
          colli_type: item.colli_type || "Pallet",
          quantity: item.quantity || 0,
          length: item.length || 0,
          width: item.width || 0,
          height: item.height || 0,
        };
      });
    }
    const keys: any[] = Object.keys(itemVal);
    keys.forEach((field) => {
      updateCargoItemValue(
        id,
        field,
        formikQuotation?.values?.cargo_item_specs[id][field],
      );
    });
  };

  const updateCargoItemValue = (index: number, field: string, value: any) => {
    const items: any[] = JSON.parse(
      JSON.stringify(formikQuotation.values.cargo_item_specs),
    );

    if (!items?.length || !items[index]) return;

    items[index][field] = value;

    if (field === "stackable") {
      items[index][field] = checkBooleanOptions(value);
      !value && (items[index]["height"] = HeightLimitation);
      value && (items[index]["height"] = 0);
    }

    if (
      field != "volume" &&
      items[index].height &&
      items[index].width &&
      items[index].length
    ) {
      items[index].volume =
        items[index].height * items[index].width * items[index].length;
    }

    formikQuotation.setFieldValue("cargo_item_specs", items);
  };

  // useEffect(() => {
  //   clearItems();
  // }, [formikQuotation.values.total_cargo_dimensions]);

  return (
    <div className="flex items-start justify-between lg:gap-2">
      <div className="flex w-full flex-col gap-4 rounded-[10px] border border-primary-100 bg-primary-25 px-24 py-12">
        {!formikQuotation.values.total_cargo_dimensions && (
          <div className="flex items-center justify-between">
            <span className="text-18 font-semibold leading-28">{id + 1}.</span>
            <div className="flex items-center">
              <CustomSwitch
                label="Stackable"
                variant={VARIANTS.SECONDARY}
                size="small"
                checked={itemVal.stackable}
                onChange={(e: any) => {
                  updateCargoItemValue(id, "stackable", e);
                }}
              />
            </div>
          </div>
        )}
        {formikQuotation.values.total_cargo_dimensions ? (
          <div className="grid grid-cols-3 gap-5 ">
            <TextInput
              name="volume"
              type="text"
              label="Volume"
              required={true}
              placeholder="0.000"
              value={itemVal.volume || ""} //Number.parseFloat(itemVal.volume).toFixed(2)
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({
                    ...errors,
                    volume:
                      e.target.value <= 0 || e.target.value > VolumeLimitation,
                  });
                  updateCargoItemValue(
                    id,
                    "volume",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  volume:
                    itemVal?.volume <= 0 || itemVal?.volume > VolumeLimitation,
                })
              }
              showError={errors.volume}
              errorMessage={generateErrorMessage(
                "volume",
                itemVal.volume > VolumeLimitation,
                `${VolumeLimitation} ${formikQuotation.values.is_cm ? "CBM" : "CBF"}`,
              )}
              suffix={CONSTANTS.UNITS.Volume[unit.NAME]}
            />
            <div>
              <TextInput
                name="weight"
                type="text"
                label="Weight"
                required={true}
                placeholder="0.00"
                value={Boolean(+itemVal.weight) ? itemVal.weight : ""}
                onChange={(e: any) => {
                  if (e?.target?.value || e?.target?.value >= 0) {
                    setErrors({
                      ...errors,
                      weight:
                        e.target.value <= 0 ||
                        e.target.value > WeightLimitation,
                    });
                    updateCargoItemValue(
                      id,
                      "weight",
                      extractNumbers(e?.target?.value),
                    );
                  }
                  // formikQuotation.handleChange(e);
                }}
                onBlur={() =>
                  setErrors({
                    ...errors,
                    weight:
                      itemVal?.weight <= 0 ||
                      itemVal?.weight > WeightLimitation,
                  })
                }
                showError={errors.weight}
                errorMessage={generateErrorMessage(
                  "weight",
                  itemVal.weight > WeightLimitation,
                  `${WeightLimitation} ${formikQuotation.values.is_cm ? "kg" : "Pounds"}`,
                )}
                suffix={CONSTANTS.UNITS.WEIGHT[unit.NAME]}
              />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
            <TextInput
              name="quantity"
              type="text"
              label="Quantity"
              placeholder="0"
              value={itemVal.quantity || ""}
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({ ...errors, quantity: e.target.value <= 0 });
                  updateCargoItemValue(
                    id,
                    "quantity",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({ ...errors, quantity: itemVal.quantity <= 0 })
              }
              // onBlur={(e) => {
              //   formikQuotation.handleBlur(e);
              // }}
              showError={errors.quantity}
              errorMessage={generateErrorMessage("quantity")}
              required={true}
              allowNumbersOnly={true}
            />
            <div>
              <CustomSelect
                name="colli_type"
                options={[
                  { label: "Pallet", value: "Pallet" },
                  { label: "Box", value: "Box" },
                ]}
                required={false}
                placeholder="Select"
                onChange={(e: any) => {
                  if (e) {
                    updateCargoItemValue(id, "colli_type", e);
                  }
                  // formikQuotation.handleChange(e);
                }}
                // onBlur={(e) => {
                //   formikQuotation.handleBlur(e);
                // }}
                value={[itemVal.colli_type]}
                showError={Boolean(formikQuotation.touched.cargo_item_specs)}
                errorMessage={formikQuotation.errors.cargo_item_specs}
                label="Colli Type"
              />
            </div>
            <TextInput
              name="length"
              type="text"
              label="Length"
              required={true}
              placeholder="0"
              value={itemVal.length || ""}
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({ ...errors, length: e.target.value <= 0 });
                  updateCargoItemValue(
                    id,
                    "length",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  length:
                    itemVal.length <= 0 || itemVal.length > LengthLimitation,
                })
              }
              // onBlur={(e) => {
              //   formikQuotation.handleBlur(e);
              // }}
              showError={errors.length}
              errorMessage={generateErrorMessage(
                "length",
                itemVal.length > LengthLimitation,
                `${LengthLimitation} ${formikQuotation.values.is_cm ? "cm" : "in"}`,
              )}
              suffix={CONSTANTS.UNITS.LENGTH[unit.NAME]}
            />
            <TextInput
              name="width"
              type="text"
              label="Width"
              placeholder="0"
              value={itemVal.width || ""}
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({ ...errors, width: e.target.value <= 0 });
                  updateCargoItemValue(
                    id,
                    "width",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  width: itemVal.width <= 0 || itemVal.width > WidthLimitation,
                })
              }
              showError={errors.width}
              errorMessage={generateErrorMessage(
                "width",
                itemVal.width > WidthLimitation,
                `${WidthLimitation} ${formikQuotation.values.is_cm ? "cm" : "in"}`,
              )}
              required={true}
              suffix={CONSTANTS.UNITS.LENGTH[unit.NAME]}
            />
            <TextInput
              name="height"
              type="text"
              label="Height"
              placeholder="0"
              value={itemVal.height || ""}
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({ ...errors, height: e.target.value <= 0 });
                  updateCargoItemValue(
                    id,
                    "height",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  height:
                    itemVal.height <= 0 || itemVal.height > HeightLimitation,
                })
              }
              showError={
                errors.height &&
                formikQuotation?.values?.cargo_item_specs[id]?.stackable
              }
              errorMessage={generateErrorMessage(
                "height",
                itemVal.height > HeightLimitation,
                `${HeightLimitation} ${formikQuotation.values.is_cm ? "cm" : "in"}`,
              )}
              required={true}
              suffix={CONSTANTS.UNITS.LENGTH[unit.NAME]}
              disabled={!itemVal.stackable}
            />
            <TextInput
              name="weight"
              type="text"
              label="Weight"
              placeholder="0"
              value={itemVal.weight || ""}
              onChange={(e: any) => {
                if (e?.target?.value || e?.target?.value >= 0) {
                  setErrors({
                    ...errors,
                    weight:
                      e.target.value <= 0 || e.target.value > WeightLimitation,
                  });
                  updateCargoItemValue(
                    id,
                    "weight",
                    extractNumbers(e?.target?.value),
                  );
                }
                // formikQuotation.handleChange(e);
              }}
              onBlur={() =>
                setErrors({
                  ...errors,
                  weight:
                    itemVal?.weight <= 0 || itemVal?.weight > WeightLimitation,
                })
              }
              showError={errors.weight}
              errorMessage={generateErrorMessage(
                "weight",
                itemVal.weight > VolumeLimitation,
                `${WeightLimitation} ${formikQuotation.values.is_cm ? "kg" : "Pounds"}`,
              )}
              required={true}
              suffix={CONSTANTS.UNITS.WEIGHT[unit.NAME]}
            />
          </div>
        )}
      </div>
      {formikQuotation?.values?.cargo_item_specs.length > 1 && (
        <button
          type="button"
          className="cursor-pointer disabled:cursor-not-allowed"
          disabled={
            formikQuotation?.values?.cargo_item_specs.length === 1
              ? true
              : false
          }
          onClick={() => removeCargoItem(id)}
        >
          <Icon.McsIcCross
            fill={
              formikQuotation?.values?.cargo_item_specs.length === 1
                ? "#FECDCA"
                : "#F04438"
            }
          />
        </button>
      )}
    </div>
  );
};

export default CargoItemComponent;
