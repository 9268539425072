import React from "react";
import SRPreviewComponent from "./sr-customerpreview.component";

interface Props {
  onSuccess?: (val: boolean) => void;
}

const SRCustomerPreviewContainer = ({ onSuccess }: Props) => {
  return <SRPreviewComponent onSuccess={onSuccess} />;
};

export default SRCustomerPreviewContainer;
