import React from 'react';
import { Tabs } from 'antd';
import "./mcs-custom-tabs.css";
const { TabPane } = Tabs;
import { ICustomTabs } from "./mcs-custom-tabs.types";

const McsCustomTabsComponent: React.FC<ICustomTabs> = ({ tabs, defaultActiveKey, onChange,className }) => (
  <Tabs defaultActiveKey={defaultActiveKey} activeKey={defaultActiveKey}  onChange={onChange}  className={className}>
    {tabs.map(({ label, children }, index) => (
      <TabPane key={index.toString()} tab={label}>
        {children}
      </TabPane>
    ))}
  </Tabs>
);

export default McsCustomTabsComponent;
