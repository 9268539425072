import React, { useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./elements/datatable/datatable.container";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { IBreadcrumbItem } from "src/types";
import { TITLES } from "src/types";

const AdminUsersManagement = () => {
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Admin User Management",
    },
  ];

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.ADMIN_USER_MANAGEMENT;
  useEffect(() => {
    document.title = TAB_TITLE+ ' | ' +APP_TITLE;
  }, []);

  return (
    <PanelLayout breadcrumbsData={breadcrumbsData}>
      <ContentWrapper>
        <Datatable />
      </ContentWrapper>
    </PanelLayout>
  );
};

export default AdminUsersManagement;
