import React, { useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./elements/datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { IBreadcrumbItem, TITLES } from "src/types";

const MarginGroups = () => {
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Margin Groups",
    },
  ];

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.MARGIN_GROUPS;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <>
      <PanelLayout breadcrumbsData={breadcrumbsData}>
        <ContentWrapper>
          <Datatable />
        </ContentWrapper>
      </PanelLayout>
    </>
  );
};

export default MarginGroups;
