import { CONSTANTS } from "src/constants";
import * as yup from "yup";
export const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
  name: yup.string().required("Please enter name"),
  password: yup.string().trim().required("Please enter password"),
  confirmPassword: yup
    .string()
    .required("Please enter password")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});
