import React from 'react'

const McsIcAdminUserManagement = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={21}
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M10 13.1445C11.7259 13.1445 13.125 11.7454 13.125 10.0195C13.125 8.29364 11.7259 6.89453 10 6.89453C8.27411 6.89453 6.875 8.29364 6.875 10.0195C6.875 11.7454 8.27411 13.1445 10 13.1445Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeMiterlimit={10}
  />
  <path
    d="M4.98438 16.2231C5.45462 15.2969 6.17216 14.519 7.05745 13.9756C7.94275 13.4322 8.96123 13.1445 10 13.1445C11.0388 13.1445 12.0572 13.4322 12.9425 13.9756C13.8278 14.519 14.5454 15.2969 15.0156 16.2231"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M15.625 6.26953C16.3154 6.26953 16.875 5.70989 16.875 5.01953C16.875 4.32918 16.3154 3.76953 15.625 3.76953C14.9346 3.76953 14.375 4.32918 14.375 5.01953C14.375 5.70989 14.9346 6.26953 15.625 6.26953Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M15.625 3.76953V2.83203"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.5391 4.39453L13.7344 3.92578"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M14.5391 5.64453L13.7344 6.11328"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M15.625 6.26953V7.20703"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M16.7109 5.64453L17.5156 6.11328"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M16.7109 4.39453L17.5156 3.92578"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.4453 9.74623C17.4832 10.0442 17.5015 10.3443 17.5 10.6447C17.5 12.128 17.0601 13.5781 16.236 14.8114C15.4119 16.0448 14.2406 17.0061 12.8701 17.5738C11.4997 18.1414 9.99168 18.2899 8.53683 18.0006C7.08197 17.7112 5.7456 16.9969 4.6967 15.948C3.64781 14.8991 2.9335 13.5627 2.64411 12.1078C2.35472 10.653 2.50325 9.14499 3.07091 7.77454C3.63856 6.4041 4.59986 5.23276 5.83323 4.40864C7.0666 3.58453 8.51664 3.14467 10 3.14467C10.2322 3.1432 10.4642 3.15363 10.6953 3.17592"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcAdminUserManagement