import React, { useEffect, useState } from "react";
import CargoShipperComponent from "./cargo-shipper.component";
import { useQuotationsContext } from "src/context/quotations-context";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { alertsSelector } from "src/store/features/alerts/selectors";
import ConfirmPopup from "src/components/organisms/confirm-delete/confirm-delete.component";

const CargoShipperContainer = ({toggle}:any) => {
  const { nextLink,setNextLink } = useQuotationsContext();
  const navigate = useNavigate();
  const alerts = useAppSelector(alertsSelector);
  const [isOpen, setOpen] = useState(false);
  const { formikQuotation } = useQuotationsContext();

  useEffect(() => {
    console.log(nextLink)
    if(nextLink) {
      nextLink!=='same'&&navigate(nextLink);
      if(nextLink==='same') {
        setOpen(true);
        toggle(true);
      }
    }
  },[nextLink])
  
  useEffect(() => {
    console.log(nextLink)
    if(!alerts?.loading) {
      if(nextLink) {
        nextLink!=='same'&&navigate(nextLink);
        if(nextLink==='same') {
          setOpen(true);
          toggle(true);
        }
      }
    }
  }, [alerts?.loading])

  const popup =  <ConfirmPopup
    title="Route unavailable!"
    img={'/assets/images/spinner.svg'}
    message={`Click on Proceed to create a ${window?.location?.pathname?.includes("results")?'new':'custom'} quotation`}
    removeImg={true}
    deleteBtnTitle="Proceed"
    cancelBtnTitle={`${window?.location?.pathname?.includes("results")?'Previous Results':'Cancel'}`}
    isOpen={isOpen}
    toggle={() => {
      setOpen(!isOpen);
      document.body.style.overflow = "auto";
      toggle(false);
      setNextLink("");
    }}
    onConfirm={() => {
      formikQuotation.setFieldValue("quotation_type", "custom");
      setOpen(!isOpen);
      document.body.style.overflow = "auto";
      toggle(false);
      setNextLink("");
    }}
    // onCancel={() => setDeleteModalOpen(false)}
    className="custom-delete-modal"
  />;

  return isOpen?popup:<CargoShipperComponent />;
};

export default CargoShipperContainer;
