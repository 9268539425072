import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export const SidebarCustomerItem = ({
  val,
  activeClass,
  inactiveClass,
  isLoading,
}: any) => {
  const location = useLocation();

  if (isLoading) {
    return <li className="shimmer mx-16 my-12 h-5"></li>;
  }

  const isActive = location.pathname.includes(val.route);

  return (
    <li>
      <NavLink
        to={val.route}
        className={`${isActive ? activeClass : inactiveClass} relative`}
      >
        <div className="grayscale invert">{val.Icon}</div>
        <span className={`text-14 font-medium leading-20 text-white`}>
          {val.label}
        </span>
        {location.pathname === val.route && (
          <div className="absolute left-0 h-[20px] w-[4px] rounded-r bg-white"></div>
        )}
      </NavLink>
    </li>
  );
};
