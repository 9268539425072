import { ISidebarItemProps } from "./sidebar.types";
import { Icon } from "src/components/atoms/icons";
import React from "react";

export const sideBarData: ISidebarItemProps[] = [
  {
    route: "/admin/quotations",
    Icon: <Icon.McsIcQuotations />,
    label: "Quotations",
    isDisabled: true,
    matchingRoutes: ["/admin/quotations/results"],
  },
  {
    route: "/admin/margin-groups",
    Icon: <Icon.McsIcMarginGroups />,
    label: "Margin Groups",
    isDisabled: true,
  },
  {
    route: "/admin/prices",
    Icon: <Icon.McsIcPrices />,
    label: "Prices",
    isDisabled: true,
  },
  {
    route: "/admin/customer-management",
    Icon: <Icon.McsIcUserManagement />,
    label: "Customer Management",
    isDisabled: true,
  },
  {
    route: "/admin/admin-user-management",
    Icon: <Icon.McsIcAdminUserManagement />,
    label: "Admin User Management",
    isDisabled: true,
  },
  {
    route: "/admin/settings",
    Icon: <Icon.McsIcSettings />,
    label: "Settings",
    isDisabled: true,
  },
  // {
  //   route: "/admin/featured-members",
  //   Icon: <Icon.McsIcRolesAndRights />,
  //   label: "Roles and Rights",
  //   isDisabled: true,
  // },
];

export const customerSideBarData: ISidebarItemProps[] = [
  {
    route: "/quotations",
    Icon: <Icon.McsIcQuotations />,
    label: "Quotations",
    isDisabled: true,
    matchingRoutes: ["/quotations/results"],
  },
  {
    route: "/bookings",
    Icon: <Icon.McsIcBooking />,
    label: "Bookings",
    isDisabled: true,
  },
  {
    route: "/help-and-support",
    Icon: <Icon.McsIcQuestion />,
    label: "Help and Support",
    isDisabled: true,
  },
];
