import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons";

interface BackButton {
  children?: React.ReactNode;
  text?: string;
  href?: string;
}

export default function BackButton({
  children,
  text = "Back",
  href,
}: BackButton) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="flex items-center gap-0.5 text-16 font-normal leading-24 text-gray-1"
      onClick={() => {
        if (href) navigate(href);
        else navigate(-1);
      }}
    >
      {children ?? (
        <>
          <Icon.McsIcBack />
          {text}
        </>
      )}
    </button>
  );
}
