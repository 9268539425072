import React from "react";

const McsIcTick = () => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 3.44776C36.3182 5.36364 39.0785 8.11292 41.0076 11.4234C42.9367 14.7339 43.9678 18.4909 43.9987 22.3223C44.0297 26.1538 43.0594 29.9269 41.184 33.2681C39.3086 36.6093 36.5931 39.4028 33.3063 41.3721C30.0195 43.3413 26.2754 44.418 22.4446 44.4955C18.6138 44.573 14.8292 43.6487 11.4654 41.8141C8.10163 39.9795 5.27532 37.2981 3.26627 34.0355C1.25722 30.7729 0.1351 27.0421 0.0110002 23.2126L0 22.4998L0.0110002 21.787C0.134207 17.9875 1.2398 14.2849 3.22001 11.0399C5.20021 7.79502 7.98744 5.11856 11.31 3.2715C14.6325 1.42445 18.3769 0.469836 22.1782 0.500727C25.9795 0.531617 29.7079 1.54696 33 3.44776ZM30.1554 16.5444C29.7766 16.1656 29.2726 15.938 28.7379 15.9044C28.2032 15.8708 27.6747 16.0334 27.2514 16.3618L27.0446 16.5444L19.8 23.7868L16.9554 20.9444L16.7486 20.7618C16.3253 20.4336 15.7968 20.2712 15.2623 20.3049C14.7278 20.3387 14.2239 20.5662 13.8452 20.9449C13.4665 21.3237 13.2389 21.8275 13.2052 22.362C13.1715 22.8966 13.3339 23.425 13.662 23.8484L13.8446 24.0552L18.2446 28.4552L18.4514 28.6378C18.8372 28.9371 19.3117 29.0996 19.8 29.0996C20.2883 29.0996 20.7628 28.9371 21.1486 28.6378L21.3554 28.4552L30.1554 19.6552L30.338 19.4484C30.6663 19.0251 30.829 18.4965 30.7953 17.9619C30.7617 17.4272 30.5342 16.9232 30.1554 16.5444Z"
        fill="#27AE60"
      />
    </svg>
  );
};

export default McsIcTick;
