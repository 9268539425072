import { useState } from "react";

const useCheckDisabled = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleInputChange = (
    formValues: any,
    requiredKeys: any[],
    storedData: any,
    updateId?: any,
  ) => {
    const otherKeys = Object.keys(formValues);
    const uniqueOtherKeys = otherKeys.filter(
      (key) => !requiredKeys.includes(key),
    );

    const requiredValuesPresent = requiredKeys.every((key) => {
      const value = formValues[key];
      return (
        value !== undefined &&
        value !== null &&
        !(typeof value === "object" && Object.keys(value).length === 0) &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "string" && value.trim() === "")
      );
    });
    if (requiredValuesPresent) {
      if (updateId && storedData) {
        const requiredFieldsSame = requiredKeys.every(
          (key) => formValues[key] === storedData[key],
        );
        if (uniqueOtherKeys.length > 0) {
          const allKeysSame = uniqueOtherKeys.every(
            (key) => formValues[key] === storedData[key],
          );
          setIsDisabled(requiredFieldsSame&&allKeysSame);
        } else {
          setIsDisabled(requiredFieldsSame);
        }
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  };

  return { isDisabled, handleInputChange };
};

export default useCheckDisabled;
