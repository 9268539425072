import React from "react";
import { ICustomCard } from "./types";

const CustomCardComponent = ({ title, children }: ICustomCard) => {
  return (
    <div className="w-full rounded border border-border">
      <h3 className="border-b border-border px-24 py-16 text-18 font-bold leading-28 text-gray-800">
        {title}
      </h3>
      <div className="p-24">{children}</div>
    </div>
  );
};

export default CustomCardComponent;
