import React from "react";
import SideBarCustomerComponent from "./sidebar-customer.component";
interface SideBarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  ref?: any;
  setIsSidebarOpen?: any;
}
const SideBarCustomerContainer = (props: SideBarProps) => {
  return <SideBarCustomerComponent {...props} />;
};

export default SideBarCustomerContainer;
