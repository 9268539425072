import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { Link, useNavigate } from "react-router-dom";
import usePagination from "src/hooks/usePagination";
import { useAppDispatch } from "src/store/hook";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import { Input } from "antd";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import FilterInputs from "./filter-inputs.component";
import {
  useDeletePriceMutation,
  useGetPricesListMutation,
} from "src/services/api-service/prices";
import useLoadingError from "src/hooks/useLoadingError";
import { ENDPOINTS } from "src/store/endpoints";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { setLoading } from "src/store/features/alerts";
import useUser from "src/hooks/useUser";

const Datatable = () => {
  const [filtersData, setFiltersData] = useState({
    validity: null,
    type: null,
    status: null,
  });

  const { startLoading, stopLoading } = useLoadingError();
  const [updateId, setUpdateId] = useState("");
  const [isCrudUpdate, setIsCrudUpdate] = useState(false);
  const [isEditData, setIsEditData] = useState<any>(null);
  const [isDownload, setIsDownload] = useState(false);
  const [getTableData] = useGetPricesListMutation<any>();
  const [deletePrice] = useDeletePriceMutation<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "users-datatable",
    mutation: getTableData,
    filtersData,
  });

  const handleCellClick = (event: any) => {
    const clickedId = event?.data?.id;
    setUpdateId(clickedId);
    setIsEditData(event?.data);
  };

  const user = useUser();
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadEndpoint = ENDPOINTS.ADMIN.DOWNLOAD_PRICE;

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const downloadDataById = async () => {
    try {
      const tableData: any[] = data || [];
      dispatch(setLoading(true));
      const selectedItem: any = tableData.find(
        (item: any) => item.id === updateId,
      );
      const token = user?.access_token;
      const downloadUrl = baseUrl + downloadEndpoint + "/" + updateId;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const blob = new Blob([await response.text()], {
          type: response.headers.get("content-type") || "",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = selectedItem?.title + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setUpdateId("");
        showToast(
          TOASTR_TYPES.SUCCESS,
          `You successfully downloaded the datasource`,
        );
      } else {
        const errorMessage = await response.json();
        showToast(TOASTR_TYPES.ERROR, `${errorMessage?.error}`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `${error.message}`);
    } finally {
      setIsDownload(false);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (updateId && !isDownload && isEditData && !isDeleteModalOpen) {
      const params = {
        type: "pricing",
        id: updateId,
        title: isEditData?.title || "",
        url: isEditData?.url || "",
      };
      const queryString = new URLSearchParams(params).toString();
      params?.title &&
        params?.url &&
        navigate("/admin/prices/connect-database?" + queryString);
    }
    if (updateId && isDownload && !isDeleteModalOpen) {
      downloadDataById();
    }
  }, [updateId, isEditData, isDownload]);

  useEffect(() => {
    setIsEditData(null);
  }, [isDeleteModalOpen]);

  const handleDownload = () => {
    setIsDownload(true);
  };

  const [selectedColumns, setSelectedColumns] = useState<any[]>(
    columns(handleOpenDeleteModal, { handleDownload }),
  );

  const [columnDefs, setColumnDefs] = useState<any[]>(
    columns(handleOpenDeleteModal, { handleDownload }),
  );

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      startLoading();
      setIsCrudUpdate(false);
      updateId &&
        deletePrice(body)
          .unwrap()
          .then(() => {
            setIsCrudUpdate(true);
            showToast(
              TOASTR_TYPES.SUCCESS,
              `You successfully deleted the datasource`,
            );
          });
    } finally {
      stopLoading();
    }
    setDeleteModalOpen(false); // Close the modal after deletion
  };

  useEffect(() => {
    fetchData({ currentPage: pagination?.currentPage, query: "" });
  }, [isCrudUpdate, pagination?.currentPage]);

  const { TextArea } = Input;

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    console.log(e);
  };

  return (
    <div>
      <div>
        <CustomDatatable
          title={"Pricings"}
          description={`${pagination.total} Total Datasource${pagination.total > 1 ? "s" : ""}`}
          className="date-picker"
          data={data}
          columns={columnDefs}
          showHeader={true}
          showFooter={true}
          pagination={pagination}
          setPagination={setPagination}
          placeholder="Search by title"
          rowDraggable={false}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
          onRowClicked={() => {
            console.log("row clicked");
          }}
          onCellClicked={(event: any) => handleCellClick(event)}
          filters={
            <FilterInputs
              values={filtersData}
              setFiltersData={setFiltersData}
            />
          }
          button={
            <Link to="/admin/prices/connect-database?type=pricing">
              <div className="flex items-center gap-3">
                <CustomButton variant="primary-icon-btn">
                  <div className="flex items-center gap-2">
                    <i className="shrink-0">
                      <Icon.McsIcCirclePlus />
                    </i>
                    <span>Connect Datasource</span>
                  </div>
                </CustomButton>
              </div>
            </Link>
          }
        />
      </div>
      <ConfirmDelete
        title="Are you sure you want to Delete this datasource?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </div>
  );
};

export default Datatable;
