import React from "react";

const McsIcFiltersExchange = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
    >
      <g clipPath="url(#clip0_5692_17854)">
        <rect width={44} height={44} rx={22} fill="#333F69" />
        <path
          d="M12.833 18.3333H38.4997L31.1663 11"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.1667 25.666H5.5L12.8333 32.9993"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5692_17854">
          <rect width={44} height={44} rx={22} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default McsIcFiltersExchange;
