import React, { useEffect } from "react";
import ResetPasswordForm from "./reset-password-form";
import AuthLayout from "src/components/layouts/auth-layout";
import { TITLES } from "src/types";

const ResetPassword = () => {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.RESET_PASSWORD;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPassword;
