import React from 'react'

const McsIcPrices= () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={21}
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M10 6.26953V7.51953"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 13.7695V15.0195"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10 18.1445C14.1421 18.1445 17.5 14.7867 17.5 10.6445C17.5 6.5024 14.1421 3.14453 10 3.14453C5.85786 3.14453 2.5 6.5024 2.5 10.6445C2.5 14.7867 5.85786 18.1445 10 18.1445Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M8.125 13.7695H10.9375C11.3519 13.7695 11.7493 13.6049 12.0424 13.3119C12.3354 13.0189 12.5 12.6214 12.5 12.207C12.5 11.7926 12.3354 11.3952 12.0424 11.1022C11.7493 10.8092 11.3519 10.6445 10.9375 10.6445H9.0625C8.6481 10.6445 8.25067 10.4799 7.95765 10.1869C7.66462 9.89386 7.5 9.49643 7.5 9.08203C7.5 8.66763 7.66462 8.2702 7.95765 7.97718C8.25067 7.68415 8.6481 7.51953 9.0625 7.51953H11.875"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>


  )
}

export default McsIcPrices