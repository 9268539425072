import React from 'react'

const McsIcPlus = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10 2.5L10 17.5M17.5 10L2.5 10"
    stroke="white"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default McsIcPlus