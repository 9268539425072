import React, { useEffect } from "react";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import { TITLES } from "src/types";
import ContentWrapper from "src/components/layouts/content-wrapper";
import HelpSupportWrapper from "./elements/wrapper";
import FAQ from "./elements/faq";
import ContactUs from "./elements/contact-us";

export default function HelpSupport() {
  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.HELP_SUPPORT;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <CustomerPanelLayout breadcrumbsData={[{ title: TAB_TITLE }]}>
      <ContentWrapper>
        <HelpSupportWrapper title={TAB_TITLE}>
          <FAQ />
          <ContactUs />
        </HelpSupportWrapper>
      </ContentWrapper>
    </CustomerPanelLayout>
  );
}
