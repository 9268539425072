import React from 'react'

const McsIcSettings = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={21}
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M8.4375 13.1445C11.0263 13.1445 13.125 11.0459 13.125 8.45703C13.125 5.8682 11.0263 3.76953 8.4375 3.76953C5.84867 3.76953 3.75 5.8682 3.75 8.45703C3.75 11.0459 5.84867 13.1445 8.4375 13.1445Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeMiterlimit={10}
  />
  <path
    d="M1.73438 16.2704C2.55567 15.2916 3.58138 14.5044 4.73942 13.9644C5.89747 13.4244 7.15973 13.1445 8.4375 13.1445C9.71527 13.1445 10.9775 13.4244 12.1356 13.9644C13.2936 14.5044 14.3193 15.2916 15.1406 16.2704"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.1875 12.5195C17.8779 12.5195 18.4375 11.9599 18.4375 11.2695C18.4375 10.5792 17.8779 10.0195 17.1875 10.0195C16.4971 10.0195 15.9375 10.5792 15.9375 11.2695C15.9375 11.9599 16.4971 12.5195 17.1875 12.5195Z"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.1875 10.0195V9.08203"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M16.1016 10.6445L15.2969 10.1758"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M16.1016 11.8945L15.2969 12.3633"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M17.1875 12.5195V13.457"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M18.2734 11.8945L19.0781 12.3633"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M18.2734 10.6445L19.0781 10.1758"
    stroke="#291770"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default McsIcSettings