export const cardData = [
  {
    title: "Internal Quotation",
    description: "Make a query and adjust results",
    isActive: true,
  },
  {
    title: "Custom Quotation",
    description: "Create entirely from scratch",
    isActive: false,
  },
];

export enum CARD_TYPES {
  QUOTATION_TYPE = "QUOTATION_TYPE",
  SERVICE_TYPE = "SERVICE_TYPE",
}
