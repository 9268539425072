import React from "react";
import { Link } from "react-router-dom";

export const SideBarCustomerHeader = () => {
  const isAdmin = window?.location?.pathname?.includes("/admin");
  return (
    <div className="flex w-full flex-col justify-center md:justify-start">
      <div className="block px-14 pb-8 pt-14">
        <Link
          to={(isAdmin?"/admin":"")+"/quotations?tab=Generate Quotations"}
          className="inline-block"
        >
          <img src="/assets/images/mcs-logo-white.svg" alt="MCS" />
        </Link>
      </div>
    </div>
  );
};
