import Label from "../label";
import React, { useState, useEffect } from "react";
import ErrorLabel from "../error-label";
import { IPortPicker, Port } from "./types";
import { AutoComplete, Input } from "antd";
import { Icon } from "../icons";
import { useGetPortsListMutation } from "src/services/api-service/ports";

const PortPickerComponent = ({
  id,
  name,
  label,
  size = "large",
  classes,
  placeholder,
  disabled,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  autoFocus,
  suffix,
  ports,
  value,
  suffixIcon,
  showSearch,
  allowCustomValue = true,
}: IPortPicker) => {
  const [searchvalue, setSearchValue] = useState(value || "");
  // const [ports, setPorts] = useState<Port[]>([]);

  // Fetch ports data from API
  const [getPortsList, { data: portsData, isLoading }] =
    useGetPortsListMutation();

  // Update ports state when data is fetched
  // useEffect(() => {
  //   if (portsData && Array.isArray(portsData)) {
  //     setPorts(portsData);
  //   }
  // }, [portsData]);

  useEffect(() => {
    setSearchValue(value || "");
  }, [value]);

  const handleInputChange = (e: any) => {
    setSearchValue(e.target.value);
    onChange(e.target.value);
  };

  const commonInputProps: any = {
    name,
    searchvalue,
    onBlur,
    onChange: handleInputChange,
    onKeyDown: onkeydown,
    placeholder,
    prefix: <Icon.McsIcMapPin />,
    suffix,
    value,
    size,
    disabled,
    className: classes?.inputClassName + (!showSearch ? " " : ""),
    status: showError && errorMessage ? "error" : "",
  };

  // Function to filter ports based on search value including aliases
  const filterPorts = (value: string) => {
    return ports.filter(
      (port) =>
        port?.name
          ?.toLowerCase()
          ?.includes(showSearch ? value.toLowerCase() : "") ||
        port.locode
          .toLowerCase()
          .includes(showSearch ? value.toLowerCase() : "") ||
        (port.aliases &&
          JSON.parse(port.aliases).some((alias: string) =>
            alias.toLowerCase().includes(showSearch ? value.toLowerCase() : ""),
          )),
    );
  };

  const style = !allowCustomValue
    ? { caretColor: "transparent", cursor: "pointer" }
    : {};

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <AutoComplete
        style={style}
        suffixIcon={suffixIcon}
        popupClassName="!bg-primary-50"
        options={filterPorts(searchvalue).map((port: Port) => ({
          value: port.locode,
          label: (
            <div className="flex flex-col gap-2">
              <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                {port?.name || ""}
              </span>
              <span>{`${port?.name || ""} ${port.locode}`}</span>
            </div>
          ),
        }))}
        size="large"
        className="w-full"
        showSearch={true}
        value={searchvalue}
        onSearch={(value: string) => {
          setSearchValue(value);
          // Call the mutation function with the search value
          filterPorts(showSearch ? value : "").map((port: Port) => ({
            value: port.locode,
            label: (
              <div className="flex flex-col gap-2">
                <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                  {port.name}
                </span>
                <span>{`${port.name} (${port.locode})`}</span>
              </div>
            ),
          }));
          // getPortsList({ /* Pass any required parameters here */ });
        }}
        onSelect={(value: any) => {
          onSelect(value);
          setSearchValue(value);
        }}
        onInputKeyDown={(e: any) => {
          if (!allowCustomValue) e.preventDefault();
        }}
      >
        <Input
          {...commonInputProps}
          onFocus={onFocus}
          autoFocus={autoFocus}
          style={
            !allowCustomValue
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
        />
      </AutoComplete>
      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default PortPickerComponent;
