import { CONSTANTS } from "src/constants";
import * as yup from "yup";

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(
      CONSTANTS.VALIDATIONS.EMAIL,
        'Invalid email address'
      ),
});
