import React from 'react'

const McsIcDownArrow = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 7.5l5 5 5-5"
      stroke="#667085"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default McsIcDownArrow