import React from "react";
import { ICustomDrawer } from "./types";
import "./custom-drawer.css";
import { Collapse } from "antd";
const { Panel } = Collapse;

const CustomDrawerComponent = ({
  toggle,
  isOpen,
  children,
  header,
}: ICustomDrawer) => {
  return (
    <Collapse bordered={false} activeKey={isOpen ? ["1"] : []}>
      <Panel key="1" showArrow={false} header={header} collapsible="header">
        {children}
      </Panel>
    </Collapse>
  );
};

export default CustomDrawerComponent;
