import React from "react";
import FormHeader from "src/pages/auth/elements/form-header";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { IForgotPasswordForm } from "./types";
import ErrorLabel from "src/components/atoms/error-label";
import { Icon } from "src/components/atoms/icons";
const ForgotPasswordForm = ({
  formik,
  error,
  resetError,
  loading,
}: IForgotPasswordForm) => {
  return (
    <div>
      <div>
        <FormHeader
          title="Forgot password"
          description="Enter the email address associated with your account to receive reset link "
        />
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-32">
            <TextInput
              name="email"
              type="email"
              label="Email Address"
              placeholder="Enter your email address"
              classes={{ containerClassName: "" }}
              value={formik.values.email}
              showError={Boolean(formik.touched.email)}
              onChange={(e) => {
                resetError();
                formik.handleChange(e);
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                resetError();
              }}
              errorMessage={formik.errors.email}
              required={true}
              prefix={<Icon.McsIcEmail />}
            />
            {Boolean(error) && <ErrorLabel message={error} />}
          </div>

          <CustomButton className="w-full" loading={loading} htmlType="submit">
            Send
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
