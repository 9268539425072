import React from "react";

const HeaderSubTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3 className="text-lg font-semibold leading-28 text-gray-900">
      {children}
    </h3>
  );
};

export default HeaderSubTitle;
