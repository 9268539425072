import React from 'react'

const McsIcChevronBack = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.0001 6L8.7072 11.2929C8.31668 11.6834 8.31668 12.3166 8.7072 12.7071L14.0001 18"
    stroke="#0F1014"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default McsIcChevronBack