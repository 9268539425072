import React from "react";
import DataTableComponent from "./custom-datatable.component";
import DatatableHeader from "./datatable-header";
import DatatableFooter from "./datatable-footer";
import { IDataTable } from "../types";

const CustomDatatableContainer = ({
  title,
  description,
  button,
  data,
  columns,
  showHeader,
  showFooter,
  pagination,
  setPagination,
  handleSearch,
  clearSearch,
  handlePageChange,
  handleItemsPerPageChange,
  onRowClicked,
  onUpdateStatus,
  onCellClicked,
  handleColumnChange,
  filters,
  rowDraggable,
  placeholder,
}: IDataTable) => {
  return (
    <DataTableComponent
      columns={columns}
      data={data}
      showFooter={showFooter}
      showHeader={onRowClicked}
      pagination={pagination}
      setPagination={setPagination}
      onRowClicked={onRowClicked}
      onCellClicked={onCellClicked}
      rowDraggable={rowDraggable}
      description={description}
      onUpdateStatus={onUpdateStatus}
      header={
        <DatatableHeader
          title={title}
          description={description}
          button={button}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          pagination={pagination}
          columns={columns}
          handleColumnChange={handleColumnChange}
          filters={filters}
          placeholder={placeholder}
        />
      }
      footer={
        <DatatableFooter
          pagination={pagination}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      }
    />
  );
};

export default CustomDatatableContainer;
