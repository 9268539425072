import useLoadingError from "src/hooks/useLoadingError";
import React, { useEffect, useState } from "react";
import VerifyOtpForm from "./verify-otp-form.component";
import { useVerifyOtpMutation } from "src/services/api-service/auth";
import { useSearchParams, useNavigate } from "react-router-dom";

const VerifyOtpFormContainer = () => {
  const [url] = useSearchParams();
  const navigate = useNavigate();

  const { loading, error, setErrorMsg, reset, startLoading, stopLoading } =
    useLoadingError();
  const [verifyOtp] = useVerifyOtpMutation();

  const [otp, setOtp] = useState("");
  const token = url?.get("token");
  const email = url?.get("email");
  console.log(url, token, email);

  useEffect(() => {
    if (!token) {
      navigate(`/login`);
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    // try {
    e.preventDefault();
    if (otp?.length < 6) {
      return setErrorMsg("Please enter OTP");
    }
    startLoading();
    verifyOtp({
      code: otp,
      token,
    })
      .unwrap()
      .then((res) => {
        navigate(`/reset-password?token=${res?.data?.token}`);
      })
      .catch((err: any) => {
        setErrorMsg(err?.data?.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <>
      <VerifyOtpForm
        error={error}
        resetError={reset}
        loading={loading}
        handleSubmit={handleSubmit}
        otp={otp}
        setOtp={setOtp}
      />
    </>
  );
};

export default VerifyOtpFormContainer;
