import dayjs from "dayjs";

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180;
}

interface Size {
  width: number;
  height: number;
}

export function rotateSize(
  width: number,
  height: number,
  rotation: number,
): Size {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise<string | null>((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file));
      } else {
        reject(new Error("Failed to create blob."));
      }
    }, "image/jpeg");
  });
}

export const formatWebsiteUrl = (url: any) => {
  // Check if the URL starts with "https://" or "http://"
  // Trim the URL and check if it is empty or whitespace-only
  const trimmedUrl = url?.trim();
  if (!trimmedUrl) {
    // If empty or whitespace-only, return an empty string
    return "";
  }

  if (!trimmedUrl.startsWith("https://") && !url.startsWith("http://")) {
    // If not, prepend "https://"
    return `https://${url}`;
  }

  // If the URL already starts with "https://" or "http://", return as is
  return url;
};

export const formatDateQuotation = (dateString: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = months[netherlandsDate.getMonth()];
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();
  const hours = netherlandsDate.getHours() + 1;
  const minutes = netherlandsDate.getMinutes();
  const seconds = netherlandsDate.getSeconds();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zeros to minutes and seconds if they are single digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return dateString
    ? `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
    : "";
};

export const formatDateQuotationRoute = (dateString: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = netherlandsDate.getMonth();
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();

  return dateString ? `${day}/${month}/${year}` : "";
};

export const roundToTwoDecimalPoints = (input: any) => {
  return input;
  // Convert input to a number if it's a string
  const number = typeof input === "string" ? parseFloat(input) : input;

  if (isNaN(number)) {
    return NaN; // Return NaN if the input is not a valid number
  }

  const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100;

  // Format the rounded number to always have two decimal places
  const formattedNumber = roundedNumber.toFixed(2);

  return formattedNumber; // Convert back to number and return
};

export const getCurrentDate = () => {
  return dayjs(new Date());
};

export const convertToLowerCaseWithSpaces = (value: string) => {
  return value?.replace(/_/g, " ").toLowerCase() || value;
};

export const removeCommas = (inputString: string): any => {
  return inputString?.replace(/,/g, "") || "";
};

export const roundToTwoDecimalPoint = (input: string | number) => {
  // Convert input to a string if it's not already a string
  const inputStr = typeof input === "number" ? String(input) : input;

  // Extract the numerical part from the input string
  const numberStr = inputStr.replace(/[^\d.]/g, ""); // Remove non-numeric characters

  // Convert the extracted numerical part to a number
  const number = parseFloat(numberStr);

  // Check if the extracted number is valid
  if (isNaN(number)) {
    return NaN; // Return NaN if the extracted number is not valid
  } else if (Number.isInteger(number)) {
    return number; // Return the number unchanged if it's an integer
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100; // Round to two decimal points otherwise
  }
};

export const objectToQueryParams = (obj: Record<string, any>) => {
  const queryParams = Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== "",
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryParams;
};

export const formatSortingPayload = (payload: any) => {
  return Object.keys(payload)?.length > 0 ? JSON.stringify(payload) : null;
};
