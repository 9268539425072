import React, { useEffect, useState } from "react";
import DatatableFooterComponent from "./datatable-footer.component";
import { IDataTable } from "../../types";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { setRouteLoading } from "src/store/features/alerts";
import { alertsSelector } from "src/store/features/alerts/selectors";

const DatatableFooterContainer = ({
  pagination,
  handlePageChange,
  handleItemsPerPageChange,
}: Partial<IDataTable>) => {
  const startDocument =
    (pagination?.currentPage - 1) * pagination?.itemsPerPage + 1;
  const endDocument = Math.min(
    pagination?.currentPage * pagination?.itemsPerPage,
    pagination?.total,
  );
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(alertsSelector);
  const [paginationOptions, setPaginationOptions] = useState([{ value: 10 }]);

  useEffect(() => {
    // if (pagination.total > 10) {
    //   setPaginationOptions([{ value: 10 }, { value: 20 }]);
    // }
    // if (pagination.total > 20) {
    //   setPaginationOptions([{ value: 10 }, { value: 20 }, { value: 50 }]);
    // }
    // if (pagination.total > 50) {
      setPaginationOptions([
        { value: 10 },
        { value: 20 },
        { value: 50 },
        { value: 100 },
      ]);
    // }
  }, [pagination]);

  useEffect(() => {
    dispatch(setRouteLoading(!alerts.routeLoading));
    return () => {
      dispatch(setRouteLoading(!alerts.routeLoading));
    };
  }, [location.pathname]);

  return (
    <DatatableFooterComponent
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleItemsPerPageChange={handleItemsPerPageChange}
      startDocument={startDocument}
      endDocument={endDocument}
      paginationOptions={paginationOptions}
    />
  );
};

export default DatatableFooterContainer;
