import React, { useState } from "react";
import moment from "moment";
import { Icon } from "src/components/atoms/icons";
import QuotationHorizontalSteps from "src/pages/quotation-results/elements/quotation-horizontal-steps";
import QuotationRoutes from "src/components/atoms/quotation-routes";

interface RouteCardProps {
  data: any;
  isPending: boolean;
}

export default function RouteCard({ data, isPending }: RouteCardProps) {
  const [showDetail, setShowDetail] = useState(false);

  const checkPending = (val: string) => {
    return isPending ? "..." : val;
  };

  return (
    <div className="relative h-28 w-full overflow-x-hidden rounded-md p-0">
      {/* ===== Overall Card ===== */}
      <div
        className={`relative z-10 transition-transform duration-500 ${showDetail ? "-translate-x-[93%]" : "translate-x-0"} flex h-full w-full flex-col items-center justify-center bg-gradient-to-r from-[#004677] via-[#004A7E] to-[#025895] p-30 text-white`}
      >
        <div className="flex w-full gap-3">
          <span className="max-w-40 flex-1 truncate text-right">
            {checkPending(data?.source_country)}
          </span>
          <Icon.McsIcBetween />
          <span className="max-w-40 flex-1 truncate">
            {checkPending(data?.destination_country)}
          </span>
        </div>
        <div className="text-center text-xs">
          {data?.source_port} . {data?.transit_time} days.{" "}
          {data?.destination_port}
        </div>

        <button
          title="Route Switch"
          type="button"
          onClick={() => setShowDetail((prev) => !prev)}
          className={`absolute bottom-1 right-[0.125rem] p-1 transition-all duration-700 ${showDetail ? "-rotate-180" : ""}`}
        >
          <Icon.McsIcCaretDouble />
        </button>
      </div>

      {/* ===== Route Detail ===== */}
      <div
        className="no-scrollbar absolute left-0 top-0 flex h-full w-full gap-6 overflow-x-auto
      rounded-md bg-primary-50 p-20 pl-44"
      >
        <div className="flex h-full w-44 flex-col items-center justify-between">
          <img
            src="/assets/images/mcs-marinair-logo-1.svg"
            alt="cargolux"
            width={100}
            height={28}
          />
          <div className="w-44 text-center font-semibold">
            Marinair Cargo Services
          </div>
        </div>

        {/* ===== Route ===== */}
        <QuotationRoutes
          source={data?.source_country}
          destination={data?.destination_country}
          date={data?.date}
          mode={data?.mode_of_transport}
          isStop={data?.isTransshipment == true ? true : false}
          transit={data?.Transshipment}
            transitTime={data?.transit_time}
        />
      </div>
    </div>
  );
}
