import React from "react";
import { Collapse } from "antd";
import HeaderSubTitle from "src/components/atoms/header-subtitle";
import { Icon } from "src/components/atoms/icons";
import { FAQInterface, FAQList } from "src/faq-text";
import { TITLES } from "src/types";

export default function FAQ() {
  const TITLE = TITLES.FAQ;

  return (
    <div className="help-support flex flex-col gap-8">
      <HeaderSubTitle>{TITLE}</HeaderSubTitle>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {FAQList.map((item: FAQInterface) => (
          <Collapse
            key={item.id}
            accordion
            size="large"
            bordered={false}
            className="group select-none"
            expandIcon={({ isActive }) => {
              return (
                <span
                  className={`${isActive ? "rotate-45" : "rotate-0"} transition-all group-hover:rounded-full group-hover:bg-red-400 group-hover:fill-white group-hover:!text-white`}
                >
                  <Icon.McsIcPlusBlack />
                </span>
              );
            }}
            expandIconPosition="end"
            items={[
              {
                key: item.id,
                label: item.title,
                headerClass:
                  "!rounded-[0.625rem] !p-20 border-2 hover:border-red-300 group-hover:border-3",
                children: (
                  <p className="mx-10 mt-10 rounded-md bg-white !px-24 !py-16">
                    {item.description}
                  </p>
                ),
              },
            ]}
          />
        ))}
      </div>
    </div>
  );
}
