import React from "react";

const McsIcTruck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3966_42361)">
        <path
          d="M4.16797 14.1667C4.16797 14.6087 4.34356 15.0326 4.65612 15.3452C4.96868 15.6577 5.39261 15.8333 5.83464 15.8333C6.27666 15.8333 6.70059 15.6577 7.01315 15.3452C7.32571 15.0326 7.5013 14.6087 7.5013 14.1667C7.5013 13.7246 7.32571 13.3007 7.01315 12.9882C6.70059 12.6756 6.27666 12.5 5.83464 12.5C5.39261 12.5 4.96868 12.6756 4.65612 12.9882C4.34356 13.3007 4.16797 13.7246 4.16797 14.1667Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 14.1667C12.5 14.6087 12.6756 15.0326 12.9882 15.3452C13.3007 15.6577 13.7246 15.8333 14.1667 15.8333C14.6087 15.8333 15.0326 15.6577 15.3452 15.3452C15.6577 15.0326 15.8333 14.6087 15.8333 14.1667C15.8333 13.7246 15.6577 13.3007 15.3452 12.9882C15.0326 12.6756 14.6087 12.5 14.1667 12.5C13.7246 12.5 13.3007 12.6756 12.9882 12.9882C12.6756 13.3007 12.5 13.7246 12.5 14.1667Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16797 14.166H2.5013V10.8327M1.66797 4.16602H10.8346V14.166M7.5013 14.166H12.5013M15.8346 14.166H17.5013V9.16602M17.5013 9.16602H10.8346M17.5013 9.16602L15.0013 4.99935H10.8346"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 7.5H5.83333"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3966_42361">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default McsIcTruck;
