import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import RadioInputComponent from "src/components/atoms/radio-input";
import SelectInput from "src/components/atoms/select-input";
import TextInput from "src/components/atoms/text-input";
import ContentWrapper from "src/components/layouts/content-wrapper";
import CustomModal from "src/components/molecules/custom-modal";
import useModal from "src/hooks/useModal";
import SettingsHeader from "../settings-header";
import PersonalInformation from "../personal-information";
import ChangePassword from "../change-password";

const GeneralSettingComponent = () => {
  const [toggle, setToggle] = useState(false);

  const onClick = () => {
    setToggle(!toggle);
  };

  return (
    <ContentWrapper>
      <div className="xl:gird-cols-12 general-settings grid">
        <SettingsHeader
          headingText="General Settings"
          subheadingText="Update your personal details here."
          showButton={true}
          buttonText={toggle ? "Cancel" : "Edit Information"}
          variant={toggle ? "secondary" : "primary"}
          onClick={onClick}
        />
        <div className="grid xl:grid-cols-12">
          <div className="xl:col-span-12 xxl:col-span-12">
            <PersonalInformation
              toggle={toggle}
              getToggle={(value: any) => setToggle(value)}
            />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default GeneralSettingComponent;
