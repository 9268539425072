import React from "react";
import Datatable from "./datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";

const LocalCharges = () => {
  return (
    <>
      <ContentWrapper>
        <Datatable />
      </ContentWrapper>
    </>
  );
};

export default LocalCharges;
