import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* General Settings APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.mutation<IResponse, Partial<any>>({
      query: () => ({
        url: ENDPOINTS.ADMIN.GET_CURRENT_USER,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    changePassword: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("current_password", body?.current_password);
        formData.append("new_password", body?.new_password?.trim());
        formData.append("confirm_password", body?.confirm_password?.trim());

        return {
          url: ENDPOINTS.ADMIN.CHANGE_PASSWORD,
          method: "PUT",
          body: body,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateCurrentUser: build.mutation<IResponse, Partial<any>>({
      query: (body) => {

        return {
          url: ENDPOINTS.ADMIN.UPDATE_CURRENT_USER,
          method: "PUT",
          body: body,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    integrationStoreCreds: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("secret_id", body.secret_id);
        formData.append("secret_key", body.secret_key);

        return {
          url: ENDPOINTS.ADMIN.INTEGRATION_STORE_CREDS,
          method: "POST",
          // mode: 'no-cors',
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    integrationGetToken: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("user_id", body.user_id);
        formData.append("code", body.code);

        return {
          url: ENDPOINTS.ADMIN.INTEGRATION_GET_TOKEN,
          method: "POST",
          // mode: 'no-cors',
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    uploadFile: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        const uniqueFileName = `croppedImage_${Date.now()}.jpg`;
        formData.append("image", body.image, uniqueFileName);

        return {
          url: ENDPOINTS.ADMIN.UPLOAD_FILE,
          method: "POST",
          headers: {
            // Set your desired headers here
            // "Content-Type": "multipart/form-data",
            // Add any additional headers as needed
          },
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useGetCurrentUserMutation,
  useChangePasswordMutation,
  useUpdateCurrentUserMutation,
  useUploadFileMutation,
  useIntegrationStoreCredsMutation,
  useIntegrationGetTokenMutation
} = APIS;
