import React, { ReactNode } from "react";
import HeaderTitle from "src/components/atoms/header-title";

const HelpSupportWrapper = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div>
      <HeaderTitle>{title}</HeaderTitle>

      <div className="mt-34 flex flex-col gap-8">{children}</div>
    </div>
  );
};

export default HelpSupportWrapper;
