import React, { useState, useEffect, useMemo } from "react";
import QuotationTabs from "./elements/quotation-tabs";
import { IBreadcrumbItem, TITLES } from "src/types";
import CargoShipperSuccess from "./elements/cargo-shipper-success";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import { useLocation } from "react-router-dom";
import SRCustomerPreview from "src/components/layouts/sr-customerpreview";

export const Quotations = () => {
  const params = new URLSearchParams(location.search);
  const tab = useMemo(() => params.get("tab"), [location]);
  const id = useMemo(() => params.get("id"), [location]);

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: tab ?? "Fill Details",
    },
  ];

  if (id) {
    breadcrumbsData.push({
      title: "Fill Details",
    });
  }

  const [data, setData] = useState(breadcrumbsData);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.QUOTATIONS;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, [data]);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  const tabChanged = (title: any) => {
    setData([{ title: title || "Quotations" }]);
  };

  console.log("inside quotations");

  return (
    <CustomerPanelLayout breadcrumbsData={data}>
      <>
        <div className={!success ? "hidden" : ""}>
          <CargoShipperSuccess toggle={setSuccess} />
        </div>
        <div className={success ? "hidden" : ""}>
          <QuotationTabs clicked={success} tabChanged={tabChanged} />
          <SRCustomerPreview onSuccess={(val) => setSuccess(val)} />
        </div>
      </>
    </CustomerPanelLayout>
  );
};
